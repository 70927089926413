import React, { useState, useEffect, useRef } from "react";
import api from "./api";
import styled from "styled-components";
import ReturnToTopButton from "./ReturnToTopButton";
import Dropdown from "./global/Dropdown";
import { useOktaAuth } from "@okta/okta-react";

const OuterWrapper = styled.section`
  width: 100%;
  background: ${({ theme }) => theme.backgroundColor};

  h1 {
    color: ${({ theme }) => theme.textColor};
    font-size: 48px;
    font-weight: 700;
    line-height: 1;
    text-transform: uppercase;
    margin: 0;
  }
`;

const PageWrapper = styled.section`
  display: flex;
  flex-direction: row;
  max-width: 1750px;
  min-height: calc(100vh - 76px);
  max-width: 90%;
  margin: 0 auto;
  margin-left: 138px;
  padding-top: 70px;
  @media only screen and (max-width: 800px) {
    margin: 0 auto;
  }
`;

const LibraryContent = styled.div`
  color: ${({ theme }) => theme.textColor};
  background: ${({ theme }) => theme.backgroundColor};
  position: relative;
  padding-bottom: 50px;
  max-width: 100%;
`;

const FilterGroup = styled.div`
  display: flex;

  @media only screen and (max-width: 800px) {
    display: block;
  }
  > div {
    margin-right: 20px;

    @media only screen and (max-width: 800px) {
      margin-right: 0;
      width: 100%;
    }
  }
`;

const TitleWrap = styled.div`
  letter-spacing: 0px;
  color: ${({ theme }) => theme.textColor};
  margin: 0;
  width: 1072px;
  max-width: 90%;
  padding-top: 30px;
  padding-bottom: 30px;
  background: ${({ theme }) => theme.backgroundColor};
  line-height: 1;
  position: sticky;
  top: 0;
  z-index: 10;
  border-bottom: 2px ${({ theme }) => theme.divider} solid;

  @media only screen and (max-width: 800px) {
    position: relative;
    top: 20px;
    max-width: 100%;
  }
`;

const Name = styled.h2`
  color: ${({ theme }) => theme.textColor};
  letter-spacing: 0px;
  margin: 0;

  @media only screen and (max-width: 800px) {
    font-size: 18px;
  }
`;

const Description = styled.p`
  letter-spacing: 0px;
  color: ${({ theme }) => theme.textColor};
  margin: 0 0 30px 0;
`;

const Department = styled.p`
  text-transform: capitalize;
  margin: 0;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.textColor};
  padding-bottom: 10px;
  font-style: italic;
`;

const LibraryItem = styled.a`
  padding: 40px 0 1px 0;
  position: relative;
  max-width: 950px;
  margin: 0;
  width: 80%;
  display: block;
  text-decoration: none;
  transition: transform 0.2s ease;

  @media only screen and (max-width: 800px) {
    width: 100%;
  }

  &:hover {
    transform: scale(0.99);
  }

  @media only screen and (max-width: 1000px) {
    margin: 0;
    padding-left: 0;
  }

  :after {
    width: 100px;
    background: ${({ theme }) => theme.divider};
    position: absolute;
    content: "";
    height: 2px;
    left: 0;
    bottom: 0;
  }

  h3 {
    overflow: hidden;
    color: #0075e0;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.3;
    text-transform: uppercase;
  }

  ul {
    margin-bottom: 40px;
  }
`;

const RequestSearch = styled.input`
  background: ${({ theme }) => theme.tabsWrapper};
  border-radius: 25px;
  outline: none;
  border: none;
  height: 30px;
  color: ${({ theme }) => theme.textColor};
  padding-left: 20px;
  position: sticky;
  top: 10px;
  width: 425px;
  max-width: 100%;

  @media only screen and (max-width: 800px) {
    position: relative;
    width: 100%;
    box-sizing: border-box;
  }
`;

function alphabetizeData(array) {
  return array.slice().sort((a, b) => {
    const nameA = a["name"].toLowerCase();
    const nameB = b["name"].toLowerCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
}

function Requests() {
  const [allRequests, setAllRequests] = useState([]);
  const [requestItems, setRequestItems] = useState(null);
  const [letters, setLetters] = useState([]);
  // const [departments, setDepartments] = useState([]);
  const [systems, setSystems] = useState([]);
  const [filteredLetter, setFilteredLetter] = useState(false);
  // const [filteredDepartment, setFilteredDepartment] = useState(false);
  const [filteredSystem, setFilteredSystem] = useState("all");
  const { authState, oktaAuth } = useOktaAuth();
  const [oktaInfo, setOktaInfo] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (authState.isAuthenticated) {
      setOktaInfo(authState.idToken.claims);
    }
  }, [oktaInfo]);

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    const debounceTimeout = setTimeout(() => {
      // Filtering logic here
      if (searchQuery === "") {
        setRequestItems(allRequests);
        return;
      }
      if (searchQuery !== "") {
        const filteredData = requestItems.filter((item) =>
          item.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setRequestItems(filteredData);
      }
    }, 1000); // Debounce for 1000 milliseconds (1 second)

    return () => clearTimeout(debounceTimeout);
  }, [searchQuery]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    const getDropdowns = (AllTheRequests) => {
      const letterSet = ["all"];
      const departmentSet = ["all"];
      const systemSet = ["all"];

      AllTheRequests.forEach((item) => {
        const firstLetter = item.name.trim().charAt(0).toLowerCase();
        item.firstLetter = firstLetter;

        function isLetter(character) {
          // Regular expression to match letters
          const letterRegex = /^[a-zA-Z]$/;

          // Test if the character is a letter
          return letterRegex.test(character);
        }

        if (!letterSet.includes(firstLetter) && isLetter(firstLetter)) {
          letterSet.push(firstLetter);
        }
        // if (!departmentSet.includes(item.department)) {
        //   departmentSet.push(item.department);
        // }
        if (!systemSet.includes(item.system) && item.system) {
          systemSet.push(item.system);
        }
      });
      setLetters(letterSet);
      // setDepartments(departmentSet);
      setSystems(systemSet);
    };

    // Split up data fetches so if SN doesnt load the page doesnt break

    async function fetchData() {
      let filterParams = "limit=-1";
      filterParams += "&sort=label";
      filterParams += `&filter[status][_eq]=published`;

      const requestCollectionData = await api.collection(
        "requests",
        oktaAuth,
        filterParams
      );

      const snRequestData = await api.getServiceNowRequests(oktaAuth);

      const AllTheRequests = [...requestCollectionData, ...snRequestData];

      const requestsInOrder = await alphabetizeData(AllTheRequests);

      getDropdowns(requestsInOrder);

      setAllRequests(requestsInOrder);
      setRequestItems(requestsInOrder);
    }
    fetchData();
  }, []);

  // useEffect(() => {
  //   document.addEventListener("click", handleClick);

  //   return () => {
  //     document.removeEventListener("click", handleClick);
  //   };
  // });

  const handleClick = (event) => {
    if (filteredDepartment) {
      setFilteredDepartment(false);
    }
    if (filteredLetter) {
      setFilteredLetter(false);
    }
    return;
  };

  useEffect(() => {
    const scrollToElement = () => {
      const hash = window.location.hash;
      const id = hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        const offsetTop =
          element.getBoundingClientRect().top + window.pageYOffset - 200;
        window.scrollTo({
          top: offsetTop,
          behavior: "smooth",
        });
      } else {
        setTimeout(scrollToElement, 200);
      }
    };

    scrollToElement();
  }, []);

  useEffect(() => {
    // if (searchQuery && searchQuery !== "") {
    //   setSearchQuery("");
    // }
    // if (filteredSystem) {
    //   if (filteredSystem.toLowerCase() == "all") {
    //     setRequestItems(allRequests);
    //   } else {
    //     let filteredRequestItems = requestItems.filter(
    //       (item) => item.system === filteredSystem
    //     );
    //     console.log("filtered", filteredSystem, requestItems);
    //     setRequestItems(filteredRequestItems);
    //   }
    // }
    // if (filteredLetter) {
    //   requestItems = requestItems.filter(
    //     (item) => item.firstLetter === filteredLetter
    //   );
    // }
  }, [filteredLetter, filteredSystem]);

  useEffect(() => {
    // if (filteredLetter) {
    //   setFilteredLetter("all");
    // }
    // if (filteredSystem) {
    //   setFilteredSystem("all");
    // }
    // setFilteredSystem(false);
    // setFilteredSystem(false);
  }, [searchQuery]);

  // Filter items by first letter

  // Filter items by Department
  // if (filteredDepartment) {
  //   filteredItems = filteredItems.filter(
  //     (item) => item.department === filteredDepartment
  //   );
  // }

  // Filter items by System

  return (
    <OuterWrapper>
      <PageWrapper>
        {requestItems && (
          <LibraryContent>
            <FilterGroup>
              {/* <Dropdown
                defaultText={"Filter By Letter"}
                selectedOptionPrependText={"Letter: "}
                options={letters}
                onSelect={(letter) => {
                  setFilteredLetter(letter == "all" ? false : letter);
                }}
              /> */}
              {/* <Dropdown
                defaultText={"Filter By Department"}
                selectedOptionPrependText={"Department: "}
                options={departments}
                onSelect={(department) => {
                  setFilteredDepartment(
                    department == "all" ? false : department
                  );
                }}
              /> */}
              {/* <Dropdown
                defaultText={
                  filteredSystem.toLowerCase() === "all"
                    ? "Filter By System"
                    : filteredSystem
                }
                selectedOptionPrependText={"System: "}
                options={systems}
                onSelect={(system) => {
                  setFilteredSystem(system);
                }}
              /> */}
            </FilterGroup>
            {/* <RequestSearch
              type="text"
              placeholder="Search requests..."
              value={searchQuery}
              onChange={handleSearchChange}
            /> */}
            <TitleWrap className="glossary-title">
              <h1>Requests {filteredLetter && <> | {filteredLetter}</>} </h1>
            </TitleWrap>
            {requestItems.length === 0 && requestItems && (
              <LibraryItem>No results.</LibraryItem>
            )}
            {requestItems.map((item, i) => {
              return (
                <LibraryItem
                  key={i}
                  id={item.name.toLowerCase().replaceAll(" ", "-")}
                  href={item.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Name>{item.name}</Name>
                  {/* <Department>
                    <b>Department – </b>
                    {item.department}
                  </Department> */}
                  <Description>{item.description}</Description>
                </LibraryItem>
              );
            })}
          </LibraryContent>
        )}
      </PageWrapper>
      <ReturnToTopButton
        onReturn={() => {
          setClickedLetter(0);
        }}
      />
    </OuterWrapper>
  );
}

export default Requests;
