import React, { useState } from "react";

const ImageWithFallback = ({ src, fallbackSrc, size }) => {
  const [imageSrc, setImageSrc] = useState(src);
  const [fallbackImageSrc, setFallbackImageSrc] = useState(
    "https://assets.mx.com/hub/images/no-img-img.png"
  );

  const handleError = () => {
    // setImageSrc(`${fallbackSrc}?width=${size}`);
    console.log("error");
    setImageSrc(false);
  };

  return (
    <img
      src={imageSrc ? `${imageSrc}?width=${size}` : fallbackImageSrc}
      onError={handleError}
      alt=""
    />
  );
};

export default ImageWithFallback;
