import { React, useEffect, useState } from "react";
import styled, { withTheme } from "styled-components";

const SearchBoxInput = styled.input`
  height: 40px;
  width: 200px;
  border-radius: 25px;
  height: 33px;
  padding: 0px 20px;
  border: 1px solid rgb(187, 187, 187);
  background: ${({ theme }) => theme.backgroundColor};
  color: ${({ theme }) => theme.textColor};
`;

function SearchBox({ onChange, value }) {
  return (
    <SearchBoxInput
      type="text"
      placeholder="Search..."
      value={value}
      onChange={onChange}
    />
  );
}

export default withTheme(SearchBox);
