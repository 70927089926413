import styled from "styled-components";

const Page = styled.div`
  padding: 0;
  overflow: hidden;
  position: relative;
  @media only screen and (max-width: 800px) {
    padding: 0 30px;
  }

  /* for sections inside of the page component that need to be full width of window */
  .full-width {
    width: 100vw;
    left: -100px;
    position: relative;
  }

  @media only screen and (max-width: 800px) {
    .full-width {
      left: -30px;
    }
  }

`;

function ThePage(props) {
  return <Page>{props.children}</Page>;
}

export default ThePage;
