import React, { useState, useEffect, useRef } from "react";
import api from "./api";
import utils from "./utils";
import { useOktaAuth } from "@okta/okta-react";
import styled, { withTheme } from "styled-components";
import PostRow from "../components/PostRow";
import CompanyCalendar from "./CompanyCalendar";
import Joyride from "react-joyride";

const PageWrapper = styled.section`
  padding: 0 20px;
  display: flex;

  @media only screen and (max-width: 1700px) {
    flex-direction: column;
  }
  @media only screen and (max-width: 900px) {
    padding: 0 20px;
  }

  h2 {
    margin: 0;
    text-transform: uppercase;
    color: ${({ theme }) => theme.textColor};
    line-height: 1;
  }

  #filters {
    margin: 70px 0 20px 0;
  }

  .month-listing {
    margin-top: 50px;
    margin-left: 50px;
    height: 95vh;
    overflow: auto;
    width: 100%;
    max-width: 40%;

    @media only screen and (max-width: 1700px) {
      margin: 0 auto;
      margin-top: 20px;
      max-width: 100%;
    }

    @media only screen and (max-width: 900px) {
      margin-left: 0;
      height: auto;
    }

    .wrap {
      max-width: 100%;

      @media (min-width: 1920px) {
        margin: 0 auto;
      }

      @media only screen and (max-width: 1700px) {
        margin: 0 auto;
        margin-top: 20px;
        width: 600px;
      }
    }

    .post-row {
      &.event-post {
        transform: none !important;
        pointer-events: none;
      }

      @media only screen and (max-width: 900px) {
        .content {
          width: 100%;
        }
      }
    }
    @media only screen and (max-width: 500px) {
      .content {
        max-width: 100%;
        box-sizing: border-box;
      }

      h3 {
        -webkit-line-clamp: 2;
      }
    }
  }
`;

function NewsEvents({ theme }) {
  const { authState, oktaAuth } = useOktaAuth();
  const [oktaInfo, setOktaInfo] = useState([]);

  // const calendarData = (data) => {
  //   setDaysInMonth(data.monthLength);
  //   setTheDate(data.date);
  //   setGoogleCalEventData(data.allEvents);
  // };

  useEffect(() => {
    // Check if the user is authenticated
    if (authState.isAuthenticated) {
      const accessToken = authState.accessToken.accessToken;
      setOktaInfo(authState.idToken.claims);

      // Now you can use the accessToken as needed
      // For example, you can include it in your API requests
    }
  }, [oktaInfo]);

  return (
    <PageWrapper>
      <CompanyCalendar />
    </PageWrapper>
  );
}

export default withTheme(NewsEvents);
