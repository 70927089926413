import { useState } from "react";
import styled from "styled-components";

const DropdownWrap = styled.div`
  position: relative;
  width: fit-content;
`;

const OptionsWrap = styled.div`
    background: ${({ theme }) => theme.tabsWrapper};
    border-radius: 15px;
    top: 35px;
    right: 0;
    position: absolute;
    z-index: 20;
    width: 100%;


    ul {
      list-style: none;
      padding: 0 20px;
    }

    li {
      padding: 10px;
      text-align: left;

      &.active-tag {
        color: ${({ theme }) => theme.blue};
      }

      &:hover {
        color: ${({ theme }) => theme.blue};
        cursor: pointer;
      }
    }
`;

const SelectArrow = ({ rotated }) => {
  return (
    <svg
      style={{ transform: rotated ? "translateY(-1px) rotate(180deg)" : "" }}
      width="11"
      height="6"
      viewBox="0 0 11 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.66602 1L5.66602 5L9.66602 1"
        stroke={"black"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};


const FilterButton = styled.button`
  display: inline;
  border: none;
  background: ${({ theme }) => theme.tabsWrapper};
  color: ${({ theme }) => theme.textColor};
  border-radius: 30px;
  padding: 5px 30px 5px 12px;
  margin: 5px 10px;
  cursor: pointer;
  width: 100%;
  position: relative;
  text-transform: uppercase;

  svg {
    position: absolute;
    top: 10px;
    right: 12px;
    margin-left: 10px;

    path {
      stroke: ${({ theme }) => theme.textColor};
    }
  }

  &:first-child {
    margin-left: 0;
  }

  &.filtered {
    color: ${({ theme }) => theme.blue};
    color: blue;
  }
`;

const Dropdown = ({ defaultText, selectedOptionPrependText, options, onSelect }) => {

  const [showOptions, setShowOptions] = useState(false);
  const [selectedOption, setSelectedOption] = useState(false);

  return (
    <DropdownWrap className="link-line">
      <FilterButton onClick={(e) => {
        setShowOptions(!showOptions)
      }}>
        {selectedOption ? (selectedOptionPrependText + selectedOption) : defaultText}
        <SelectArrow rotated={showOptions} />
        {showOptions && (
          <OptionsWrap>
            <ul>

              {options.map((option, index) => {
                return (
                  <li key={index} onClick={() => {
                    setSelectedOption(option)
                    onSelect(option)
                  }}>
                    {option}
                  </li>
                );
              })}
            </ul>

          </OptionsWrap>
        )}
      </FilterButton>
    </DropdownWrap>
  );
};

export default Dropdown;
