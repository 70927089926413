import styled from "styled-components";

const Gap = styled.div`


  width: ${({ width }) => (width ? `${width}px` : "0")};
  height: ${({ height }) => (height ? `${height}px` : "0")};

  width: ${({ size }) => (size ? `${size}px` : "0")};
  height: ${({ size }) => (size ? `${size}px` : "0")};

  @media only screen and (max-width: ${({ mobile }) => (mobile ? `${mobile}px` : "0")}) {

    width: ${({ mWidth }) => (mWidth ? `${mWidth}px` : "0")};
    height: ${({ mHeight }) => (mHeight ? `${mHeight}px` : "0")};
  
    width: ${({ mSize }) => (mSize ? `${mSize}px` : "0")};
    height: ${({ mSize }) => (mSize ? `${mSize}px` : "0")};

  }

`;

export default Gap;
