import React, { useState, useEffect, useRef } from "react";
import styled, { withTheme } from "styled-components";
import { useOktaAuth } from "@okta/okta-react";
import api from "./api";
import axios from "axios";
import utils from "./utils";

const PageWrapper = styled.div`
  .controls {
    width: 100%;
    background: ${({ theme }) => theme.hoverBackground};
    padding: 20px 0;

    .controls-wrapper {
      width: 600px;
      margin: 0 auto;
      text-align: center;
    }

    h1 {
      margin: 0;
      padding-bottom: 20px;
      font-size: 25px;
      text-align: center;

      color: ${({ theme }) => theme.textColor};
    }

    select {
      width: 200px;
      height: 30px;
      margin-right: 40px;
    }
    button {
      background: ${({ theme }) => theme.blue};
      color: #fff;
      border: none;
      height: 30px;
      padding: 0 40px;
      font-weight: 600;
    }
  }

  .content {
    padding: 20px;
    background: ${({ theme }) => theme.backgroundColor};

    pre {
      color: ${({ theme }) => theme.textColor};
      font-family: monospace;
    }
  }
`;

const serviceNowData = [
  {
    short_description:
      "How To Set Up a Secondary Multifactor Authentication Method In Okta",
    sys_id: "015f314d47a05d10e3591a88536d4390",
    valid_to: "2023-06-15",
    sys_updated_on: "2022-12-09 18:00:08",
  },
  {
    short_description: "Work Backlog",
    sys_id: "03919d2397fd1950653f7057f053af81",
    valid_to: "2023-09-13",
    sys_updated_on: "2022-09-13 18:45:44",
  },
  {
    short_description: "How to Reserve a Workspace on Zoom Web Portal",
    sys_id: "09ddb2c8875229505ad20e98cebb3522",
    valid_to: "2024-04-13",
    sys_updated_on: "2023-04-17 17:02:09",
  },
  {
    short_description:
      "How to View a User's Contact Information on the Floor Map",
    sys_id: "09e88bc4879229505ad20e98cebb35e0",
    valid_to: "2024-04-13",
    sys_updated_on: "2023-04-17 19:04:40",
  },
  {
    short_description: "How to Deal with Spam",
    sys_id: "0b48fd75474321009db4b5b08b9a71c2",
    valid_to: "2100-01-01",
    sys_updated_on: "2022-12-09 17:59:39",
  },
  {
    short_description: "Automation - New Request",
    sys_id: "10b8e5c8972b5d10653f7057f053af73",
    valid_to: "2023-11-28",
    sys_updated_on: "2022-11-28 23:12:25",
  },
  {
    short_description: 'Accessing the "M" drive from Mac',
    sys_id: "12da8568db48d70090f27b5b8c9619e0",
    valid_to: "2100-01-01",
    sys_updated_on: "2022-12-14 16:48:34",
  },
  {
    short_description: "End of Life Process",
    sys_id: "1a677fc91b2bc9d0a4ceca242a4bcbe3",
    valid_to: "2023-05-17",
    sys_updated_on: "2022-05-17 16:03:45",
  },
  {
    short_description: "Setting Up Outlook for Mac",
    sys_id: "213041a4db48d70090f27b5b8c961997",
    valid_to: "2100-01-01",
    sys_updated_on: "2022-12-09 17:59:43",
  },
  {
    short_description: "Integrations and Managed Package Contact Information",
    sys_id: "229a47b51ba381d06ddc751bcd4bcb2d",
    valid_to: "2023-05-19",
    sys_updated_on: "2022-12-09 18:00:13",
  },
  {
    short_description: "MX Equipment Policy",
    sys_id: "2343e297db908150dc6a5716f4961988",
    valid_to: "2024-12-31",
    sys_updated_on: "2021-12-13 20:16:39",
  },
  {
    short_description: "Account Lockout",
    sys_id: "28e99bd79782ed90653f7057f053af3c",
    valid_to: "2025-06-17",
    sys_updated_on: "2023-04-12 16:23:32",
  },
  {
    short_description: "Add a shared calendar to Outlook",
    sys_id: "2c107bcadb205f0090f27b5b8c961933",
    valid_to: "2100-01-01",
    sys_updated_on: "2022-12-09 17:59:45",
  },
  {
    short_description: "Sales and Marketing Printers - For Macbook users",
    sys_id: "31876b3cdba240103ca946723996195a",
    valid_to: "2100-01-01",
    sys_updated_on: "2022-12-09 17:59:44",
  },
  {
    short_description: "Why does my Zoom Meeting ID show invalid?",
    sys_id: "39116e201b1c29506ddc751bcd4bcbe3",
    valid_to: "2024-01-06",
    sys_updated_on: "2023-01-06 23:47:29",
  },
  {
    short_description: 'Accessing the "M" Drive from Windows',
    sys_id: "3d5bcd68db48d70090f27b5b8c961929",
    valid_to: "2100-01-01",
    sys_updated_on: "2022-12-14 16:48:13",
  },
  {
    short_description: "Slack Integration",
    sys_id: "3f4f0ff91ba381d06ddc751bcd4bcb9c",
    valid_to: "2023-05-19",
    sys_updated_on: "2022-05-19 21:00:16",
  },
  {
    short_description: "What is the Mimecast Personal Portal?",
    sys_id: "40b90ea8db88d70090f27b5b8c96195f",
    valid_to: "2100-01-01",
    sys_updated_on: "2022-12-09 17:59:48",
  },
  {
    short_description:
      "How to access Workspace Reservations on the Zoom mobile app",
    sys_id: "45fac30c879229505ad20e98cebb35cf",
    valid_to: "2024-04-13",
    sys_updated_on: "2023-04-17 19:02:53",
  },
  {
    short_description: "OneNote To Google Keep",
    sys_id: "473d476d971a69d0653f7057f053af55",
    valid_to: "2024-04-18",
    sys_updated_on: "2023-04-19 15:22:24",
  },
  {
    short_description: "Users - User Role Assignment",
    sys_id: "47692100976b5d10653f7057f053afe8",
    valid_to: "2023-11-28",
    sys_updated_on: "2022-11-28 23:15:40",
  },
  {
    short_description: "How to create a shared calendar ",
    sys_id: "4879d1acdb974850dc6a5716f496195d",
    valid_to: "2100-01-01",
    sys_updated_on: "2022-12-09 17:59:49",
  },
  {
    short_description: "How to Print With Adobe",
    sys_id: "536c3395dbb43410dc6a5716f496190e",
    valid_to: "2024-12-31",
    sys_updated_on: "2023-04-21 19:21:48",
  },
  {
    short_description: "Papercut for Linux Setup",
    sys_id: "63887cb7db39470090f27b5b8c961944",
    valid_to: "2100-01-01",
    sys_updated_on: "2022-12-09 17:59:55",
  },
  {
    short_description: "Can I Put a Camera Cover on my MacBook Pro? ",
    sys_id: "657db9ea97c2e990653f7057f053af54",
    valid_to: "2024-04-09",
    sys_updated_on: "2023-04-10 01:44:32",
  },
  {
    short_description: "MXCX Team Processes",
    sys_id: "667ac3b51ba381d06ddc751bcd4bcb46",
    valid_to: "2023-05-10",
    sys_updated_on: "2022-12-09 18:00:20",
  },
  {
    short_description: "Email Setup for Gmail on Android",
    sys_id: "6a3bf784db84970026ab3c9b7c9619ea",
    valid_to: "2100-01-01",
    sys_updated_on: "2022-12-09 17:59:56",
  },
  {
    short_description: "Open a shared mailbox in Outlook",
    sys_id: "6bbd668d973fd190653f7057f053af54",
    valid_to: "2023-10-10",
    sys_updated_on: "2022-12-14 17:44:24",
  },
  {
    short_description: "30 day/90 day cooldown",
    sys_id: "6d5ebfcd1b27cd906ddc751bcd4bcb93",
    valid_to: "2023-05-17",
    sys_updated_on: "2022-12-09 18:00:01",
  },
  {
    short_description: "SMC/PRAM Reset (Mac)",
    sys_id: "705f05bd97fb9590653f7057f053afed",
    valid_to: "2023-12-14",
    sys_updated_on: "2022-12-16 18:06:02",
  },
  {
    short_description: "Installing Office 365 ",
    sys_id: "720e4d1e87ebd1505ad20e98cebb3569",
    valid_to: "2100-01-01",
    sys_updated_on: "2022-12-05 21:03:01",
  },
  {
    short_description: "Outreach",
    sys_id: "76583bb0db4f3700dc6a5716f496196b",
    valid_to: "2100-01-01",
    sys_updated_on: "2022-12-09 17:59:58",
  },
  {
    short_description: "1Password",
    sys_id: "76801ce687d5d1905ad20e98cebb355e",
    valid_to: "2023-08-15",
    sys_updated_on: "2022-12-09 18:00:05",
  },
  {
    short_description: "Sending Secure Files with Mimecast",
    sys_id: "7ac981b4db9ff7003ca94672399619da",
    valid_to: "2100-01-01",
    sys_updated_on: "2022-12-09 18:00:23",
  },
  {
    short_description:
      "How to View, Edit, or Delete Upcoming Reservations Within Zoom Web Portal",
    sys_id: "7b958f44879229505ad20e98cebb359d",
    valid_to: "2024-04-13",
    sys_updated_on: "2023-04-17 19:02:38",
  },
  {
    short_description: "Precursive Managed Packages",
    sys_id: "801128561be7c1d06ddc751bcd4bcb3d",
    valid_to: "2023-05-19",
    sys_updated_on: "2022-12-09 17:59:38",
  },
  {
    short_description: "Users - Create/Modify Roles",
    sys_id: "80c92180976b5d10653f7057f053af57",
    valid_to: "2023-11-28",
    sys_updated_on: "2022-11-28 23:17:05",
  },
  {
    short_description: "How to Reserve a Workspace on the Mobile app",
    sys_id: "829c34458716e9505ad20e98cebb35f2",
    valid_to: "2024-04-16",
    sys_updated_on: "2023-04-17 19:04:21",
  },
  {
    short_description: "Installing Slack",
    sys_id: "902626a6db3a7700f44a760a68961905",
    valid_to: "2100-01-01",
    sys_updated_on: "2022-12-09 17:59:47",
  },
  {
    short_description: "Remove iCloud Activation Lock from Mac Laptop",
    sys_id: "9f29e4d6db9b5c10dc6a5716f496199a",
    valid_to: "2025-08-18",
    sys_updated_on: "2022-12-09 17:59:52",
  },
  {
    short_description: "How to Submit a Ticket",
    sys_id: "a398dfc197b81110653f7057f053af9c",
    valid_to: "2023-07-18",
    sys_updated_on: "2022-12-09 18:00:07",
  },
  {
    short_description: "Billing Team Processes",
    sys_id: "b0246c7c1b53c990a4ceca242a4bcbd7",
    valid_to: "2023-05-03",
    sys_updated_on: "2022-12-09 17:59:50",
  },
  {
    short_description: "How to Set Service Order for Mac Network Ports. ",
    sys_id: "b569267d870711505ad20e98cebb35e7",
    valid_to: "2023-11-09",
    sys_updated_on: "2022-12-09 18:00:10",
  },
  {
    short_description: "How to change OKTA password",
    sys_id: "b7939039db6a830090f27b5b8c9619e7",
    valid_to: "2100-01-01",
    sys_updated_on: "2019-05-16 18:09:37",
  },
  {
    short_description: "Conference Room Etiquette ",
    sys_id: "bdaa10f697a35950653f7057f053af3d",
    valid_to: "2023-12-07",
    sys_updated_on: "2023-01-12 18:45:42",
  },
  {
    short_description: "Users - Access Requests",
    sys_id: "c319a500976b5d10653f7057f053af3b",
    valid_to: "2023-11-28",
    sys_updated_on: "2022-11-28 23:14:30",
  },
  {
    short_description: "How to Reset OKTA Verify MFA",
    sys_id: "c65b8a358774d1105ad20e98cebb35c3",
    valid_to: "2023-12-31",
    sys_updated_on: "2022-07-20 19:19:55",
  },
  {
    short_description: "Sending Large Files with Mimecast",
    sys_id: "cb65097cdb5ff7003ca946723996199d",
    valid_to: "2100-01-01",
    sys_updated_on: "2022-12-09 18:00:19",
  },
  {
    short_description: "How to Verify a Conference Room accepted an Invite",
    sys_id: "ce3613c097db1590653f7057f053af10",
    valid_to: "2023-11-16",
    sys_updated_on: "2022-12-09 18:00:15",
  },
  {
    short_description: "Tips for Proper Use and Care of Your Assigned Computer",
    sys_id: "ce9718f8dbcf030090f27b5b8c961982",
    valid_to: "2100-01-01",
    sys_updated_on: "2022-12-09 18:00:15",
  },
  {
    short_description: "Windows VPN Install and Connect (GlobalProtect)",
    sys_id: "d5549e18db3bd4503ca94672399619ca",
    valid_to: "2030-06-05",
    sys_updated_on: "2022-12-09 17:59:44",
  },
  {
    short_description: "Outreach Sync Setup Instructions",
    sys_id: "dfc302dadb694300b8ca3a1b7c961923",
    valid_to: "2100-01-01",
    sys_updated_on: "2022-12-09 18:00:19",
  },
  {
    short_description: "QuickStartGuide_ DevOpsCenter",
    sys_id: "ec5d41751b53cd90a4ceca242a4bcbbe",
    valid_to: "2023-05-07",
    sys_updated_on: "2022-12-09 18:00:21",
  },
  {
    short_description: "Babbage: Open Use Technology Intrsuction and Notes",
    sys_id: "f7bca4b9dbb3bf00dc6a5716f4961985",
    valid_to: "2100-01-01",
    sys_updated_on: "2019-09-10 14:27:51",
  },
];

const mxEduData = [
  {
    id: 410,
    course_name: "2023 Benefit Details and Benefits Education",
    category: "Docebo",
    course_unique_id: "E-N055Q0",
    course_duration: "0:00:00",
  },
  {
    id: 278,
    course_name: "5 Ways To Be BOLD At Work",
    category: "MXCX Team",
    course_unique_id: "E-KVOMX1",
    course_duration: "0:00:00",
  },
  {
    id: 214,
    course_name: "Accessibility For an Inclusive Digital Experience",
    category: "BuildMX Week 3",
    course_unique_id: "E-Q07NL1",
    course_duration: "0:25:00",
  },
  {
    id: 414,
    course_name: "AGGREGATIONS 101",
    category: "Docebo",
    course_unique_id: "E-Q07GEV",
    course_duration: "0:00:00",
  },
  {
    id: 294,
    course_name: "APIs 101: What's an API?",
    category: "MXCX Team",
    course_unique_id: "E-GVZ6PV",
    course_duration: "0:00:00",
  },
  {
    id: 91,
    course_name: "Banking 101",
    category: "Industry",
    course_unique_id: "E-5VN2Q0",
    course_duration: "0:10:00",
  },
  {
    id: 336,
    course_name: "Base Sales Presentation Prework",
    category: "Certifications",
    course_unique_id: "E-Z1G360",
    course_duration: "0:10:00",
  },
  {
    id: 413,
    course_name: "BATCAVE 101",
    category: "Docebo",
    course_unique_id: "E-P0YGY0",
    course_duration: "0:00:00",
  },
  {
    id: 147,
    course_name: "Big Data, Big Responsibility",
    category: "BuildMX Week 2",
    course_unique_id: "E-YVM8QV",
    course_duration: "0:25:00",
  },
  {
    id: 307,
    course_name: "Build the Right Relationships: Intro to MEDDPICC",
    category: "MXCX Team",
    course_unique_id: "E-P08MP0",
    course_duration: "0:00:00",
  },
  {
    id: 152,
    course_name: "Building Your Budget",
    category: "BuildMX Week 2",
    course_unique_id: "E-6027N1",
    course_duration: "0:10:00",
  },
  {
    id: 146,
    course_name: "BuildMX Week 2",
    category: "BuildMX Week 2",
    course_unique_id: "E-GVZPE0",
    course_duration: "0:10:00",
  },
  {
    id: 159,
    course_name: "BuildMX Week 3",
    category: "BuildMX Week 3",
    course_unique_id: "E-Y1Q8RV",
    course_duration: "0:10:00",
  },
  {
    id: 311,
    course_name: "Call Best Practices & Discovery Techniques",
    category: "MXCX Team",
    course_unique_id: "E-Y1QXJ0",
    course_duration: "0:00:00",
  },
  {
    id: 418,
    course_name: "Code of Conduct",
    category: "People Development",
    course_unique_id: "E-7VR4GV",
    course_duration: "0:45:00",
  },
  {
    id: 401,
    course_name: "Contracts 101",
    category: "Systems",
    course_unique_id: "E-E0JZDV",
    course_duration: "0:05:00",
  },
  {
    id: 178,
    course_name: "Core Provider: Fiserv",
    category: "Industry",
    course_unique_id: "E-KVOEP0",
    course_duration: "0:30:00",
  },
  {
    id: 254,
    course_name: "Core Provider: Jack Henry",
    category: "Industry",
    course_unique_id: "E-KVOPX1",
    course_duration: "0:30:00",
  },
  {
    id: 388,
    course_name: "Data Analyst Week 1",
    category: "Data Analyst check List Courses",
    course_unique_id: "E-E1LZ9V",
    course_duration: "0:00:00",
  },
  {
    id: 380,
    course_name: "Data Analyst Week 2",
    category: "Data Analyst check List Courses",
    course_unique_id: "E-GVPRX0",
    course_duration: "0:00:00",
  },
  {
    id: 387,
    course_name: "Data Analyst Week 3",
    category: "Data Analyst check List Courses",
    course_unique_id: "E-P0WKX0",
    course_duration: "0:00:00",
  },
  {
    id: 389,
    course_name: "Data Analyst Week 4",
    category: "Data Analyst check List Courses",
    course_unique_id: "E-71KZX1",
    course_duration: "0:00:00",
  },
  {
    id: 391,
    course_name: "Data Analyst Week 5",
    category: "Data Analyst check List Courses",
    course_unique_id: "E-5VNZ9V",
    course_duration: "0:00:00",
  },
  {
    id: 293,
    course_name: "Data Enhancement 101",
    category: "MXCX Team",
    course_unique_id: "E-G03L6V",
    course_duration: "0:00:00",
  },
  {
    id: 298,
    course_name: "Data Enhancement and Aggregation Deep Dive",
    category: "MXCX Team",
    course_unique_id: "E-D19XD0",
    course_duration: "0:00:00",
  },
  {
    id: 337,
    course_name: "Demo Call Prework",
    category: "Certifications",
    course_unique_id: "E-P0Y5X0",
    course_duration: "0:10:00",
  },
  {
    id: 341,
    course_name: "Discovery Call Prework",
    category: "Certifications",
    course_unique_id: "E-71K3X1",
    course_duration: "0:10:00",
  },
  {
    id: 376,
    course_name:
      "DOMO Power User Training: Cards & Dashboards Visualize your data",
    category: "Domo Power User Training",
    course_unique_id: "E-602G40",
    course_duration: "0:12:00",
  },
  {
    id: 374,
    course_name: "DOMO Power User Training: Dataflows (ETL) & Views",
    category: "Domo Power User Training",
    course_unique_id: "E-D192OV",
    course_duration: "0:12:00",
  },
  {
    id: 373,
    course_name: "DOMO Power User Training: Dataflows (SQL)",
    category: "Domo Power User Training",
    course_unique_id: "E-E0JR91",
    course_duration: "0:05:00",
  },
  {
    id: 313,
    course_name: "Explore 6sense,  Call Reports & Time Management",
    category: "MXCX Team",
    course_unique_id: "E-P0Y2X0",
    course_duration: "0:00:00",
  },
  {
    id: 310,
    course_name:
      "Explore the Importance of Elevating Others &amp; Finish MEDDPICC Training",
    category: "MXCX Team",
    course_unique_id: "E-N05LM1",
    course_duration: "0:00:00",
  },
  {
    id: 430,
    course_name: "FI Navigator",
    category: "Docebo",
    course_unique_id: "E-KVO9R0",
    course_duration: "0:00:00",
  },
  {
    id: 163,
    course_name: "Finstrong Module 1: organize & automate",
    category: "BuildMX Week 3",
    course_unique_id: "E-P0W43V",
    course_duration: "0:20:00",
  },
  {
    id: 165,
    course_name: "Finstrong Module 2: Stockpile",
    category: "BuildMX Week 3",
    course_unique_id: "E-71K450",
    course_duration: "0:10:00",
  },
  {
    id: 167,
    course_name: "Finstrong Module 3: Take Charge",
    category: "BuildMX Week 3",
    course_unique_id: "E-5VNWRV",
    course_duration: "0:15:00",
  },
  {
    id: 162,
    course_name: "FinStrong Welcome & Foundation",
    category: "BuildMX Week 3",
    course_unique_id: "E-Q07E41",
    course_duration: "0:15:00",
  },
  {
    id: 276,
    course_name: "Get to Know the Executive Team",
    category: "MXCX Team",
    course_unique_id: "E-602ELV",
    course_duration: "0:00:00",
  },
  {
    id: 132,
    course_name: "Getting Set Up",
    category: "BuildMX Week 1",
    course_unique_id: "E-GVPMQV",
    course_duration: "0:10:00",
  },
  {
    id: 134,
    course_name: "Getting Started with Workday",
    category: "BuildMX Week 1",
    course_unique_id: "E-N0539V",
    course_duration: "0:20:00",
  },
  {
    id: 55,
    course_name: "Helios 101",
    category: "Docebo",
    course_unique_id: "E-P08NJV",
    course_duration: "0:00:00",
  },
  {
    id: 429,
    course_name: "History of MX",
    category: "Docebo",
    course_unique_id: "E-E046P1",
    course_duration: "0:00:00",
  },
  {
    id: 421,
    course_name: "History of the Financial Industry",
    category: "BuildMX Week 1",
    course_unique_id: "E-G03P41",
    course_duration: "0:00:00",
  },
  {
    id: 284,
    course_name: "How FI's function",
    category: "MXCX Team",
    course_unique_id: "E-Z1GYQ1",
    course_duration: "0:00:00",
  },
  {
    id: 312,
    course_name: "Importance of Creativity in Prospecting",
    category: "MXCX Team",
    course_unique_id: "E-Z1GG61",
    course_duration: "0:00:00",
  },
  {
    id: 79,
    course_name: "Industry 101",
    category: "Industry",
    course_unique_id: "E-P083JV",
    course_duration: "0:10:00",
  },
  {
    id: 286,
    course_name: "Industry News Feeds & Glossary of Terms",
    category: "MXCX Team",
    course_unique_id: "E-Q07PLV",
    course_duration: "0:00:00",
  },
  {
    id: 297,
    course_name: "Internal Teams Overview",
    category: "MXCX Team",
    course_unique_id: "E-E0J4W1",
    course_duration: "0:00:00",
  },
  {
    id: 288,
    course_name: "Intro to Partnerships",
    category: "MXCX Team",
    course_unique_id: "E-E1L8E0",
    course_duration: "0:00:00",
  },
  {
    id: 287,
    course_name: "Intro to Personas",
    category: "MXCX Team",
    course_unique_id: "E-P0WDY1",
    course_duration: "0:00:00",
  },
  {
    id: 135,
    course_name: "Introduction to the BuildMX Program",
    category: "BuildMX Week 1",
    course_unique_id: "E-Y1QPRV",
    course_duration: "0:10:00",
  },
  {
    id: 375,
    course_name: "Introduction to the Catalyst Team",
    category: "People Development",
    course_unique_id: "E-J0ERRV",
    course_duration: "0:30:00",
  },
  {
    id: 282,
    course_name: "Introduction to the Financial Services Industry",
    category: "MXCX Team",
    course_unique_id: "E-N05OE1",
    course_duration: "0:00:00",
  },
  {
    id: 138,
    course_name: "Introduction to the Money Experience",
    category: "BuildMX Week 1",
    course_unique_id: "E-Q07341",
    course_duration: "0:05:00",
  },
  {
    id: 207,
    course_name: "Jobs to Be Done:  Part 1",
    category: "JTBD",
    course_unique_id: "E-P08XE1",
    course_duration: "1:30:00",
  },
  {
    id: 208,
    course_name: "Jobs to Be Done:  Part 2",
    category: "JTBD",
    course_unique_id: "E-GVP3K1",
    course_duration: "1:30:00",
  },
  {
    id: 318,
    course_name: "LeadMX: Copa Airlines Case Study",
    category: "FC Insights",
    course_unique_id: "E-7VR5X1",
    course_duration: "0:07:00",
  },
  {
    id: 271,
    course_name: "Learn How MX Stands Out in the Industry.",
    category: "MXCX Team",
    course_unique_id: "E-YVM7J0",
    course_duration: "0:00:00",
  },
  {
    id: 137,
    course_name: "Make it Official",
    category: "BuildMX Week 1",
    course_unique_id: "E-P0YYG0",
    course_duration: "0:10:00",
  },
  {
    id: 66,
    course_name: "MEDDPICC",
    category: "Role responsibilities",
    course_unique_id: "E-7VR271",
    course_duration: "0:20:00",
  },
  {
    id: 90,
    course_name: "Modern Banking Infrastructure",
    category: "Industry",
    course_unique_id: "E-7VRY71",
    course_duration: "0:30:00",
  },
  {
    id: 56,
    course_name: "MoneyMap 101",
    category: "Docebo",
    course_unique_id: "E-GVP430",
    course_duration: "0:00:00",
  },
  {
    id: 144,
    course_name: "Mutual Evaluation Plans",
    category: "Sales Enablement Team",
    course_unique_id: "E-XVDKN1",
    course_duration: "0:10:00",
  },
  {
    id: 74,
    course_name: "MX COMMUNICATIONS SYSTEMS",
    category: "BuildMX Week 1",
    course_unique_id: "E-D19LP0",
    course_duration: "0:15:00",
  },
  {
    id: 417,
    course_name: "MX Compensation 101",
    category: "Docebo",
    course_unique_id: "E-71KDKV",
    course_duration: "0:00:00",
  },
  {
    id: 211,
    course_name: "MX Competitors 101",
    category: "BuildMX Week 1",
    course_unique_id: "E-Y1QQ71",
    course_duration: "0:20:00",
  },
  {
    id: 240,
    course_name: "MX Culture and Personal Minimums for New Hires",
    category: "BuildMX Week 2",
    course_unique_id: "I-E1L4E1",
    course_duration: "0:00:00",
  },
  {
    id: 230,
    course_name: "MX Interview Certification",
    category: "People Development",
    course_unique_id: "E-KVOLXV",
    course_duration: "0:45:00",
  },
  {
    id: 148,
    course_name: "MX Messaging",
    category: "BuildMX Week 2",
    course_unique_id: "E-6VXL7V",
    course_duration: "0:10:00",
  },
  {
    id: 354,
    course_name: "MX Pitch 101",
    category: "Certifications",
    course_unique_id: "E-KVOXQV",
    course_duration: "0:10:00",
  },
  {
    id: 358,
    course_name: "MX Pitch Builder",
    category: "Industry",
    course_unique_id: "E-N059MV",
    course_duration: "0:10:00",
  },
  {
    id: 419,
    course_name: "MX Privacy 101",
    category: "Annual Mandatory Trainings",
    course_unique_id: "E-5VNJG0",
    course_duration: "0:15:00",
  },
  {
    id: 139,
    course_name: "MX Product Crash Course: CONNECTIVITY",
    category: "BuildMX Week 1",
    course_unique_id: "E-P0WM31",
    course_duration: "0:20:00",
  },
  {
    id: 150,
    course_name: "MX Product Crash Course: DATA",
    category: "BuildMX Week 2",
    course_unique_id: "E-D19440",
    course_duration: "0:20:00",
  },
  {
    id: 161,
    course_name: "MX Product Crash Course: EXPERIENCE",
    category: "BuildMX Week 3",
    course_unique_id: "E-P0Y7G1",
    course_duration: "0:30:00",
  },
  {
    id: 382,
    course_name: "MX Security Training 2023",
    category: "MX Security All Company",
    course_unique_id: "E-N05XM1",
    course_duration: "0:20:00",
  },
  {
    id: 171,
    course_name: "MX Security: Audit Event Notes in Batcave",
    category: "Annual Mandatory Trainings",
    course_unique_id: "E-YVMJQV",
    course_duration: "0:10:00",
  },
  {
    id: 110,
    course_name: "MX Sensitivity",
    category: "MX Sensitivity Non Manager",
    course_unique_id: "E-N05D90",
    course_duration: "1:00:00",
  },
  {
    id: 233,
    course_name: "MX Sensitivity - Manager",
    category: "MX Sensitivity Manager",
    course_unique_id: "E-O06RQV",
    course_duration: "1:30:00",
  },
  {
    id: 246,
    course_name: "MX Sensitivity for California Workers",
    category: "MX Sensitivity Non Manager",
    course_unique_id: "E-GVZGP0",
    course_duration: "1:30:00",
  },
  {
    id: 154,
    course_name: "MX Solutions and Personas",
    category: "BuildMX Week 2",
    course_unique_id: "E-KVOWP0",
    course_duration: "0:10:00",
  },
  {
    id: 170,
    course_name: "MX Systems: Okta and VPN",
    category: "BuildMX Week 1",
    course_unique_id: "E-GVZXE1",
    course_duration: "0:15:00",
  },
  {
    id: 195,
    course_name: "New Hire Experience Survey",
    category: "BuildMX Week 1",
    course_unique_id: "E-YVMYQV",
    course_duration: "0:10:00",
  },
  {
    id: 199,
    course_name: "New Hire Experience Survey",
    category: "BuildMX Week 3",
    course_unique_id: "E-J0E4QV",
    course_duration: "0:10:00",
  },
  {
    id: 404,
    course_name: "OAUTH 101",
    category: "TechOps Level 1 Certification",
    course_unique_id: "E-602451",
    course_duration: "0:30:00",
  },
  {
    id: 93,
    course_name: "Open Finance 101",
    category: "Industry",
    course_unique_id: "E-G03M81",
    course_duration: "0:27:00",
  },
  {
    id: 306,
    course_name: "Overview of MX Messaging",
    category: "MXCX Team",
    course_unique_id: "E-KVO5Q1",
    course_duration: "0:00:00",
  },
  {
    id: 309,
    course_name:
      "Overview of Systems We Use to Track Data: Last Two Letters In MEDDPICC",
    category: "MXCX Team",
    course_unique_id: "E-O06M51",
    course_duration: "0:00:00",
  },
  {
    id: 308,
    course_name:
      "Overview of Systems We Use to Track Data: Second Two Letters In MEDDPICC",
    category: "MXCX Team",
    course_unique_id: "E-GVPQX1",
    course_duration: "0:00:00",
  },
  {
    id: 115,
    course_name: "Partnerships 101",
    category: "Industry",
    course_unique_id: "E-P0W630",
    course_duration: "0:15:00",
  },
  {
    id: 349,
    course_name: "Performance Development: Introduction",
    category: "Performance Development Courses",
    course_unique_id: "E-E0J39V",
    course_duration: "0:07:00",
  },
  {
    id: 179,
    course_name: "Platform API",
    category: "Product",
    course_unique_id: "E-P08J60",
    course_duration: "0:15:00",
  },
  {
    id: 428,
    course_name: "Pre-work: Payments Solutions Deep Dive",
    category: "Docebo",
    course_unique_id: "E-602850",
    course_duration: "0:00:00",
  },
  {
    id: 213,
    course_name: "Preparing for 360 Feedback",
    category: "People Development",
    course_unique_id: "E-P0YJE0",
    course_duration: "0:15:00",
  },
  {
    id: 120,
    course_name: "Sales Negotiation Skills",
    category: "Sales skills",
    course_unique_id: "E-XVD7N0",
    course_duration: "0:15:00",
  },
  {
    id: 15,
    course_name: "Sales Team Ramp Up: Week 1 Day 1",
    category: "Onboarding week 1",
    course_unique_id: "E-P0WZ0J",
    course_duration: "0:51:00",
  },
  {
    id: 16,
    course_name: "Sales Team Ramp Up: Week 1 Day 2",
    category: "Onboarding week 1",
    course_unique_id: "E-E1LZV4",
    course_duration: "0:51:00",
  },
  {
    id: 20,
    course_name: "Sales Team Ramp Up: Week 1 Day 3",
    category: "Onboarding week 1",
    course_unique_id: "E-XVDPV2",
    course_duration: "0:58:00",
  },
  {
    id: 21,
    course_name: "Sales Team Ramp Up: Week 1 Day 4",
    category: "Onboarding week 1",
    course_unique_id: "E-G0381J",
    course_duration: "0:54:00",
  },
  {
    id: 22,
    course_name: "Sales Team Ramp Up: Week 1 Day 5",
    category: "Onboarding week 1",
    course_unique_id: "E-GVZ808",
    course_duration: "0:55:00",
  },
  {
    id: 26,
    course_name: "Sales Team Ramp Up: Week 2 Day 1",
    category: "Onboarding week 2",
    course_unique_id: "E-D19WP1",
    course_duration: "0:50:00",
  },
  {
    id: 27,
    course_name: "Sales Team Ramp Up: Week 2 Day 2",
    category: "Onboarding week 2",
    course_unique_id: "E-J0EO8V",
    course_duration: "0:50:00",
  },
  {
    id: 31,
    course_name: "Sales Team Ramp Up: Week 2 Day 3",
    category: "Onboarding week 2",
    course_unique_id: "E-P08WJV",
    course_duration: "0:50:00",
  },
  {
    id: 32,
    course_name: "Sales Team Ramp Up: Week 2 Day 4",
    category: "Onboarding week 2",
    course_unique_id: "E-GVP53V",
    course_duration: "1:00:00",
  },
  {
    id: 33,
    course_name: "Sales Team Ramp Up: Week 2 Day 5",
    category: "Onboarding week 2",
    course_unique_id: "E-O06KR0",
    course_duration: "0:55:00",
  },
  {
    id: 34,
    course_name: "Sales Team Ramp Up: Week 3 Day 1",
    category: "Onboarding week 3",
    course_unique_id: "E-N05WZV",
    course_duration: "0:55:00",
  },
  {
    id: 35,
    course_name: "Sales Team Ramp Up: Week 3 Day 2",
    category: "Onboarding week 3",
    course_unique_id: "E-Y1QWZ1",
    course_duration: "0:50:00",
  },
  {
    id: 36,
    course_name: "Sales Team Ramp Up: Week 3 Day 3",
    category: "Onboarding week 3",
    course_unique_id: "E-Z1GL9V",
    course_duration: "0:50:00",
  },
  {
    id: 37,
    course_name: "Sales Team Ramp Up: Week 3 Day 4",
    category: "Onboarding week 3",
    course_unique_id: "E-P0YX21",
    course_duration: "0:50:00",
  },
  {
    id: 38,
    course_name: "Sales Team Ramp Up: Week 3 Day 5",
    category: "Onboarding week 3",
    course_unique_id: "E-Q07Y2V",
    course_duration: "1:00:00",
  },
  {
    id: 39,
    course_name: "Sales Team Ramp Up: Week 4 Day 1",
    category: "Onboarding week 4",
    course_unique_id: "E-P0WEZ0",
    course_duration: "0:45:00",
  },
  {
    id: 40,
    course_name: "Sales Team Ramp Up: Week 4 Day 2",
    category: "Onboarding week 4",
    course_unique_id: "E-E1LNZV",
    course_duration: "0:55:00",
  },
  {
    id: 41,
    course_name: "Sales Team Ramp Up: Week 4 Day 3",
    category: "Onboarding week 4",
    course_unique_id: "E-71KND1",
    course_duration: "0:55:00",
  },
  {
    id: 42,
    course_name: "Sales Team Ramp Up: Week 4 Day 4",
    category: "Onboarding week 4",
    course_unique_id: "E-7VR670",
    course_duration: "0:45:00",
  },
  {
    id: 43,
    course_name: "Sales Team Ramp Up: Week 4 Day 5",
    category: "Onboarding week 4",
    course_unique_id: "E-5VNPQ0",
    course_duration: "1:00:00",
  },
  {
    id: 400,
    course_name: "TECHOPS BEST PRACTICES",
    category: "TechOps Level 1 Certification",
    course_unique_id: "E-6VXKK0",
    course_duration: "0:20:00",
  },
  {
    id: 92,
    course_name: "The Banking Customer Experience",
    category: "Industry",
    course_unique_id: "E-XVDGPV",
    course_duration: "0:10:00",
  },
  {
    id: 77,
    course_name: "The Challenger Sale Method",
    category: "Sales skills",
    course_unique_id: "E-E045KV",
    course_duration: "0:40:00",
  },
  {
    id: 258,
    course_name: "The Python Workshop",
    category: "Go1 Content",
    course_unique_id: "E-N05ZEV",
    course_duration: "0:00:00",
  },
  {
    id: 356,
    course_name: "Trip Action/Liquid Cards Team Member Training",
    category: "People Development",
    course_unique_id: "E-GVPJXV",
    course_duration: "1:00:00",
  },
  {
    id: 415,
    course_name: "TROUBLESHOOTING 101",
    category: "Docebo",
    course_unique_id: "E-P0W5W1",
    course_duration: "0:00:00",
  },
  {
    id: 420,
    course_name: "Understanding the MX Values",
    category: "Annual Mandatory Trainings",
    course_unique_id: "E-XVDDQV",
    course_duration: "0:15:00",
  },
  {
    id: 73,
    course_name: "US State of Finances",
    category: "BuildMX Week 1",
    course_unique_id: "E-E0JPM0",
    course_duration: "0:15:00",
  },
  {
    id: 275,
    course_name: "We Act as Brand Champions",
    category: "MXCX Team",
    course_unique_id: "E-J0EGG1",
    course_duration: "0:00:00",
  },
  {
    id: 272,
    course_name: "We Believe in Philanthropic Capitalism",
    category: "MXCX Team",
    course_unique_id: "E-6VXDX1",
    course_duration: "0:00:00",
  },
  {
    id: 273,
    course_name: "We Promote Our Mission With BOLDNESS",
    category: "MXCX Team",
    course_unique_id: "E-E0JGW0",
    course_duration: "0:00:00",
  },
  {
    id: 169,
    course_name: "Week 3 Review",
    category: "BuildMX Week 3",
    course_unique_id: "E-G03K30",
    course_duration: "0:10:00",
  },
  {
    id: 143,
    course_name: "Week One Review",
    category: "BuildMX Week 1",
    course_unique_id: "E-5VNERV",
    course_duration: "0:10:00",
  },
  {
    id: 157,
    course_name: "Week Two Review",
    category: "BuildMX Week 2",
    course_unique_id: "E-O069P0",
    course_duration: "0:10:00",
  },
  {
    id: 131,
    course_name: "Welcome to MX",
    category: "BuildMX Week 1",
    course_unique_id: "E-P08860",
    course_duration: "0:15:00",
  },
  {
    id: 94,
    course_name: "Welcome to Workday",
    category: "Workday",
    course_unique_id: "E-GVZ781",
    course_duration: "0:15:00",
  },
  {
    id: 277,
    course_name: "What is Silicon Slopes?",
    category: "MXCX Team",
    course_unique_id: "E-E044O0",
    course_duration: "0:00:00",
  },
  {
    id: 283,
    course_name: "What's a Fintech?",
    category: "MXCX Team",
    course_unique_id: "E-Y1QZ71",
    course_duration: "0:00:00",
  },
  {
    id: 412,
    course_name: "WIDGETS 101",
    category: "Docebo",
    course_unique_id: "E-Z1GD41",
    course_duration: "0:00:00",
  },
  {
    id: 203,
    course_name: "Workday Financial: Procurement Process",
    category: "Workday",
    course_unique_id: "E-J0E4GV",
    course_duration: "0:10:00",
  },
  {
    id: 405,
    course_name: "ZENDESK 101",
    category: "TechOps Level 1 Certification",
    course_unique_id: "E-E048PV",
    course_duration: "0:30:00",
  },
  {
    id: 133,
    course_name: "2021 Benefit Details and Benefits Education",
    category: "People Development Archived Courses",
    course_unique_id: "E-O06GP1",
    course_duration: "0:30:00",
  },
  {
    id: 346,
    course_name: "2022 Benefit Details and Benefits Education",
    category: "BuildMX Week 1",
    course_unique_id: "E-GVZQGV",
    course_duration: "0:30:00",
  },
  {
    id: 299,
    course_name: "MX APIs Timeline",
    category: "MXCX Team",
    course_unique_id: "E-J0EMG1",
    course_duration: "0:00:00",
  },
  {
    id: 101,
    course_name: "MX Security Training",
    category: "MX Security All Company",
    course_unique_id: "E-E0JXK1",
    course_duration: "0:20:00",
  },
  {
    id: 247,
    course_name: "Workday Financial: Amazon Business Purchasing",
    category: "People Development Archived Courses",
    course_unique_id: "E-YVMOJ0",
    course_duration: "0:10:00",
  },
  {
    id: 204,
    course_name: "Workday Financial: Expenses and Reimbursements",
    category: "People Development Archived Courses",
    course_unique_id: "E-602NLV",
    course_duration: "0:10:00",
  },
];

function IndexGenerator() {
  const { authState, oktaAuth } = useOktaAuth();
  const [oktaInfo, setOktaInfo] = useState([]);
  const [mainIndex, setMainIndex] = useState([]);
  const [glossary, setGlossary] = useState([]);
  const [serviceNow, setServiceNow] = useState([]);
  const [mxEdu, setMxEdu] = useState([]);
  const [unlocked, setUnlocked] = useState(true);
  const [currentDataSet, setCurrentDataSet] = useState("");

  async function directusFetch(collection, fields) {
    let filterParams = "filter[status][_eq]=published";
    filterParams += `&limit=-1`;
    filterParams += fields;
    let data = await api.collection(collection, oktaAuth, filterParams);
    return data;
  }

  const cleanTheContent = (content) => {
    const parser = new DOMParser();
    let cleanContent;

    if (!content) {
      cleanContent = null;
    } else {
      cleanContent = parser.parseFromString(content, "text/html").body
        .innerText;
      if (cleanContent.length >= 300) {
        cleanContent = cleanContent.substring(0, 300).concat("...").trim();
      } else {
        cleanContent = cleanContent.substring(0, 300).trim();
      }
      if (
        cleanContent.includes("unable to view the document") ||
        cleanContent.length < 10
      ) {
        cleanContent = null;
      }
    }

    content = cleanContent;

    return content;
  };

  function fieldRemap(collection, data) {
    // Fields to be defined

    // status
    // id
    // tags
    // url
    // title
    // content
    // date
    // content_logo
    // image
    // content_type
    // external

    if (collection == "articles") {
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        element.article_url = `/library/${element.article_url}/`;

        // map keys to match
        element.objectID = `articles-${element.id}`;
        element.url = element.article_url;
        element.title = element.article_title;
        element.date = element.update_date;
        element.description = element.searchable_description;
        element.content_logo =
          "https://assets.mx.com/hub/images/search/white-hub-logo.png";
        if (element.featured_image) {
          element.image = `https://directus.hub.mx.com/assets/${element.featured_image}`;
        } else {
          element.image = "https://assets.mx.com/hub/images/no-img-img.png";
        }
        element.content_type = "Article";
        element.external = false;
        element.content =
          element.description > 0
            ? element.description
            : cleanTheContent(element.article_content);

        // delete old keys
        delete element.description;
        delete element.update_date;
        delete element.article_url;
        delete element.article_title;
        delete element.article_content;
        delete element.searchable_description;
        delete element.featured_image;
      }
    }
    if (collection == "announcements") {
      for (let index = 0; index < data.length; index++) {
        const element = data[index];

        // map keys to match
        element.objectID = `announcements-${element.id}`;
        element.url = `/comms/${element.slug}/`;
        element.title = element.post_title;
        element.date = element.publish_date;
        element.description = element.post_subtitle;
        element.content_logo =
          "https://assets.mx.com/hub/images/search/white-hub-logo.png";
        if (element.featured_image) {
          element.image = `https://directus.hub.mx.com/assets/${element.featured_image}`;
        } else {
          element.image = "https://assets.mx.com/hub/images/no-img-img.png";
        }
        element.content_type = "Communication";
        element.external = false;
        element.content =
          element.description > 0
            ? element.description
            : cleanTheContent(element.post_content);

        // delete old keys
        delete element.description;
        delete element.publish_date;
        delete element.slug;
        delete element.post_title;
        delete element.post_content;
        delete element.post_subtitle;
        delete element.featured_image;
      }
    }
    if (collection == "resources") {
      for (let index = 0; index < data.length; index++) {
        const element = data[index];

        // map keys to match
        element.objectID = `resources-${element.id}`;
        element.url = element.link;
        element.title = element.title;
        element.date = null;
        element.description = element.description;
        element.content_logo =
          "https://assets.mx.com/hub/images/search/white-hub-logo.png";
        if (element.thumbnail_image) {
          element.image = `https://directus.hub.mx.com/assets/${element.thumbnail_image}`;
        } else {
          element.image = "https://assets.mx.com/hub/images/no-img-img.png";
        }
        element.content_type = "Resources";
        element.external = false;
        element.content = null;
      }
    }

    if (collection == "MX Docs") {
      for (let index = 0; index < data.length; index++) {
        const element = data[index];

        // map new keys
        element.content_type = "MX Docs";
        //element.title
        //element.url
        element.content_logo =
          "https://assets.mx.com/hub/images/search/docs-logo-dark.svg";
        element.external = true;
        element.id = null;
        element.status = null;
        element.tags = null;
        element.image = null;
        element.content = null;
        element.description = null;
        element.date = null;
      }
    }

    if (collection == "mx academy") {
      for (let index = 0; index < data.length; index++) {
        const element = data[index];

        // map new keys
        // id
        // tags
        // url
        // title
        // content
        // date
        // content_logo
        // image
        // content_type
        // external
        // image
        // description

        if (element.draft == false) {
          element.status = "published";
        } else {
          element.status = "draft";
        }
        // element.id
        element.tags = null;
        element.url = element.html_url;
        // element.title
        element.content = cleanTheContent(element.body);
        element.date = element.created_at.split("T")[0];
        element.unix_date = parseInt(
          (new Date(element.date.replace("-", ".")).getTime() / 1000).toFixed(0)
        );
        element.content_logo =
          "https://assets.mx.com/hub/images/search/mx-academy-logo.png";
        element.content_type = "MX Academy";
        element.external = true;
        element.image = null;
        element.description = null;

        // delete old keys
        delete element.html_url;
        delete element.author_id;
        delete element.comments_disabled;
        delete element.draft;
        delete element.promoted;
        delete element.position;
        delete element.vote_sum;
        delete element.vote_count;
        delete element.section_id;
        delete element.created_at;
        delete element.updated_at;
        delete element.name;
        delete element.source_locale;
        delete element.locale;
        delete element.outdated;
        delete element.outdated_locales;
        delete element.edited_at;
        delete element.user_segment_id;
        delete element.permission_group_id;
        delete element.content_tag_ids;
        delete element.label_names;
        delete element.body;
      }
    }

    return data;
  }

  const copyToClipboard = () => {
    if (currentDataSet == "main-index") {
      navigator.clipboard.writeText(JSON.stringify(mainIndex, null, 2));
    } else if (currentDataSet == "glossary") {
      navigator.clipboard.writeText(JSON.stringify(glossary, null, 2));
    } else if (currentDataSet == "servicenow") {
      navigator.clipboard.writeText(JSON.stringify(serviceNow, null, 2));
    }
  };

  useEffect(() => {
    async function fetchData() {
      if (currentDataSet == "main-index") {
        const collections = ["articles", "announcements", "resources"];

        const allCollectionData = await Promise.allSettled(
          collections.map(async (collection) => {
            let fields;
            let data;
            if (collection == "MX Docs") {
              fields = "";
              data = docsData;
            }
            if (collection == "resources") {
              fields = "";
              data = await directusFetch(collection, fields);
            }
            if (collection == "articles") {
              fields =
                "&fields[]=status&fields[]=id&fields[]=article_url&fields[]=alt_index_text&fields[]=article_title&fields[]=article_content&fields[]=update_date&fields[]=tags&fields[]=featured_image";
              data = await directusFetch(collection, fields);
            }
            if (collection == "announcements") {
              const today = new Date();
              const thirtyDaysAgo = new Date(
                today.getTime() - 30 * 24 * 60 * 60 * 1000
              )
                .toISOString()
                .split("T")[0];

              fields = `&fields[]=status&fields[]=id&fields[]=post_title&fields[]=post_subtitle&fields[]=slug&fields[]=post_content&fields[]=publish_date&fields[]=tags&fields[]=featured_image&filter[publish_date][_gte]=${thirtyDaysAgo}`;
              data = await directusFetch(collection, fields);
            }
            if (collection == "mx academy") {
              fields = "";

              const result = await axios.get(
                `https://academy.mx.com/api/v2/help_center/en-us/articles.json?page=1\u0026per_page=100`
              );

              let dataArr = [];

              for (let index = 1; index <= result.data.page_count; index++) {
                const result = await axios.get(
                  `https://academy.mx.com/api/v2/help_center/en-us/articles.json?page=${index}\u0026per_page=100`
                );

                dataArr.push(...result.data.articles);
              }

              data = dataArr;
            }
            data = fieldRemap(collection, data);
            return data;
          })
        );

        let collectionData = [];

        for (let index = 0; index < allCollectionData.length; index++) {
          collectionData = [
            ...collectionData,
            ...allCollectionData[index].value,
          ];
          // console.log(collectionData);
        }
        console.log(collectionData);
        setMainIndex(collectionData);
      } else if (currentDataSet == "glossary") {
        let collection = currentDataSet;
        let fields =
          "&fields[]=status&fields[]=id&fields[]=term&fields[]=definition";
        let data = await directusFetch(collection, fields);

        setGlossary(data);
      } else if (currentDataSet == "servicenow") {
        const snKeyMap = {
          short_description: "title",
          sys_updated_on: "updated_on",
          text: "content",
        };

        function renameKeys(array, keyMap) {
          return array.map((item) => {
            const renamedKeys = Object.keys(item).reduce((acc, key) => {
              if (key === "sys_id") {
                acc["url"] =
                  "https://mxtechnologies.service-now.com/hub/?id=kb_article&sys_id=" +
                  item[key];
              } else if (key === "text") {
                acc["text"] = item[key].replaceAll('"', "'");
              } else {
                acc[keyMap[key] || key] = item[key];
              }
              return acc;
            }, {});

            return renamedKeys;
          });
        }

        let renamedData = renameKeys(serviceNowData, snKeyMap);
      } else if (currentDataSet == "mxedu") {
        const eduKeyMap = {
          short_description: "title",
          sys_updated_on: "updated_on",
          text: "content",
        };

        function renameKeys(array, keyMap) {
          return array.map((item) => {
            const renamedKeys = Object.keys(item).reduce((acc, key) => {
              if (key === "sys_id") {
                acc["url"] =
                  "https://mxtechnologies.service-now.com/hub/?id=kb_article&sys_id=" +
                  item[key];
              } else if (key === "text") {
                acc["text"] = item[key].replaceAll('"', "'");
              } else {
                acc[keyMap[key] || key] = item[key];
              }
              return acc;
            }, {});

            return renamedKeys;
          });
        }

        let renamedData = renameKeys(serviceNowData, eduKeyMap);

        console.log(renamedData);
      }
    }
    if (unlocked && currentDataSet) {
      fetchData();
    }
  }, [unlocked, currentDataSet]);

  useEffect(() => {
    // Check if the user is authenticated
    if (authState.isAuthenticated) {
      const accessToken = authState.accessToken.accessToken;
      setOktaInfo(authState.idToken.claims);

      // Now you can use the accessToken as needed
      // For example, you can include it in your API requests
    }
  }, [oktaInfo]);

  useEffect(() => {
    let lowercaseEmail = oktaInfo.email;
    if (utils.adminList.includes(lowercaseEmail)) {
      setUnlocked(true);
    }
  }, [oktaInfo]);

  return (
    <PageWrapper>
      {unlocked && (
        <>
          <div className="controls">
            <div className="controls-wrapper">
              <h1>Index Data Generator</h1>
              <select onChange={(e) => setCurrentDataSet(e.target.value)}>
                <option value="">Select Data</option>
                <option value="main-index">Main Index</option>
                <option value="glossary">Glossary</option>
                <option value="servicenow">ServiceNow</option>
                <option value="mxedu">MX Edu</option>
                <option disabled value="confluence">
                  Confluence
                </option>
                <option disabled value="misc">
                  Misc
                </option>
              </select>
              <button
                onClick={() => {
                  copyToClipboard();
                }}
              >
                Copy to clipboard
              </button>
            </div>
          </div>
          <div className="content">
            {currentDataSet == "main-index" && (
              <div style={{ marginLeft: 30 }}>
                <pre>{JSON.stringify(mainIndex, null, 2)}</pre>
              </div>
            )}
            {currentDataSet == "servicenow" && (
              <pre>{JSON.stringify(serviceNow, null, 2)}</pre>
            )}
            {currentDataSet == "mxedu" && (
              <pre>{JSON.stringify(mxEdu, null, 2)}</pre>
            )}
            {currentDataSet == "glossary" && (
              <div style={{ marginLeft: 30 }}>
                <pre>{JSON.stringify(glossary, null, 2)}</pre>
              </div>
            )}
          </div>
        </>
      )}
    </PageWrapper>
  );
}

export default withTheme(IndexGenerator);
