import { createGlobalStyle } from "styled-components";

export const darkTheme = {
  name: "dark",
  navBackgroundColor: "#202020",
  backgroundColor: "#1C1C1C",
  tabsWrapper: "#424242",
  sectionColor: "#262626",
  cardColor: "#424242",
  textColor: "#FFFFFF",
  hoverBackground: "#444444",
  blue: "#2F73DA",
  overlayColor: "rgb(0 0 0 / 60%)",
  homeFooterBgColor: "#1C1C1C",
  gradientBg: "linear-gradient(90deg, rgba(0, 0, 0, 0) -17.41%, #FFFFFF 160%)",
  divider: "#313131",
  textGray: "#808080",
};

export const lightTheme = {
  name: "light",
  navBackgroundColor: "#F5F5F5",
  backgroundColor: "#FFFFFF",
  tabsWrapper: "#EDEDED",
  sectionColor: "#EDEDED",
  cardColor: "#FFFFFF",
  textColor: "#262626",
  hoverBackground: "#F5F5F5",
  blue: "#2F73DA",
  overlayColor: "rgb(0 0 0 / 25%)",
  homeFooterBgColor: "#EDEDED",
  gradientBg: "linear-gradient(90deg,rgba(255,255,255,0) -18.41%,#000000 290%)",
  divider: "#bbbbbb",
  textDisabled: "#808080",
  textGray: "#808080",
};

export const GlobalStyles = createGlobalStyle`

    body {
        background: ${(props) => props.theme.backgroundColor}
    }

`;
