import React, { useState, useEffect, createContext } from "react";
import styled from "styled-components";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  Redirect,
} from "react-router-dom";
import {
  LoginCallback,
  SecureRoute,
  Security,
  useOktaAuth,
} from "@okta/okta-react";
import Gap from "./components/gap";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import CustomPage from "./components/CustomPage";
import { ThemeProvider } from "styled-components";
import { lightTheme, darkTheme, GlobalStyles } from "./themes";
import ThemeToggle from "./components/ThemeToggle";
import NotFound from "./components/NotFound";
import ScrollToTop from "./components/ScrollToTop";
import FoundersCircle from "./components/FoundersCircle";
import SidebarPage from "./components/SidebarPage";
import Library from "./components/Library";
import NewsEvents from "./components/NewsEvents";
import Resources from "./components/Resources";
import Article from "./components/Article";
import Glossary from "./components/Glossary";
import CompetencyLibrary from "./components/CompetencyLibrary";
import Signature from "./components/Signature";
import GenerateIndex from "./components/generate-index";
import TagResults from "./components/TagResults";
import GoogleCalendarLinkBuilder from "./components/GoogleCalendarLinkBuilder";
import ManagerPortal from "./components/ManagerPortal";
import Summit from "./components/Summit";
import Requests from "./components/Requests";

import Ask from "./components/Ask";
import CalPage from "./components/CompanyCalendar";
import Lunches from "./components/Lunches";
import SocialTool from "./components/SocialTool";
import Utilities from "./components/Utilities";
import HolidayParty from "./components/HolidayParty";

const oktaAuth = new OktaAuth({
  issuer: "https://mx.okta.com/oauth2/default",
  clientId: "0oas1djf7pnMolL7K0x7",
  redirectUri: window.location.origin + "/login/callback",
});

const App = () => {
  const savedTheme = localStorage.getItem("mx-hub-theme") || "light";
  const savedVersion = localStorage.getItem("mx-hub-version") || "old";
  const [theme, setTheme] = useState(savedTheme);
  const [uiVersion, setUiVersion] = useState(savedVersion);

  const history = useHistory();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  const themeStyles = theme == "light" ? lightTheme : darkTheme;
  document.getElementById("root").style.background =
    themeStyles.backgroundColor;

  // const oktaInfoString = localStorage.getItem("okta-token-storage");
  // console.log(oktaInfoString);
  // const oktaInfo = JSON.parse(oktaInfoString).idToken.claims;
  // // console.log(JSON.parse(oktaInfoString).idToken.claims);

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <ThemeProvider theme={themeStyles}>
        <ThemeToggle setTheme={setTheme} />
        <ScrollToTop />
        <Switch>
          {/* Begin Redirects */}

          <SecureRoute path="/library/mx-code-of-conduct/" exact={true}>
            <Redirect to="/library/code-of-conduct/" />
          </SecureRoute>
          <SecureRoute
            path="/library/mx-reorganization-department-all-hands-decks-/"
            exact={true}
          >
            <Redirect to="/library/mx-reorganization-department-all-hands-decks/" />
          </SecureRoute>

          <SecureRoute
            path="/library/mx-reorganization-team-member-talking-points-/"
            exact={true}
          >
            <Redirect to="/library/mx-reorganization-team-member-talking-points/" />
          </SecureRoute>

          <SecureRoute path="/library/mr-copy-custom-copy-tool-/" exact={true}>
            <Redirect to="/library/mr-copy-custom-copy-tool/" />
          </SecureRoute>
          <SecureRoute
            path="/comms/mx-kickoff-top-golf-event-january-8"
            exact={true}
          >
            <Redirect to="/comms/mx-kickoff-top-golf-event-february-8" />
          </SecureRoute>
          <SecureRoute path="/comms/" exact={true}>
            <Redirect to="/upcoming/" />
          </SecureRoute>

          <SecureRoute path="/holiday-party/" exact={true}>
            <Redirect to="/holiday/" />
          </SecureRoute>

          {/* End Redirects */}

          <SecureRoute path="/" exact={true}>
            <SidebarPage fullWidthContent={true} noMarginPadding home>
              {/* Home Component is in the SidebarPage */}
            </SidebarPage>
          </SecureRoute>
          <SecureRoute path="/tags/:tag">
            <SidebarPage>
              <TagResults />
            </SidebarPage>
          </SecureRoute>
          <SecureRoute path="/upcoming/">
            <SidebarPage noMarginPadding={true}>
              <NewsEvents />
            </SidebarPage>
          </SecureRoute>
          <SecureRoute path="/resources/">
            <SidebarPage>
              <Resources />
            </SidebarPage>
          </SecureRoute>
          <SecureRoute path="/share/" exact={true}>
            <SidebarPage noMarginPadding>
              <SocialTool />
            </SidebarPage>
          </SecureRoute>
          <SecureRoute path="/index/">
            <GenerateIndex />
          </SecureRoute>
          <SecureRoute path="/brand-library/" exact>
            <Redirect to="/resources/" />
          </SecureRoute>
          <SecureRoute
            path="/brand-library/wallpapers/"
            component={() => {
              window.location.href =
                "https://drive.google.com/drive/folders/1me8HugG0gDy-10Q5-WMdcZp973fyiL3g";
              return null;
            }}
          ></SecureRoute>
          <SecureRoute path="/calendar-invite/">
            <SidebarPage>
              <Gap size={50} />
              <GoogleCalendarLinkBuilder />
            </SidebarPage>
          </SecureRoute>
          <SecureRoute path="/signature/">
            <SidebarPage>
              <Gap size={50} />
              <Signature />
            </SidebarPage>
          </SecureRoute>

          <SecureRoute path="/manager-portal/">
            <SidebarPage fullWidthContent={true}>
              <ManagerPortal />
            </SidebarPage>
          </SecureRoute>

          <SecureRoute path="/new-cal/">
            <SidebarPage>
              <CalPage />
            </SidebarPage>
          </SecureRoute>

          <SecureRoute path="/lunch/">
            <Redirect to="/upcoming/?lunch" />
          </SecureRoute>

          <SecureRoute path="/library/:slug">
            <SidebarPage fullWidthContent={true} noMarginPadding>
              <Article />
            </SidebarPage>
          </SecureRoute>
          <SecureRoute path="/library/" exact={true}>
            <SidebarPage noMarginPadding>
              <Library />
            </SidebarPage>
          </SecureRoute>

          <SecureRoute path="/comms/:slug">
            <SidebarPage noMarginPadding>
              <Article articleType={"comms"} />
            </SidebarPage>
          </SecureRoute>

          <SecureRoute path="/glossary/" exact={true}>
            <SidebarPage noMarginPadding>
              <Glossary />
            </SidebarPage>
          </SecureRoute>

          <SecureRoute path="/competency-library/" exact={true}>
            <SidebarPage noMarginPadding>
              <CompetencyLibrary />
            </SidebarPage>
          </SecureRoute>

          <SecureRoute path="/requests/" exact={true}>
            <SidebarPage noMarginPadding>
              <Requests />
            </SidebarPage>
          </SecureRoute>

          <SecureRoute path="/utilities/" exact={true}>
            <SidebarPage noMarginPadding>
              <Utilities />
            </SidebarPage>
          </SecureRoute>

          <SecureRoute path="/summit/" exact={true}>
            <SidebarPage>
              <Summit theme={theme} />
            </SidebarPage>
          </SecureRoute>

          <SecureRoute path="/ask/">
            <SidebarPage noMarginPadding>
              <Ask />
            </SidebarPage>
          </SecureRoute>

          <SecureRoute path="/founders-circle/">
            <SidebarPage>
              <FoundersCircle />
            </SidebarPage>
          </SecureRoute>

          <SecureRoute path="/pages/:url">
            <SidebarPage>
              <CustomPage />
            </SidebarPage>
          </SecureRoute>

          <SecureRoute path="/holiday/">
            <SidebarPage noMarginPadding>
              <HolidayParty />
            </SidebarPage>
          </SecureRoute>

          <Route path="/login/callback" component={LoginCallback} />
          <SecureRoute path="*">
            <SidebarPage>
              <NotFound />
            </SidebarPage>
          </SecureRoute>
        </Switch>
      </ThemeProvider>
    </Security>
  );
};

export default App;
