import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import authorPlaceholder from "../assets/images/author-placeholder.png";
import api from "./api";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import { useOktaAuth } from "@okta/okta-react";

const PageWrapper = styled.section`
  background: ${({ theme }) => theme.backgroundColor};

  .boxed-wrapper {
    max-width: 1440px;
    margin: 0 auto;
  }
`;

const PostContent = styled.div``;

function CustomPage() {
  let { id, url } = useParams();
  const [post, setPost] = useState({});
  const [oktaInfo, setOktaInfo] = useState([]);
  const postWrapperRef = useRef(null);
  const { authState, oktaAuth } = useOktaAuth();

  useEffect(() => {
    async function fetchData() {
      let filterParams = "filter[url][_eq]=" + url;
      let post = await api.collection("pages", oktaAuth, filterParams);
      setPost(post[0]);
    }
    fetchData();
  }, []);

  useEffect(() => {
    // Check if the user is authenticated
    if (authState.isAuthenticated) {
      const accessToken = authState.accessToken.accessToken;
      setOktaInfo(authState.idToken.claims);

      // Now you can use the accessToken as needed
      // For example, you can include it in your API requests
    }
  }, [oktaInfo]);

  let htmlContent = parse(String(post.page_html));
  let wysiwygContent = parse(String(post.page_content));

  return (
    post && (
      <>
        <Navigation />
        <PageWrapper>
          <PostContent className={post.width == "boxed" && "boxed-wrapper"}>
            {post.content_type == "html" ? htmlContent : wysiwygContent}
          </PostContent>
        </PageWrapper>
        <style>{post.page_css}</style>
      </>
    )
  );
}

export default CustomPage;
