import styled from "styled-components";

const ButtonOnClick = styled.div`
  transition: all 0.2s ease;
  display: inline-block;
  &:hover {
    /* transform: scale(0.99); */
  }

  background: ${({ theme }) => theme.blue};
  font-size: 14px;
  line-height: 1;
  color: #fff;
  padding: 7px 30px;
  border-radius: 25px;
  cursor: pointer;
  font-size: 14px;
  text-transform: uppercase;
  text-decoration: none;

  &:visited {
    text-decoration: none;
  }

  &:active {
    opacity: 0.5;
  }
`;

const ButtonLink = styled.a`
  transition: all 0.2s ease;
  display: inline-block;

  &:hover {
    /* transform: scale(0.99); */
  }
  background: ${({ theme }) => theme.blue};
  font-size: 14px;
  line-height: 1;
  color: #fff;
  padding: 7px 30px;
  border-radius: 25px;
  cursor: pointer;
  font-size: 14px;
  text-transform: uppercase;
  text-decoration: none;

  &:visited {
    text-decoration: none;
  }

  &:active {
    opacity: 0.5;
  }
`;

const Button = ({ href, children, onClick, notALink, id }) => {
  return (
    <>
      {onClick ? (
        <ButtonOnClick id={id} onClick={onClick} className="primary-button">
          {children}
        </ButtonOnClick>
      ) : (
        <ButtonLink id={id} href={href} className="primary-button">
          {children}
        </ButtonLink>
      )}
    </>
  );
};

export default Button;
