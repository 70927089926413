import styled from "styled-components";

const ButtonLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.textColor};
  display: inline-block;
  margin: 0;
  box-sizing: border-box;
`;
const ButtonOnClick = styled.p`
  display: inline-block;
  margin: 0;
  box-sizing: border-box;
`;
const ButtonStyles = styled.span`
  border: 3px solid ${({ theme }) => theme.textColor};
  ${(props) =>
    props.outlineColor && `border: 3px solid ${props.outlineColor};`};
  padding: 10px 30px;
  border-radius: 25px;
  ${(props) => props.width && `width: ${props.width}px;`};
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 18px;
  line-height: 1;
  text-transform: uppercase;
  box-sizing: border-box;
  color: ${({ theme }) => theme.textColor};
  display: inline-block;

  &:hover {
    border: 3px solid ${({ theme }) => theme.blue};
    ${(props) =>
      props.outlineColor && `border: 3px solid ${props.outlineColor};`};
  }
  &.active-button {
    border: 3px solid ${({ theme }) => theme.blue};
  }
`;

const OutlineButton = ({
  buttonText,
  href,
  onClick,
  width,
  className = "",
  children,
  outlineColor,
  id,
  rel,
  target,
}) => {
  return (
    <>
      {onClick ? (
        <ButtonOnClick onClick={onClick} className="outline-button" id={id}>
          <ButtonStyles
            className={className}
            width={width}
            outlineColor={outlineColor}
          >
            {buttonText ? buttonText : children}
          </ButtonStyles>
        </ButtonOnClick>
      ) : (
        <ButtonLink
          href={href}
          rel={rel}
          target={target}
          className="outline-button"
          id={id}
        >
          <ButtonStyles
            className={className}
            width={width}
            outlineColor={outlineColor}
          >
            {buttonText ? buttonText : children}
          </ButtonStyles>
        </ButtonLink>
      )}
    </>
  );
};

export default OutlineButton;
