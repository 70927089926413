import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import api from "./api";
import { useOktaAuth } from "@okta/okta-react";

const PageWrapper = styled.section`
  margin: 0 50px;

  @media only screen and (max-width: 1100px) {
    text-align: center;
  }

  h1 {
    color: ${({ theme }) => theme.textColor};
  }

  .buttons {
    width: 1100px;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    color: ${({ theme }) => theme.textColor};

    @media only screen and (max-width: 1100px) {
      flex-direction: column;
    }

    & > div {
      width: 500px;
      max-width: 100%;
      text-align: center;
      background: ${({ theme }) => theme.hoverBackground};
      padding: 20px;
      border-radius: 15px;
      box-sizing: border-box;

      &:first-of-type {
        margin-right: 20px;
      }

      @media only screen and (max-width: 1100px) {
        margin: 20px auto !important;
      }
    }

    h3 {
      font-size: 25px;
      margin: 0;
      margin: 0 0 20px 0;
    }

    p {
      font-size: 14px;
    }

    .sync-time {
      margin: 0;
    }
  }

  button {
    background: ${({ theme }) => theme.blue};
    color: #fff;
    padding: 10px 20px;
    border: none;
    font-weight: 600;
    border-radius: 5px;
    transition: transform 0.2s ease;
    cursor: pointer;
    margin-bottom: 10px;

    &:hover {
      transform: scale(0.98);
    }
  }

  .submit-status-icon {
    position: absolute;
    width: 15px; /* Set the size of the spinner */
    height: 15px; /* Set the size of the spinner */
    top: 10px;
    right: -25px;

    &.loading-spinner {
      animation: spin 1s linear infinite;
    }

    &.icon-success {
      background: #fff;
      border-radius: 50%;
    }
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

function Utilities() {
  const { authState, oktaAuth } = useOktaAuth();
  const [oktaInfo, setOktaInfo] = useState([]);
  const [dataUpdated, setDataUpdated] = useState(null);
  const [syncLog, setSyncLog] = useState(false);
  const [submitStatus, setSubmitStatus] = useState("");

  useEffect(() => {
    if (authState.isAuthenticated) {
      if (
        authState.idToken.claims.email === "ali.kirk@mx.com" ||
        authState.idToken.claims.email === "cameron.bullock@mx.com"
      ) {
      } else {
        window.location = "/";
      }
      setOktaInfo(authState.idToken.claims);
    }
  }, [oktaInfo]);

  // useEffect to reset dataUpdated after 2 seconds
  useEffect(() => {
    if (dataUpdated !== null) {
      const timer = setTimeout(() => {
        setSubmitStatus("");
        setDataUpdated(null);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, []);

  useEffect(() => {
    async function fetchData() {
      let filterParams = "limit=1";
      filterParams += "&sort=timestamp";

      const indexSyncMainData = await api.collection(
        "algolia_sync_log",
        oktaAuth,
        "limit=1&sort=-timestamp&filter[_and][0][index][_eq]=Main"
      );

      const indexSyncHubRequestFormsData = await api.collection(
        "algolia_sync_log",
        oktaAuth,
        "limit=1&sort=-timestamp&filter[_and][0][index][_eq]=Hub_request_forms"
      );
      const syncLogData = {
        main: indexSyncMainData[0],
        requests: indexSyncHubRequestFormsData[0],
      };

      setSyncLog(syncLogData);
    }
    fetchData();
  }, [dataUpdated]);

  const syncDirectus = async (e) => {
    e.preventDefault();
    console.log("directus-updating");
    setSubmitStatus("directus-updating");
    try {
      const response = await api.syncDirectusData(oktaAuth);
      if (response) {
        setDataUpdated("Directus data");
        setSubmitStatus("directus-updated");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const syncRequests = async (e) => {
    e.preventDefault();
    setSubmitStatus("requests-updating");
    try {
      const response = await api.syncRequestsData(oktaAuth);
      if (response) {
        setDataUpdated("Requests");
        setSubmitStatus("requests-updated");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  function formatDateTime(isoDateString) {
    if (!isoDateString) {
      return;
    }
    const date = new Date(isoDateString + "Z");

    const readableDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    const readableTime = date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      timeZone: "America/Denver",
    });

    return `${readableDate} at ${readableTime}`;
  }

  return (
    <PageWrapper>
      <h1>Search Sync Buttons</h1>
      <div className="buttons">
        <div>
          <h3>Sync Hub Content</h3>
          <button onClick={(e) => syncDirectus(e)}>
            Sync Content
            {submitStatus === "directus-updating" && (
              <img
                className="submit-status-icon button loading-spinner"
                src="https://assets.mx.com/hub/images/share/icon-spinner.svg"
              />
            )}
            {submitStatus === "directus-updated" && (
              <img
                className="submit-status-icon icon-success"
                src="https://assets.mx.com/hub/images/share/icon-check.svg"
              />
            )}
          </button>
          <p>
            Indexes Directus content (Articles, Comms, Resources, Misc Search
            Content) in Algolia. Indexed to the{" "}
            <b>
              <i>Main</i>
            </b>{" "}
            index.{" "}
          </p>
          <p className="sync-time">
            <i>Main</i> index last synced:{" "}
            {syncLog && <b>{formatDateTime(syncLog.main.timestamp)} MT</b>}
          </p>
        </div>

        <div>
          <h3>Sync Request Forms</h3>
          <button onClick={(e) => syncRequests(e)}>
            Sync Requests
            {submitStatus === "requests-updating" && (
              <img
                className="submit-status-icon button loading-spinner"
                src="https://assets.mx.com/hub/images/share/icon-spinner.svg"
              />
            )}
            {submitStatus === "requests-updated" && (
              <img
                className="submit-status-icon icon-success"
                src="https://assets.mx.com/hub/images/share/icon-check.svg"
              />
            )}
          </button>
          <p>
            Indexes ServiceNow request forms and Directus request forms in
            Algolia. Indexed to the{" "}
            <b>
              <i>Hub_request_forms</i>
            </b>{" "}
            index.{" "}
          </p>
          <p className="sync-time">
            <i>Hub_request_forms</i> index last synced:{" "}
            {syncLog && <b>{formatDateTime(syncLog.requests.timestamp)} MT</b>}
          </p>
        </div>
      </div>
    </PageWrapper>
  );
}

export default Utilities;
