import React, { useState, useEffect, useRef } from "react";
import styled, { withTheme } from "styled-components";
import parse from "html-react-parser";
import api from "../components/api";
import { useOktaAuth } from "@okta/okta-react";

const Wrap = styled.div`
  h1 {
    color: ${({ theme }) => theme.textColor};
    font-size: 48px;
    font-weight: 700;
    line-height: 1;
    text-transform: uppercase;
    margin-top: 70px;
    padding-left: 10px;

    @media only screen and (max-width: 600px) {
      font-size: 30px;
    }
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      padding: 0 10px;
      p {
        margin: 0;
        font-weight: 400;
        position: relative;
        z-index: 1;
      }
    }
  }
`;

const BaseCalendarStyles = styled.div`
  color: ${({ theme }) => theme.textColor};
  position: relative;

  .control-arrow {
    cursor: pointer;
  }

  #weekdays {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    height: 15px;
    margin: 5px 0;
  }
  .calendar.no-weekends {
    #weekdays {
      grid-template-columns: repeat(5, 1fr);
    }
    #month-days {
      grid-template-columns: repeat(5, 1fr);
    }
    .weekend {
      display: none;
    }
  }

  .calendar-header {
    padding: 20px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 20px;
    box-sizing: border-box;

    h1 {
      font-weight: 700;
      font-size: 20px;
      text-align: center;
      text-transform: uppercase;
      text-align: center;
      margin: 0;
      padding: 0;
    }
    #cal-prev-month {
      width: 50px;
      cursor: pointer;
    }
    #cal-next-month {
      width: 50px;
      text-align: right;
      cursor: pointer;
      img {
        transform: scaleX(-1);
      }
    }
  }

  #month-days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    align-items: start;

    @media only screen and (max-width: 900px) {
      position: relative;
    }

    .calendar-day {
      height: 100%;
      font-weight: 700;
      min-height: 150px;
      position: relative;

      ${(props) => props.popupEvents && "position: relative;"}

      @media only screen and (max-width: 900px) {
        position: unset;
      }

      &.other-day {
        color: ${({ theme }) => theme.textGray};
        font-weight: 500;
      }

      .day-date-num {
        padding: 8px 5px 0 5px;
        margin: 0;
      }

      &.weekend {
        color: ${({ theme }) => theme.textGray};
        font-weight: 500;
      }

      &.today {
        .day-date-num {
          border: 2px solid ${({ theme }) => theme.blue};
          border-radius: 50%;
          width: 18px;
          height: 17px;
          line-height: 1.1;
          padding: 8px;
          display: inline-block;
        }
      }
    }
  }
`;

const CalendarStyles = styled.div`
  margin: 30px;
  @media only screen and (max-width: 1700px) {
    max-width: 100%;
    border-bottom: 1px solid ${({ theme }) => theme.sectionColor};
  }

  .calendar-header {
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;

    h1 {
      font-weight: 700;
      font-size: 24px;
      text-align: center;
      text-transform: uppercase;
      text-align: center;
      margin: 0;
      padding: 0;
    }
    #cal-prev-month {
      width: 70px;
      cursor: pointer;
    }
    #cal-next-month {
      width: 70px;
      text-align: right;
      cursor: pointer;
      img {
        transform: scaleX(-1);
      }
    }

    .control-arrow {
      width: 12px;
      cursor: pointer;
    }
  }

  #weekdays {
    display: grid;
    grid-gap: 20px;
    height: 15px;
    margin: 0;
    margin-bottom: 30px;
    text-align: center;

    @media only screen and (max-width: 900px) {
      display: none;
    }

    .weekday {
      font-weight: 700;
      font-size: 12px;
      text-align: center;
      color: #808080;
      text-transform: uppercase;
    }
  }

  #month-days {
    display: grid;
    grid-gap: 0;
    column-gap: 20px;
    align-items: start;
    position: relative;
    grid-template-rows: repeat(
      ${(props) => (props.daysInMonth / 5 == 4 ? 4 : 5)},
      1fr
    );

    @media only screen and (max-width: 900px) {
      grid-template-rows: repeat(
        ${(props) => (props.daysInMonth / 5 == 4 ? 4 : 5)},
        1fr
      );
      grid-template-columns: 1fr !important;
    }

    .calendar-day {
      border-top: 1px solid ${({ theme }) => theme.textColor};
      text-align: center;
      box-sizing: border-box;
      padding: 10px 0;
      display: flex;
      flex-direction: column;
      height: initial;
      min-height: 200px;
      position: relative;

      @media only screen and (max-width: 600px) {
        height: initial !important;
        min-height: 100px;

        &:first-child {
          border: none;
          margin-top: 20px;
        }
      }

      p {
        line-height: 1;
        margin: 0;
      }

      .mobile-month {
        display: none;
        @media only screen and (max-width: 900px) {
          display: block;
        }
      }

      .lunch-icon {
        position: absolute;
        width: 20px;
        top: 10px;
        right: 10px;
      }

      .lunch-name {
        font-size: 18px;
        font-weight: 600;
        margin: 5px 0 10px 0;

        @media only screen and (max-width: 1100px) {
          font-size: 18px;
        }
      }
      .lunch-vendor {
        margin-top: 10px;
        font-size: 16px;
        font-weight: 400;

        @media only screen and (max-width: 1100px) {
          margin-top: 5px;
          font-size: 14px;
        }
      }
      .lunch-description {
        margin: 0;
        font-size: 14px;
        font-weight: 400;
        padding: 0 10px;

        @media only screen and (max-width: 1100px) {
          margin-bottom: 20px;
          font-size: 14px;
        }
      }
      .day-date {
        text-align: left;
        @media only screen and (max-width: 900px) {
          display: flex;
          align-items: center;
          text-align: left;
        }
      }

      .day-date-num {
        font-weight: 700;
        font-size: 20px;
        line-height: 1;
        text-transform: uppercase;
        color: ${({ theme }) => theme.blue};
        text-align: left;
        display: inline-block;
        margin: 0;
        padding: 0;

        @media only screen and (max-width: 900px) {
          font-size: 18px;
          line-height: 1;
          margin: 0;
          margin-left: 10px !important;
        }
      }
      &.other-day .day-date-num {
        color: ${({ theme }) => theme.textGray};
      }
      .event-title {
        @media only screen and (max-width: 900px) {
          font-size: 12px;
        }
      }

      &.today {
        .day-date-num {
          background: ${({ theme }) => theme.blue};
          color: #fff;
          height: 20px;
          width: 20px;
          border-radius: 50%;
          padding: 6px;
          line-height: 1;
          text-align: center;
          margin-top: -5px;
          display: flex;
          justify-content: center;
        }
      }
    }
  }
`;

const formatEventData = (theLunchData) => {
  let cleanedDataArr = [];

  // format the data

  // what every event should have
  // Name
  // description
  // Start date
  // End date
  // start Time
  // end time
  // all day
  // collab day (boolean)
  // range (boolean)
  // custom class
  // id

  // This is a custom formatter for the office_lunches collection
  theLunchData.map((lunch) => {
    let theData = [];
    let name = lunch.Title;
    let eventStart = lunch.date;
    let description = lunch.notes;
    let id = lunch.id;

    theData.push({
      name: `${name}`,
      description,
      start_date: eventStart,
      end_date: eventStart,
      start_time: "",
      end_time: "",
      all_day: false,
      collaboration_day: false,
      range: false,
      range_indv_date: null,
      custom_class: "office-lunch", // You can customize this based on your needs
      id,
      vendor: lunch.vendor,
    });

    cleanedDataArr.push(...theData);
  });
  return cleanedDataArr;
};

const getEventData = async (selectedMonth, selectedYear, oktaAuth) => {
  try {
    let filterParams = "limit=-1";
    filterParams += `&filter[_and][0][_and][0][month(date)][_eq]=${selectedMonth
      .toString()
      .padStart(
        2,
        "0"
      )}&filter[_and][0][_and][1][year(date)][_eq]=${selectedYear}`;

    const theLunchData = await api.collection(
      "office_lunches",
      oktaAuth,
      filterParams
    );
    // const theFormattedData = await formatEventData(theLunchData);
    return theLunchData;
  } catch (err) {
    console.log("error " + err);
  }
};

const sortedEventsByDay = (events) => {
  const organizedEvents = {};

  events.forEach((event) => {
    let startDate = event.date;

    startDate = startDate.toString().padStart(2, "0");

    // Create a new array for the day if it doesn't exist
    if (!organizedEvents[startDate]) {
      organizedEvents[startDate] = [];
    }

    // Push the event to the corresponding day
    organizedEvents[startDate].push(event);
  });

  return organizedEvents;
};

const loadUpTheCalendarData = async (selectedMonth, selectedYear, oktaAuth) => {
  const theEvents = await getEventData(selectedMonth, selectedYear, oktaAuth);
  const sortedEvents = await sortedEventsByDay(theEvents);
  return sortedEvents;
};

function getNextMonthsDates(inputDate, n) {
  const result = [];
  const currentDate = new Date(inputDate);
  for (let i = 1; i <= n; i++) {
    const newDate = new Date(currentDate);
    newDate.setDate(currentDate.getDate() + i);
    result.push(newDate.toISOString().split("T")[0]); // Format as YYYY-MM-DD
  }

  return result;
}
function getPrevMonthsDates(inputDate, n) {
  const result = [];
  const currentDate = new Date(inputDate);

  for (let i = 1; i <= n; i++) {
    const newDate = new Date(currentDate);
    newDate.setDate(currentDate.getDate() - i);
    result.push(newDate.toISOString().split("T")[0]); // Format as YYYY-MM-DD
  }

  return result;
}

// build the list of days
const getDaysOfTheMonth = (monthInfo) => {
  let daysInMonth = [];
  // create an array of all the days in the month
  // create filler days for prev month days and next month days

  let monthStartsOnWeekend =
    monthInfo.first_day_index === 6 || monthInfo.first_day_index === 0
      ? true
      : false;
  let monthEndsOnWeekend =
    monthInfo.last_day_index === 6 || monthInfo.last_day_index === 0
      ? true
      : false;

  // if no weekends then remove 1 from calculations
  let leadingOffset = monthInfo.no_weekends
    ? monthInfo.first_day_index - 1
    : monthInfo.first_day_index;
  let trailingOffset = monthInfo.no_weekends
    ? 5 - monthInfo.last_day_index
    : 6 - monthInfo.last_day_index;

  for (let index = 1; index <= monthInfo.last_day_date; index++) {
    const currentDay = new Date(
      `${monthInfo.month}/${index}/${monthInfo.year}`
    );

    const formattedDate = `${currentDay.getFullYear()}-${(
      currentDay.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${currentDay.getDate().toString().padStart(2, "0")}`;

    let isAWeekend =
      currentDay.getDay() === 6 || currentDay.getDay() === 0 ? true : false;
    // if we're hiding weekends then check that the day isn't a weekend
    if (monthInfo.no_weekends) {
      if (!isAWeekend) {
        daysInMonth.push({
          firstDay: index === 1 ? true : false,
          lastDay: index === monthInfo.last_day_date ? true : false,
          day_date: index,
          weekday_index: currentDay.getDay(),
          is_weekend: isAWeekend,
          full_date: `${monthInfo.year}-${monthInfo.month}-${index}`,
          days_events: monthInfo.events[formattedDate],
        });
      }
    } else {
      daysInMonth.push({
        firstDay: index === 1 ? true : false,
        lastDay: index === monthInfo.last_day_date ? true : false,
        day_date: index,
        weekday_index: currentDay.getDay(),
        is_weekend: isAWeekend,
        full_date: `${monthInfo.year}-${monthInfo.month}-${index}`,
        days_events: monthInfo.events[formattedDate],
      });
    }
  }

  // last months final days cell Generation

  let leadingDays = [];
  let trailingDays = [];

  let lastDay = parseInt(new Date(monthInfo.prev_month_last_day).getDate());
  let theDate = monthInfo.last_day;

  const prevMonthsEndingDays = getPrevMonthsDates(
    monthInfo.first_day,
    leadingOffset
  );
  const nextMonthLeadingDays = getNextMonthsDates(theDate, trailingOffset);

  if (monthInfo.no_weekends && monthStartsOnWeekend) {
    leadingOffset = 0;
    leadingDays = [];
  } else {
    // starting from nth offset to then count up the days in the loop to get the order right
    let lastDayStartCount = lastDay - (leadingOffset - 1);

    for (let i = 0; i < leadingOffset; i++) {
      const dayToAdd = {
        other_day: true,
        date: prevMonthsEndingDays[leadingOffset - 1 - i],
        day_date: prevMonthsEndingDays[leadingOffset - 1 - i].split("-")[2],
        days_events: monthInfo.events[nextMonthLeadingDays[i - 1]],
      };
      leadingDays.push(dayToAdd);
      lastDayStartCount++;
    }
  }

  // next months beginning Days Cell Generation

  if (monthInfo.no_weekends && monthEndsOnWeekend) {
    trailingOffset = 0;
    trailingDays = [];
  } else {
    trailingDays = [];
    for (let j = 1; j <= trailingOffset; j++) {
      const dayToAdd = {
        other_day: true,
        date: nextMonthLeadingDays[j - 1],
        day_date: parseInt(nextMonthLeadingDays[j - 1].split("-")[2]),
        days_events: monthInfo.events[nextMonthLeadingDays[j - 1]],
      };
      trailingDays.push(dayToAdd);
    }
  }

  daysInMonth = [...leadingDays, ...daysInMonth, ...trailingDays];

  return daysInMonth;
};

// get all the info for the selected Month
const getMonthInfo = async (
  selectedMonth,
  selectedYear,
  noWeekends,
  eventData
) => {
  let nextMonth = selectedMonth + 1;
  let prevMonth = selectedMonth - 1;
  if (selectedMonth == 12) {
    nextMonth = 1;
  }
  if (selectedMonth == 1) {
    prevMonth = 12;
  }

  const getNextMonth = (month, year) => {
    let nextMonth = (parseInt(month) + 1).toString().padStart(2, "0");
    let nextMonthYear = year;

    if (month === "12") {
      nextMonth = "01";
      nextMonthYear = parseInt(year) + 1;
    }

    return {
      month: month,
      year: year,
    };
  };

  const month = selectedMonth;
  const year = selectedYear;
  const firstDay = new Date(year, month - 1, 1);
  const nextMonthFirstDay = new Date(year, month, 1);
  const lastDay = new Date(year, month, 0);
  const prevMonthLastDay = new Date(year, prevMonth, 0);
  const prevMonthLastIndex = new Date(year, prevMonth, 0).getDay();

  let theInfo = {
    month: month,
    year: year,
    first_day: firstDay,
    first_day_index: firstDay.getDay(),
    last_day: lastDay,
    last_day_date: lastDay.getDate(),
    last_day_index: lastDay.getDay(),
    next_month_first_day: nextMonthFirstDay,
    next_month_first_day_full_date: getNextMonth(month, year)[month],
    next_month_first_day_index: nextMonthFirstDay.getDay(),
    prev_month_last_day: prevMonthLastDay,
    prev_month_last_day_index: prevMonthLastIndex,
    prev_month_last_day_full_date: "",
    no_weekends: noWeekends,
    events: eventData,
  };

  const monthDays = await getDaysOfTheMonth(theInfo);

  theInfo.month_days = monthDays;
  return theInfo;
};

function CompanyCalendar() {
  const { oktaAuth } = useOktaAuth();
  const [theEventData, setTheEventData] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [monthInfo, setMonthInfo] = useState(null);

  const [today, setToday] = useState(
    `${new Date().getFullYear()}-${
      new Date().getMonth() + 1
    }-${new Date().getDate()}`
  );

  const noWeekends = true;

  let days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  let fullDays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  if (noWeekends) {
    days = ["Mon", "Tue", "Wed", "Thu", "Fri"];
    fullDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
  }

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    (async () => {
      setMonthInfo(
        await getMonthInfo(
          selectedMonth,
          selectedYear,
          noWeekends,
          theEventData ? theEventData : []
        )
      );
    })();
  }, [selectedMonth, selectedYear, theEventData]);

  const handlePrevClick = () => {
    if (selectedMonth == 1) {
      setSelectedMonth(12);
      setSelectedYear((prevState) => prevState - 1);
    } else {
      setSelectedMonth((prevState) => prevState - 1);
    }
  };

  const handleNextClick = () => {
    if (selectedMonth == 12) {
      setSelectedMonth(1);
      setSelectedYear((prevState) => prevState + 1);
    } else {
      setSelectedMonth((prevState) => prevState + 1);
    }
  };

  useEffect(() => {
    (async () => {
      let allTheEvents = await loadUpTheCalendarData(
        selectedMonth,
        selectedYear,
        oktaAuth
      );
      setTheEventData(allTheEvents);
    })();
  }, [selectedMonth, selectedYear]);

  return (
    <Wrap>
      <h1>Lunch Calendar</h1>
      <BaseCalendarStyles>
        <CalendarStyles>
          <div className={`calendar ${noWeekends ? "no-weekends" : ""} `}>
            <div className="popup-bg-overlay" />
            <div className="calendar-header">
              <img
                className="control-arrow prev-arrow"
                src="https://assets.mx.com/hub/images/icon-arrow-blue.svg"
                onClick={handlePrevClick}
              />
              <h1>
                {months[selectedMonth - 1]} {selectedYear}
              </h1>
              <img
                className="control-arrow next-arrow"
                style={{ transform: "rotate(180deg)" }}
                src="https://assets.mx.com/hub/images/icon-arrow-blue.svg"
                onClick={handleNextClick}
              />
            </div>
            <div className="calendar-body">
              <div id="weekdays">
                {days.map((day, index) => (
                  <div
                    key={"week-" + index}
                    className="weekday"
                    id={"week-" + index}
                  >
                    {day}
                  </div>
                ))}
              </div>
              <div id="month-days">
                {monthInfo &&
                  monthInfo.month_days.map((day, index) => {
                    const todayClass = today === day.full_date ? "today" : "";
                    const otherDayClass = day.other_day ? "other-day" : "";
                    const weekendClass = day.is_weekend ? "weekend" : "";
                    const displayedDate = day.day_date;
                    const dayMonth = day.full_date
                      ? day.full_date.split("-")[1]
                      : day.date.split("-")[1];

                    return (
                      <div
                        key={`month-${index}`}
                        className={`calendar-day ${weekendClass} ${todayClass} ${otherDayClass} `}
                        id={day.full_date}
                      >
                        <div className="day-date">
                          <p className="mobile-month">{months[dayMonth - 1]}</p>
                          <p className="day-date-num">{displayedDate}</p>
                        </div>
                        {day.days_events && (
                          <div>
                            {day.days_events &&
                              day.days_events.map((event, index) => {
                                return (
                                  <div key={index}>
                                    <p className="lunch-vendor">
                                      {event.vendor}
                                    </p>
                                    <p className="lunch-name">{event.Title}</p>
                                    <div className="lunch-description">
                                      {event.notes && parse(event.notes)}
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        )}
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </CalendarStyles>
      </BaseCalendarStyles>
    </Wrap>
  );
}

export default withTheme(CompanyCalendar);
