import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import styled, { withTheme } from "styled-components";
import spacemanLight from "../assets/images/spaceman-dark.png";
import spacemanDark from "../assets/images/spaceman-light.png";
import HeroBG from "../assets/images/founders-circle/header-bg.png";
import Jacket from "../assets/images/founders-circle/jacket.png";
import Shoes from "../assets/images/founders-circle/shoes.png";
import Watch from "../assets/images/founders-circle/watch.png";
import ValOneBG from "../assets/images/founders-circle/watch.png";

const PageWrapper = styled.section`
  background: #000;
  min-height: 100vh;
  margin: 0;
  margin-top: -20px;
  padding-bottom: 100px;
  overflow: hidden;

  h2 {
    font-size: 30px;
  }

  * {
    color: #fff;
    margin: 0;
    padding: 0;
    font-family: Proxima-nova;
  }
`;

const Hero = styled.div`
  background: url("https://assets.mx.com/hub/images/holiday-party/2024/hero-bg.png");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  padding: 100px 0;
  position: relative;

  &:after {
    background: #00000075;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    content: "";
    z-index: 1;
  }

  .wrap {
    position: relative;
    z-index: 2;
    width: 1000px;
    max-width: 100%;
    margin: 0 auto;
    border-radius: 5px;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.7) 40%,
      rgba(0, 0, 0, 0) 100%
    );
    padding: 100px 50px;

    @media only screen and (max-width: 600px) {
      width: 300px;
    }

    a {
      background: #e1c16e;
      color: #000;
      text-decoration: none;
      padding: 8px 40px;
      display: inline-block;
      border-radius: 200px;
      font-weight: 700;
      margin-top: 20px;
      transition: all 0.2s ease;

      &:hover {
        transform: scale(0.98);
      }
    }
    h3 {
      color: #e1c16e;
    }

    h1 {
      font-size: 58px;
      font-weight: 700;
      padding-bottom: 10px;
      margin-top: 10px;

      @media only screen and (max-width: 600px) {
        line-height: 1.1;
        font-size: 45px;
      }
    }
    p {
      font-size: 18px;
      font-weight: 300;

      b {
        font-weight: 700;
      }
    }
  }
`;

const Agenda = styled.div`
  background: #000;
  color: #fff;

  h2 {
    width: 1000px;
    max-width: 100%;
    margin: 0 auto 10px auto;
    padding: 0 20px;

    @media only screen and (max-width: 800px) {
      text-align: center;
      padding: 0;
    }
  }

  .wrap {
    display: flex;
    justify-content: space-between;
    width: 1000px;
    max-width: 100%;
    margin: 0 auto;
    background: linear-gradient(180deg, #2d2d2d 40%, rgba(0, 0, 0, 0) 100%);
    border-radius: 5px;
    padding: 50px;
    box-sizing: border-box;

    @media only screen and (max-width: 800px) {
      width: fit-content;
      flex-direction: column;
    }

    .slot {
      display: flex;
      margin: 20px 0;

      @media only screen and (max-width: 1000px) {
        flex-direction: column;
      }

      .time {
        margin-right: 40px;

        @media only screen and (max-width: 600px) {
          margin: 0 0 5px 0;
        }
      }

      .info {
        h3 {
          font-size: 24px;
          font-weight: 700;
        }

        p {
          color: #e1c16e;
          font-size: 14px;
          font-weight: 700;
          line-height: 1.4;
        }
        a {
          color: #e1c16e;
          text-decoration: none;
        }
      }
    }

    & > img {
      padding: 0 50px 0 0;
      max-width: 100%;

      @media only screen and (max-width: 800px) {
        padding: 50px 0 0 0;
        max-width: 300px;
      }
    }
  }
`;

const FAQs = styled.div`
  width: 1000px;
  max-width: 100%;
  margin: 50px auto 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
  position: relative;

  @media only screen and (max-width: 800px) {
    width: 700px;
    max-width: 100%;
  }

  h2 {
    margin-bottom: 30px;
  }

  .faq {
    margin: 20px 0;
    position: relative;
    z-index: 2;
    padding-bottom: 30px;
    border-bottom: 1px solid #fff;

    img {
      max-width: 100%;
    }
    .directions-link {
      background: #2e2e2e;
      color: #fff;
      text-decoration: none;
      padding: 8px 40px;
      display: inline-block;
      border-radius: 200px;
      font-weight: 700;
      margin-top: 20px;
      transition: all 0.2s ease;

      &:hover {
        transform: scale(0.98);
      }
    }

    h3 {
      font-size: 24px;
      font-weight: 700;
    }

    p {
      /* display: none; */
      font-size: 18px;
      margin: 0 0 10px 0;

      &:first-of-type {
        padding-top: 15px;
      }
    }
  }

  .sparkle {
    position: absolute;
    top: 0;
    right: -700px;
    z-index: 1;

    &.sparkle-lower {
      top: 1000px;
      right: initial;
      left: -900px;
      transform: rotate(180deg);
    }
  }
`;

const questions = [
  {
    question: "When is the company holiday event?",
    answer: (
      <>
        <p>
          <strong>Tuesday, November 19th</strong>
        </p>
        <p>Dinner: 6:00 - 7:30 p.m.</p>
        <p>
          <strong>Luminaria:</strong> 7:30 - 9:00 p.m.
        </p>
      </>
    ),
  },

  {
    question: "Do I need tickets for the holiday event?",
    answer: (
      <>
        <p>
          Yes - team members will need to pick up tickets at MXHQ prior to the
          event in order to collect their swag at the event and to gain entrance
          to the Luminaria light show
        </p>
      </>
    ),
  },

  {
    question: "Where is the company event located?",
    answer: (
      <>
        <p>
          <strong>Dinner:</strong> Show Barn at Thanksgiving Point,{" "}
          <a href="https://maps.app.goo.gl/eBCGJsx8NwYrALYh6" target="_blank">
            2975 Thanksgiving Way, Lehi, UT 84043
          </a>
        </p>
        <p>
          <strong>Luminaria:</strong> Ashton Gardens at Thanksgiving Point,{" "}
          <a href="https://maps.app.goo.gl/NNJH6MfBLgpT8ttK6" target="_blank">
            3900 Garden Dr, Lehi, UT 84043
          </a>
        </p>
        <a
          href="https://maps.app.goo.gl/ynaxA63apuVjbvzdA"
          target="_blank"
          className="directions-link"
        >
          Directions from MX
        </a>
      </>
    ),
  },

  {
    question: "Can I bring a plus one?",
    answer: (
      <>
        <p>
          Yes - make sure to indicate that you are bringing a plus one in your
          RSVP.
        </p>
      </>
    ),
  },

  {
    question: "Where can I park?",
    answer: (
      <>
        <p>Anywhere in the parking areas marked below.</p>
        <br />
        <a
          href="https://assets.mx.com/hub/images/holiday-party/2024/thankgiving-point-map.jpg"
          target="_blank"
        >
          <img src="https://assets.mx.com/hub/images/holiday-party/2024/thankgiving-point-map.jpg" />
        </a>
      </>
    ),
  },

  {
    question: "Will dinner be provided?",
    answer: <p>Yes</p>,
  },

  {
    question: "What should I wear?",
    answer: (
      <>
        <p>
          Business casual. Bring a jacket for Luminaria, which will be outdoors.
        </p>
      </>
    ),
  },

  {
    question: "Will there be alcohol at the holiday event?",
    answer: (
      <>
        <p>We will not be serving alcohol at the dinner.</p>
      </>
    ),
  },

  {
    question: "Will we announce the Founders Circle Winners at the event?",
    answer: <p>Yep</p>,
  },

  {
    question: "What is Luminaria?",
    answer: (
      <>
        <p>
          Luminaria is a holiday lights display. During the holiday season,
          Ashton Gardens is transformed into a winter wonderland with millions
          of twinkling lights guiding you through dozens of themed areas,
          including the iconic luminaire hill – a light and music show on 6,500
          programmable luminaries.
        </p>
        <p>
          This year, team members will get to enjoy the bigger and
          better-than-ever Fire & Ice show, where two worlds collide during a
          flame and light performance set to music.
        </p>
        <p>
          Additionally, MX will host a sponsored tent for team members to warm
          up and enjoy cookies and hot chocolate with their plus ones.
        </p>
        <a
          href="https://maps.app.goo.gl/CtrejVaoBkEgn8cD6"
          target="_blank"
          className="directions-link"
        >
          Directions from MX
        </a>
      </>
    ),
  },
];

function FoundersCircle({ theme }) {
  const [activeAccordion, setActiveAccordion] = useState(null);

  function toggleAccordion(num) {
    setActiveAccordion(num);
  }

  return (
    <>
      <PageWrapper>
        <Hero>
          <div className="wrap">
            <h3>November 19, 2024</h3>
            <h1>2024 Holiday Gathering</h1>
            <p>
              Team members must <b>RSVP by Friday, November 8</b> to collect
              their swag
            </p>
            <a href="https://forms.gle/nRRxxyCoAALCh5NY9">RSVP TODAY</a>
          </div>
        </Hero>
        <Agenda>
          <h2>Agenda</h2>
          <div className="wrap">
            <div className="left">
              <div className="slot">
                <p className="time">6:00 PM - 7:30 PM</p>
                <div className="info">
                  <h3>Dinner</h3>
                  <p>
                    Show Barn at Thanksgiving Point,
                    <br />{" "}
                    <a
                      href="https://maps.app.goo.gl/eBCGJsx8NwYrALYh6"
                      target="_blank"
                    >
                      2975 Thanksgiving Way, Lehi, UT 84043
                    </a>
                  </p>
                </div>
              </div>
              <div className="slot">
                <p className="time">7:30 PM - 9:00 PM</p>
                <div className="info">
                  <h3>Luminaria</h3>
                  <p>
                    Ashton Gardens at Thanksgiving Point
                    <br />{" "}
                    <a
                      href="https://maps.app.goo.gl/NNJH6MfBLgpT8ttK6"
                      target="_blank"
                    >
                      3900 Garden Dr, Lehi, UT 84043
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <img src="https://assets.mx.com/hub/images/holiday-party/2024/venue-images.png" />
          </div>
        </Agenda>
        <FAQs>
          <h2>FAQs</h2>
          {questions.map((question, index) => {
            return (
              <div className="faq">
                <h3>{question.question}</h3>
                <>{question.answer}</>
              </div>
            );
          })}
          <img
            src="https://assets.mx.com/hub/images/holiday-party/2024/sparkle.png"
            className="sparkle"
          />
          <img
            src="https://assets.mx.com/hub/images/holiday-party/2024/sparkle.png"
            className="sparkle sparkle-lower"
          />
        </FAQs>
      </PageWrapper>
    </>
  );
}

export default withTheme(FoundersCircle);
