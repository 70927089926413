import { React, useEffect, useState } from "react";
import styled, { withTheme } from "styled-components";
import utils from "./utils";
import {
  Index,
  Hits,
  useHits,
  Highlight,
  Configure,
  RefinementList,
  Snippet,
  useInstantSearch,
  Pagination,
} from "react-instantsearch-hooks-web";
import Gap from "./gap";
import { set } from "lodash";

const Page = styled.section`
  color: ${({ theme }) => theme.textColor};
  background: ${({ theme }) => theme.backgroundColor};
  padding: 20px 0;

  .confluence-search {
    color: ${({ theme }) => theme.blue};
    text-decoration: none;
    font-weight: 500;
    display: block;
    margin-right: 40px;
    margin-left: auto;
    width: fit-content;
  }

  .filter-heading {
    margin-bottom: 10px;
    font-size: 16px;
  }
`;

const FilterDropdown = styled.div`
  position: relative;
  display: inline;

  button {
    cursor: pointer;
    color: ${({ theme }) => theme.textColor};
  }

  .ais-RefinementList {
    display: none;
    background: ${({ theme }) => theme.hoverBackground};
    border-radius: 15px;
    top: 30px;
    left: 0;
    position: absolute;
    z-index: 20;
    width: 250px;
    box-shadow: 0px 3px 6px #00000029;

    ul {
      cursor: pointer;
      list-style: none;
      padding: 0 20px;
    }

    li {
      color: ${({ theme }) => theme.textColor};
      margin: 7px 0;
    }

    .ais-RefinementList-checkbox {
      margin-right: 10px;
    }
    .ais-RefinementList-labelText {
      text-transform: capitalize;
      margin-right: 10px;
    }
    .ais-RefinementList-count {
      color: ${({ theme }) => theme.blue};
    }
  }

  &:has(.ais-RefinementList-item--selected) button {
    color: ${({ theme }) => theme.blue};
  }

  &.active-dropdown {
    button {
      color: ${({ theme }) => theme.blue};
    }
    .ais-RefinementList {
      display: block;
    }
  }

  button {
    &.filtered {
      background: ${({ theme }) => theme.blue};
      color: #fff;
    }
  }
`;
const FilterButton = styled.button`
  display: inline;
  border: none;
  background: ${({ theme }) => theme.tabsWrapper};
  border-radius: 30px;
  padding: 5px 30px;
  margin: 5px 10px;

  &:first-child {
    margin-left: 0;
  }

  &.filtered {
    color: ${({ theme }) => theme.blue};
  }
`;

const SearchResultsWrap = styled.div`
  padding-top: 50px;

  &.search-results {
    width: 1236px;
    max-width: 100%;
    margin: 0 auto;
    padding: 30px 0 60px 0;

    .section-title {
      font-size: 22px;
      font-weight: 400;
      margin: 0;
      color: ${({ theme }) => theme.textColor};

      @media only screen and (max-width: 900px) {
        padding: 20px;
      }
    }

    .filtered-src {
      color: ${({ theme }) => theme.textColor};
    }

    #source-dropdown {
      margin-bottom: 50px;
      display: block;

      @media only screen and (max-width: 900px) {
        padding: 0 20px;
      }

      .ais-RefinementList {
        @media only screen and (max-width: 900px) {
          margin-left: 20px;
        }
      }

      .active {
        color: ${({ theme }) => theme.blue};
        font-weight: 700;
      }
    }
  }

  .ais-Hits {
    ol {
      padding: 0;
      margin-top: 30px;
      list-style: none;
    }

    .ais-Hits-item {
      margin: 30px 0;
      transition: transform 0.2s ease;

      &:hover {
        transform: scale(0.99);
      }
      .search-item {
        display: flex;
        text-decoration: none;
        color: #000;
        justify-content: flex-start;

        @media only screen and (max-width: 800px) {
          flex-direction: column;
          margin-top: 30px;
          margin: 10px;
        }

        .content-img {
          margin-right: 20px;
          height: 100px;
          width: 100px;
          flex: 0 0 100px;
          overflow: hidden;
          position: relative;
          border-radius: 15px;

          @media only screen and (max-width: 800px) {
            width: 100%;
            margin-bottom: 10px;
          }

          /* &:after {
            content: "";
            position: absolute;
            width: 100px;
            height: 100px;
            background: red;
            z-index: 5;
          } */

          .image-bg {
            object-fit: cover;
            height: 100px;
            width: 100px;
            position: relative;
            z-index: 4;

            @media only screen and (max-width: 800px) {
              width: 100%;
            }
          }

          .content-logo {
            width: 50px;
            top: 10px;
            left: 10px;
            z-index: 10;
            position: absolute;
          }

          &.no-bg-img {
            display: flex;
            background: #e2e2e2;

            .content-logo {
              top: 0;
              left: 0;
              align-self: center;
              position: relative;
              width: 80%;
              margin: 0 auto;
            }
          }
        }

        h3 {
          margin: 0;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: ${({ theme }) => theme.textColor};
          text-transform: capitalize;
        }
        h1 {
          margin: 0;
          margin-top: 5px;
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 1.2;
          color: ${({ theme }) => theme.textColor};
          text-transform: uppercase;

          @media only screen and (max-width: 900px) {
            font-size: 21px;
            line-height: 1;
            margin-bottom: 10px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }
        p {
          margin: 0;
        }
        .link {
          margin-top: 5px;
          color: ${({ theme }) => theme.textColor};
          text-transform: capitalize;
        }
        .description {
          margin-top: 5px;
          color: ${({ theme }) => theme.textColor};
        }
        .post-content {
          margin-top: 5px;
          color: ${({ theme }) => theme.textColor};
        }

        .ais-Highlight-highlighted,
        .ais-Snippet-highlighted {
          padding-bottom: 0;
          display: inline-block;
          line-height: -1;
          border-bottom: 3px solid ${({ theme }) => theme.blue};
          background: none;
          color: ${({ theme }) => theme.textColor};
        }

        .post-content {
          .ais-Highlight-highlighted {
            font-weight: 700;
          }
        }
      }
    }
  }

  .ais-Pagination {
    text-align: center;
    margin-top: 50px;
    ul {
      list-style: none;
      display: flex;
      justify-content: center;
      align-items: center;

      li {
        color: ${({ theme }) => theme.textColor};

        a {
          color: ${({ theme }) => theme.textColor};
          text-decoration: none;
        }
      }
    }
    .ais-Pagination-item {
      font-size: 18px;
    }
    .ais-Pagination-item--selected {
      background: ${({ theme }) => theme.blue};
      border-radius: 50%;
      a {
        color: #fff !important;
      }
    }
    .ais-Pagination-item--firstPage {
      margin-right: 10px;
    }
    .ais-Pagination-item--previousPage {
      margin-right: 10px;
    }
    .ais-Pagination-item--nextPage {
      margin-left: 10px;
    }
    .ais-Pagination-item--lastPage {
      margin-left: 10px;
    }
    .ais-Pagination-item--page {
      width: 30px;
      height: 30px;
      padding: 5px;
      line-height: 1.7;
    }
  }

  .content-source-title {
    margin-bottom: 10px;
    font-size: 27px;
    font-weight: 400;
  }

  /* Glossary */
  .glossary-results {
    margin-top: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid ${({ theme }) => theme.divider};

    @media only screen and (max-width: 900px) {
      padding: 20px;
    }

    .section-title {
      @media only screen and (max-width: 900px) {
        padding: 0;
      }
    }

    h2 {
      font-size: 22px;
      font-weight: 400;
      margin: 0;
      color: ${({ theme }) => theme.textColor};
    }
    .glossary-snippet {
      width: 600px;
      max-width: 100%;
      color: ${({ theme }) => theme.textColor};

      h3 {
        font-size: 28px;
        font-weight: 500;
        margin: 20px 0 10px 0;
        color: ${({ theme }) => theme.textColor};
      }

      p {
        margin: 0;
        font-size: 14px;
        font-weight: 400;
        color: ${({ theme }) => theme.textColor};
      }
    }
  }
`;

function TheSearchResults({ searchValue, isManager }) {

  const mainIndexName = isManager ? "hub_manager_content" : "Main";

  const [indexesWithResults, setIndexesWithResults] = useState([
    "All",
    "Content Library",
    "Requests",
  ]);
  const [filteredSource, setFilteredSource] = useState("All");
  const [showLeavingTheHubMessage, setShowLeavingTheHubMessage] =
    useState(false);
  const [leavingTheHubLink, setLeavingTheHubLink] = useState("");
  const [activeRefinementDropdown, setActiveRefinementDropdown] = useState("");
  const [resultsPerPage, setResultsPerPage] = useState(8);

  useEffect(() => {
    if (filteredSource.toLowerCase() == "all") {
      setResultsPerPage(4);
    } else {
      setResultsPerPage(20);
    }
  }, [filteredSource]);

  useEffect(() => {
    if (window.location.pathname === "/requests/") {
      setFilteredSource("Requests");
    }
  }, []);

  const LeavingTheHubMessage = () => {
    return (
      <div id="leaving-the-hub-message">
        <div>
          <h2>You are now leaving the Hub</h2>
          <p>
            If you are not redirected{" "}
            <a target="_blank" rel="noreferrer" href={leavingTheHubLink}>
              Click here
            </a>
          </p>
        </div>
      </div>
    );
  };
  const toggleRefinementDropdown = (dropdownID) => {
    if (activeRefinementDropdown == dropdownID) {
      setActiveRefinementDropdown("");
    } else {
      setActiveRefinementDropdown(dropdownID);
    }
  };
  const NoResultsBoundary = ({ children, fallback }) => {
    const { results } = useInstantSearch();

    // The `__isArtificial` flag makes sure not to display the No Results message
    // when no hits have been returned yet.
    if (!results.__isArtificial && results.nbHits === 0) {
      // Remove from sources dropdown if there's no results
      if (results.index == mainIndexName) {
        if (indexesWithResults.includes("Content Library")) {
          let arr = indexesWithResults.filter((e) => e !== "Content Library");
          setIndexesWithResults(arr);
        }
      }
      if (results.index == "Hub_request_forms") {
        if (indexesWithResults.includes("Requests")) {
          let arr = indexesWithResults.filter((e) => e !== "Requests");
          setIndexesWithResults(arr);
        }
      }

      return (
        <>
          {fallback}
          <div hidden>{children}</div>
        </>
      );
    }

    return children;
  };

  function GlossarySnippets() {
    const { hits } = useHits();
    const result = hits[0];

    if (
      result &&
      result._rankingInfo.words - 1 === result._rankingInfo.proximityDistance
    ) {
      return (
        <div className="glossary-results">
          <h2 className="section-title">Glossary</h2>
          <div className="glossary-snippet">
            <h3>{result.term}</h3>
            <p>{result.definition}</p>
          </div>
        </div>
      );
    }

    return null;
  }

  return (
    <Page>
      <SearchResultsWrap className="search-results">
        {showLeavingTheHubMessage && <LeavingTheHubMessage />}
        {searchValue && (
          <a
            className="confluence-search"
            href={`https://mxcom.atlassian.net/wiki/search?text=${searchValue}`}
            target="_blank"
          >
            Search in Confluence?
          </a>
        )}
        <Index indexName="Hub Glossary">
          <Configure hitsPerPage={1} getRankingInfo={true} />
          <GlossarySnippets />
        </Index>
        <Gap size={30} />
        <h3 className="filter-heading">Filter by Content Type</h3>
        <FilterDropdown
          id="source-dropdown"
          className={activeRefinementDropdown == "sources" && "active-dropdown"}
        >
          <FilterButton
            className={
              filteredSource && filteredSource.toLowerCase() !== "all"
                ? "filtered"
                : ""
            }
            onClick={() => toggleRefinementDropdown("sources")}
          >
            {filteredSource && filteredSource.toLowerCase() !== "all"
              ? filteredSource
              : "Select Content Type"}
          </FilterButton>
          <div className="ais-RefinementList">
            <ul>
              {indexesWithResults &&
                indexesWithResults.map((source, index) => {
                  return (
                    <li
                      onClick={() => {
                        setActiveRefinementDropdown("");
                        setFilteredSource(source);
                      }}
                      className={filteredSource === source ? "active" : ""}
                      key={index}
                    >
                      {source}
                    </li>
                  );
                })}
            </ul>
          </div>
        </FilterDropdown>

        <Index indexName={mainIndexName}>
          <Configure hitsPerPage={resultsPerPage} getRankingInfo={true} />
          {(filteredSource === "All" ||
            filteredSource === "Content Library") && (
            <NoResultsBoundary fallback={<></>}>
              <>
                <h2 className="section-title">Content Library</h2>
                <FilterDropdown
                  id="tags-dropdown"
                  className={
                    activeRefinementDropdown == "tags" && "active-dropdown"
                  }
                >
                  <FilterButton
                    onClick={() => toggleRefinementDropdown("tags")}
                  >
                    Filter By Tag
                  </FilterButton>
                  <RefinementList attribute="tags" />
                </FilterDropdown>
                <Hits
                  hitComponent={({ hit, sendEvent }) => (
                    <a
                      href={hit.url}
                      // onClick={() => {
                      //   sendEvent("click", hit, hit.url);}
                      // }
                      className="search-item"
                    >
                      <div
                        className={
                          "content-img " +
                          (!hit.image && hit.external ? `no-bg-img` : "")
                        }
                      >
                        <img
                          className="image-bg"
                          src={
                            hit.image
                              ? `${hit.image}?width=400`
                              : `https://assets.mx.com/hub/images/no-img-img.png`
                          }
                          alt={hit.image}
                        />
                      </div>
                      <div>
                        <h3>
                          {hit.external
                            ? hit.content_type
                            : `${hit.content_type}${" | "}`}
                          <span>
                            {hit.date &&
                              !hit.external &&
                              utils.easyToReadDate(
                                hit.date,
                                false,
                                "date-T-time"
                              )}
                          </span>
                        </h3>
                        <h1>{<Highlight attribute="title" hit={hit} />}</h1>
                        {hit.external && hit.content == null && (
                          <p className="link">{hit.url}</p>
                        )}
                        <p className="post-content">
                          {<Snippet attribute="content" hit={hit} />}
                        </p>
                      </div>
                    </a>
                  )}
                />
                <Pagination />
              </>
            </NoResultsBoundary>
          )}
        </Index>

        <Index indexName="Hub_request_forms">
          <Configure hitsPerPage={resultsPerPage} getRankingInfo={true} />
          {(filteredSource === "All" || filteredSource === "Requests") && (
            <NoResultsBoundary fallback={<></>}>
              <>
                <h2 className="section-title">Requests</h2>
                <Hits
                  hitComponent={({ hit, sendEvent }) => (
                    <a
                      href={hit.url}
                      target={"_blank"}
                      rel="noreferrer"
                      // onClick={() => sendEvent("click", hit, "Product Clicked")}
                      className="search-item"
                    >
                      {/* <div
                        className={
                          "content-img " +
                          (!hit.image && hit.external ? `no-bg-img` : "")
                        }
                      >
                        <img
                          className="image-bg"
                          src={
                            "https://directus.hub.mx.com/assets/7e907df2-a4d9-4a62-8818-9b327bdcec3a?width=400"
                          }
                        />
                      </div> */}
                      <div>
                        <h3>
                          Request Portal
                          {/* <span>
                            {hit.date &&
                              utils.easyToReadDate(
                                hit.date,
                                false,
                                "date-T-time"
                              )}
                          </span> */}
                        </h3>
                        <h1>{<Highlight attribute="title" hit={hit} />}</h1>
                        <p className="description">{hit.description}</p>
                        {/* <p className="link">{hit.system}</p> */}
                      </div>
                    </a>
                  )}
                />
                <Pagination />
              </>
            </NoResultsBoundary>
          )}
        </Index>
      </SearchResultsWrap>
    </Page>
  );
}

export default withTheme(TheSearchResults);
