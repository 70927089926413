import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import styled from "styled-components";

const CheckboxWrap = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  label {
    position: relative;
    top: -1px;
    padding-left: 7px;
  }

  * {
    cursor: pointer;
  }
`;

function Loader({ id, text, checked, toggleChecked }) {
  return (
    <CheckboxWrap>
      <div className="field">
        <input
          type="checkbox"
          id={id}
          checked={checked}
          onChange={toggleChecked}
        />
        <label htmlFor={id}>{text}</label>
      </div>
    </CheckboxWrap>
  );
}

export default Loader;
