import styled from "styled-components";

const TaskWrap = styled.a`
  display: flex;
  margin: 20px 20px 20px 0;
  width: fit-content;
  max-width: 100%;
  text-decoration: none;
  display: flex;
  cursor: pointer;

  img {
    width: 107px;
    height: 107px;
    min-width: 107px;
    min-height: 107px;
    border-radius: 10px;
    background: #f5f5f5;
    object-fit: cover;
  }

  transition: transform ease 0.3s;
  &:hover {
    @media only screen and (min-width: 1100px) {
      transform: scale(0.98);
    }
  }
  &:active {
    opacity: 0.6;
  }
`;

const Right = styled.div`
  padding-left: 20px;
  width: 100%;
  max-width: 100%;
  position: relative;
  top: -2px;
  a {
    color: #2f73da;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
    text-decoration: none;
    padding: 5px 7px;
    position: relative;
    left: -5px;
  }
`;

const ResourceType = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.textColor};
  text-transform: capitalize;
`;

const Title = styled.div`
  color: ${({ theme }) => theme.textColor};
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  height: auto;
  padding: 7px 0;
`;

const Description = styled.div`
  color: ${({ theme }) => theme.textColor};
  font-size: 16px;
  font-weight: 400;
  max-width: 100%;

  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
`;

function formatDate(dateString, optional) {
  if (!dateString) return "";

  const [year, month, day] = dateString.split("-");

  const shortYear = year.slice(-2);

  let formattedDate = `${month}/${day}/${shortYear}`;

  let preText = optional ? "Optional" : "Deadline";

  formattedDate = preText + " – " + formattedDate;

  return formattedDate;
}

const trimDescriptionLength = (description) => {
  const maxDescriptionLength = 200;
  if(description && description.length && description.length > maxDescriptionLength) {
    description = description.substring(0, maxDescriptionLength) + '...';
  }
  return description;
}

const ManagerTask = ({ data, key }) => {
  let { type, title, description, link, thumbnail_image } = data;

  let featuredImage =
    "https://directus.hub.mx.com/assets/b4da0650-1312-4f10-8dee-3df4a79c4659";
  if (thumbnail_image) {
    featuredImage = `https://directus.hub.mx.com/assets/${thumbnail_image}?width=600`;
  }

  return (
    <TaskWrap key={key} href={link}>
      <img src={featuredImage} />
      <Right>
        <ResourceType>{type}</ResourceType>
        <Title>{title}</Title>
        <Description>{trimDescriptionLength(description)}</Description>
      </Right>
    </TaskWrap>
  );
};

export default ManagerTask;
