import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import styled from "styled-components";
import api from "./api";
import ReturnToTopButton from "./ReturnToTopButton";
import Dropdown from "./global/Dropdown";
import { useOktaAuth } from "@okta/okta-react";

const OuterWrapper = styled.section`
  width: 100%;
  background: ${({ theme }) => theme.backgroundColor};

  h1 {
    color: ${({ theme }) => theme.textColor};
    font-size: 48px;
    font-weight: 700;
    line-height: 1;
    text-transform: uppercase;
    margin: 0;
  }
`;

const PageWrapper = styled.section`
  display: flex;
  flex-direction: row;
  max-width: 1750px;
  min-height: calc(100vh - 76px);
  max-width: 90%;
  margin: 0 auto;
  margin-left: 138px;
  padding-top: 70px;
  @media only screen and (max-width: 800px) {
    margin-left: 60px;
  }
`;

const LibraryContent = styled.div`
  color: ${({ theme }) => theme.textColor};
  background: ${({ theme }) => theme.backgroundColor};
  position: relative;
  padding-bottom: 50px;
  max-width: 100%;
`;

const FilterGroup = styled.div`
  display: flex;
  > div {
    margin-right: 20px;
  }
`;

const TitleWrap = styled.div`
  letter-spacing: 0px;
  color: ${({ theme }) => theme.textColor};
  margin: 0;
  width: 1072px;
  max-width: 90%;
  padding-top: 30px;
  padding-bottom: 30px;
  background: ${({ theme }) => theme.backgroundColor};
  line-height: 1;
  position: sticky;
  top: 76px;
  z-index: 10;
  border-bottom: 2px ${({ theme }) => theme.divider} solid;
`;

const Name = styled.h2`
  color: ${({ theme }) => theme.textColor};
  letter-spacing: 0px;
  margin: 0;
`;

const Description = styled.p`
  letter-spacing: 0px;
  color: ${({ theme }) => theme.textColor};
  margin: 30px 0;
`;

const Cluster = styled.p`
  margin: 0;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.textColor};
  padding-bottom: 10px;
`;

const LibraryItem = styled.div`
  padding: 40px 0 30px 0;
  position: relative;
  max-width: 950px;
  margin: 0 auto;
  width: 80%;

  @media only screen and (max-width: 1000px) {
    margin: 0;
    padding-left: 0;
  }

  :after {
    width: 100px;
    background: ${({ theme }) => theme.divider};
    position: absolute;
    content: "";
    height: 1px;
    left: 0;
    bottom: 0;
  }

  h3 {
    overflow: hidden;
    color: #0075e0;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.3;
    text-transform: uppercase;
  }

  ul {
    margin-bottom: 40px;
  }

  .course {
    border-radius: 50px;
    background: #f5f5f5;
    padding: 8px 20px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    color: #494949;
    text-decoration: none;
    text-transform: uppercase;
    margin: 12px 25px 0 0;
    transition: opacity ease 0.2s;
    display: inline-block;
    width: fit-content;
    &:hover {
      opacity: 0.7;
    }
    &:active {
      transition: none;
      opacity: 0.5;
    }
  }
`;

const CourseList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

function CompetencyLibrary() {
  const [competencyItems, setCompetencyItems] = useState([]);
  const [letters, setLetters] = useState([]);
  const [clusters, setClusters] = useState([]);
  const [filteredLetter, setFilteredLetter] = useState(false);
  const [filteredCluster, setFilteredCluster] = useState(false);
  const { authState, oktaAuth } = useOktaAuth();
  const [oktaInfo, setOktaInfo] = useState([]);

  useEffect(() => {
    if (authState.isAuthenticated) {
      setOktaInfo(authState.idToken.claims);
    }
  }, [oktaInfo]);

  useEffect(() => {
    async function fetchData() {
      let filterParams = "&limit=-1";
      filterParams += "&sort=title";

      const libraryCollection = await api.collection(
        "Competency_Library",
        oktaAuth,
        filterParams
      );

      const letterSet = ["all"];
      const clusterSet = ["all"];

      libraryCollection.forEach((item) => {
        const firstLetter = item.title.trim().charAt(0).toLowerCase();
        item.firstLetter = firstLetter;
        if (!letterSet.includes(firstLetter)) {
          letterSet.push(firstLetter);
        }
        if (!clusterSet.includes(item.cluster)) {
          clusterSet.push(item.cluster);
        }
      });
      setLetters(letterSet);
      setClusters(clusterSet);
      setCompetencyItems(libraryCollection);
    }
    fetchData();
  }, []);

  useEffect(() => {
    const scrollToElement = () => {
      const hash = window.location.hash;
      const id = hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        const offsetTop = element.getBoundingClientRect().top + window.pageYOffset - 200;
        window.scrollTo({
          top: offsetTop,
          behavior: 'smooth'
        });
      } else {
        setTimeout(scrollToElement, 200);
      }
    };

    scrollToElement();
  }, []);


  let filteredItems = competencyItems;

  // Filter items by first letter
  if (filteredLetter) {
    filteredItems = filteredItems.filter(
      (item) => item.firstLetter === filteredLetter
    );
  }

  // Filter items by cluster
  if (filteredCluster) {
    filteredItems = filteredItems.filter(
      (item) => item.cluster === filteredCluster
    );
  }

  return (
    <OuterWrapper>
      <PageWrapper>
        {competencyItems && (
          <LibraryContent>
            <FilterGroup>
              <Dropdown
                defaultText={"Filter By Letter"}
                selectedOptionPrependText={"Letter: "}
                options={letters}
                onSelect={(letter) => {
                  setFilteredLetter(letter == "all" ? false : letter);
                }}
              />
              <Dropdown
                defaultText={"Filter By Cluster"}
                selectedOptionPrependText={"Cluster: "}
                options={clusters}
                onSelect={(cluster) => {
                  setFilteredCluster(cluster == "all" ? false : cluster);
                }}
              />
            </FilterGroup>
            <TitleWrap className="glossary-title">
              <h1>
                Competency Library{filteredLetter && <> | {filteredLetter}</>}{" "}
              </h1>
            </TitleWrap>
            {filteredItems.length === 0 && (
              <LibraryItem>No results.</LibraryItem>
            )}
            {filteredItems.map((item, i) => {
              return (
                <LibraryItem
                  key={i}
                  id={item.title.toLowerCase().replaceAll(" ", "-")}
                >
                  <Name>{item.title}</Name>
                  <Cluster>
                    <b>Cluster –</b> {item.cluster}
                  </Cluster>
                  <Description>{item.description}</Description>
                  {item.attributes && (
                    <ul>
                      {item.attributes.map((attributes, index) => {
                        return (
                          <li key={index} className="attributes">
                            {attributes.bullet_point}
                          </li>
                        );
                      })}
                    </ul>
                  )}
                  {item.Recommended_Courses && (
                    <>
                      <h3>Recommended Courses</h3>
                      <CourseList>
                        {item.Recommended_Courses.map((course, index) => {
                          return (
                            <a
                              key={index}
                              className="course"
                              href={course.link}
                              target="_blank"
                            >
                              {course.name}
                            </a>
                          );
                        })}
                      </CourseList>
                    </>
                  )}
                </LibraryItem>
              );
            })}
          </LibraryContent>
        )}
      </PageWrapper>
      <ReturnToTopButton
        onReturn={() => {
          setClickedLetter(0);
        }}
      />
    </OuterWrapper>
  );
}

export default CompetencyLibrary;
