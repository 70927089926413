import { useEffect } from "react";
import { withRouter } from "react-router-dom";

/*

This components scrolls the user to the top of every page when navigating

*/

function ScrollToTop({ history }) {
  useEffect(() => {
    const unlisten = history.listen(() => {

      const glossaryException = window.location.pathname.includes("glossary");
      if (!glossaryException) {
        window.scrollTo(0, 0);
      }
    });
    return () => {
      unlisten();
    };
  }, []);

  return null;
}

export default withRouter(ScrollToTop);
