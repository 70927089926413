import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import styled, { withTheme } from "styled-components";
import spacemanLight from "../assets/images/spaceman-dark.png";
import spacemanDark from "../assets/images/spaceman-light.png";

const PageWrapper = styled.section`
  background: ${({ theme }) => theme.backgroundColor};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
`;

const InnerWrapper = styled.div`
  max-width: 600px;
  margin: 0 auto;
  text-align: center;

  h1 {
    color: ${({ theme }) => theme.textColor};
    margin-bottom: 0;
    font-weight: 700;
    font-size: 30px;
    text-transform: uppercase;
  }

  p {
    color: ${({ theme }) => theme.textColor};
    font-weight: 400;
    font-size: 14px;
    text-transform: uppercase;
  }

  img {
    width: 100%;
  }

  a {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-decoration: none;
    border-radius: 25px;
    padding: 10px 40px;
    background: ${({ theme }) => theme.blue};
    color: #fff;
  }
`;

function NotFound({ theme }) {
  const spacemanImg = theme.name == "light" ? spacemanLight : spacemanDark;

  return (
    <>
      <PageWrapper>
        <InnerWrapper>
          <h1>You've Reached Uncharted Space</h1>
          <p>No results found</p>
          <img src={spacemanImg} />
          <a href="/">Take me home</a>
        </InnerWrapper>
      </PageWrapper>
    </>
  );
}

export default withTheme(NotFound);
