import React, { useState, useEffect, useRef } from "react";
import styled, { withTheme } from "styled-components";
import parse from "html-react-parser";
import api from "./api";
import utils from "./utils";
import axios from "axios";
import { useOktaAuth } from "@okta/okta-react";
import OutlineButton from "./OutlineButton";
import { event } from "jquery";

const Wrap = styled.div`
  width: 100%;
  max-width: 1400px;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      padding: 0 10px;
      p {
        margin: 0;
        font-weight: 400;
        position: relative;
        z-index: 1;
      }
    }
  }
`;

const BaseCalendarStyles = styled.div`
  color: ${({ theme }) => theme.textColor};
  position: relative;

  .control-arrow {
    cursor: pointer;
  }

  #weekdays {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    height: 15px;
    margin: 5px 0;
  }
  .calendar.no-weekends {
    #weekdays {
      grid-template-columns: repeat(5, 1fr);
    }
    #month-days {
      grid-template-columns: repeat(5, 1fr);
    }
    .weekend {
      display: none;
    }
  }

  .calendar-header {
    padding: 20px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 20px;
    box-sizing: border-box;
    h1 {
      font-weight: 700;
      font-size: 20px;
      text-align: center;
      text-transform: uppercase;
      text-align: center;
      margin: 0;
      padding: 0;
    }
    #cal-prev-month {
      width: 50px;
      cursor: pointer;
    }
    #cal-next-month {
      width: 50px;
      text-align: right;
      cursor: pointer;
      img {
        transform: scaleX(-1);
      }
    }
  }

  #month-days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    align-items: start;
    grid-template-rows: repeat(5, auto);

    @media only screen and (max-width: 750px) {
      grid-template-rows: 1fr;
    }

    &.weeks-4 {
      grid-template-rows: repeat(4, auto);
    }

    @media only screen and (max-width: 900px) {
      position: relative;
    }

    @media only screen and (max-width: 750px) {
      grid-template-columns: 1fr !important;
    }

    &.activeDay {
      .calendar-day {
        pointer-events: none;
      }
    }
    .calendar-day {
      height: 100%;
      font-weight: 700;
      min-height: 150px;
      overflow: hidden;

      ${(props) => props.popupEvents && "position: relative;"}

      @media only screen and (max-width: 900px) {
        position: unset;
      }
      @media only screen and (max-width: 750px) {
        min-height: 90px !important;
      }

      &.other-day {
        color: ${({ theme }) => theme.textGray};
        font-weight: 500;
      }

      .day-date-num {
        padding: 8px 5px 0 5px;
        margin: 0;
        position: relative;
        top: 2px;
        left: 5px;
      }

      &.weekend {
        color: ${({ theme }) => theme.textGray};
        font-weight: 500;
      }

      .event-dot {
        width: 8px;
        height: 8px;
        background: ${({ theme }) => theme.blue};
        margin: 0 auto;
        margin-top: 10px;
        border-radius: 50%;
        pointer-events: none;
      }
    }
  }
`;

const CalendarStyles = styled.div`
  margin: 0 30px 30px;
  @media only screen and (max-width: 1700px) {
    max-width: 100%;
    border-bottom: 1px solid ${({ theme }) => theme.sectionColor};
  }

  .popup-bg-overlay {
    position: absolute;
    z-index: 2;
    background-color: #00000078;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .calendar-header {
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;

    h1 {
      font-weight: 700;
      font-size: 24px;
      text-align: center;
      text-transform: uppercase;
      text-align: center;
      margin: 0;
      padding: 0;
    }
    #cal-prev-month {
      width: 70px;
      cursor: pointer;
    }
    #cal-next-month {
      width: 70px;
      text-align: right;
      cursor: pointer;
      img {
        transform: scaleX(-1);
      }
    }

    .control-arrow {
      width: 12px;
      cursor: pointer;
    }
  }

  #weekdays {
    display: grid;
    grid-gap: 20px;
    height: 15px;
    margin: 0;
    margin-bottom: 30px;
    text-align: center;

    @media only screen and (max-width: 900px) {
      display: none;
    }

    .weekday {
      font-weight: 700;
      font-size: 12px;
      text-align: center;
      color: #808080;
      text-transform: uppercase;
    }
  }

  #month-days {
    display: grid;
    grid-gap: 0;
    align-items: start;
    position: relative;

    .calendar-day {
      text-align: center;
      box-sizing: border-box;
      padding: 10px 0 0 0;
      display: flex;
      flex-direction: column;
      height: 100%;
      min-height: 160px;
      position: relative;

      @media only screen and (max-width: 750px) {
        min-height: unset;
      }

      &:after {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: calc(100% - 14px);
        margin: 0 auto;
        content: "";
        background: ${({ theme }) => theme.textColor};
        height: 1px;
      }

      @media only screen and (max-width: 750px) {
        &:first-child {
          margin-top: 20px;
          &:after {
            display: none;
          }
        }
      }

      .mobile-month {
        display: none;
        margin: 0;
        padding-right: 10px;
        @media only screen and (max-width: 750px) {
          display: block;
        }
      }

      .event-list {
        height: 100%;
        margin-bottom: 30px;
        @media only screen and (max-width: 750px) {
          margin-top: 10px;
        }
      }
      .event {
        /* border-bottom: 1px solid ${({ theme }) => theme.sectionColor}; */
        margin: 5px 0;
        padding-bottom: 2px;
        transition: color 0.2s ease;
        margin-top: 10px;

        &:not(.office-lunch) {
          cursor: pointer;
        }

        @media only screen and (max-width: 750px) {
          margin-top: 10px;
        }

        &:not(.office-lunch):hover {
          color: ${({ theme }) => theme.blue};
        }

        &:last-child {
          border: none;
        }

        p {
          text-align: left;

          @media only screen and (max-width: 750px) {
            text-align: center;
          }
        }
      }

      .collab-week-day {
        position: absolute;
        background: ${({ theme }) => theme.blue};
        border-radius: 25px;
        width: 100%;
        padding: 3px 0;
        margin-top: -8px;
        margin-bottom: -4px;

        p {
          position: relative;
          z-index: 1;
          color: #fff;
          font-weight: 700;
          font-size: 12px;
          line-height: 1;
          text-transform: uppercase;
          text-align: center;
          margin: 0;
          opacity: 1;

          @media only screen and (max-width: 900px) {
            font-size: 12px;
          }
        }

        &.collab-week-1 {
          border-radius: 25px 0 0 25px;
        }

        &.collab-week-2 {
          border-radius: 0 !important;
          p {
            opacity: 0;
          }
        }
        &.collab-week-3 {
          border-radius: 0 25px 25px 0;
          box-sizing: border-box;
          p {
            opacity: 0;
          }
        }

        @media only screen and (max-width: 750px) {
          position: relative;
          margin: unset;
          background: transparent;
          border: none;

          p {
            opacity: 1 !important;
            color: ${({ theme }) => theme.textColor} !important;
            text-transform: unset;
            font-weight: 400;
            font-size: 16px;
          }
        }
      }

      .day-date {
        text-align: left;
        @media only screen and (max-width: 900px) {
          display: flex;
          align-items: center;
          text-align: left;
        }
      }

      .day-date-num {
        font-weight: 700;
        font-size: 20px;
        line-height: 1;
        text-transform: uppercase;
        color: ${({ theme }) => theme.blue};
        text-align: left;
        display: inline-block;
        padding: 0;
        margin: 0 auto 12px auto;

        @media only screen and (max-width: 750px) {
          width: calc(100% - 55px);
          margin: 0 auto;
        }
      }
      &.other-day .day-date-num {
        color: ${({ theme }) => theme.textGray};
      }
      .event-title {
        @media only screen and (max-width: 900px) {
          font-size: 12px;
        }
      }

      &.today {
        text-align: left;
        .day-date-num {
          background: ${({ theme }) => theme.blue};
          color: #fff;
          height: 20px;
          width: 20px;
          border-radius: 50%;
          padding: 6px;
          line-height: 1;
          margin: -5px 0 6px 0px;
          display: flex;
          justify-content: center;

          @media only screen and (max-width: 750px) {
            margin: 0;
          }
        }
      }
    }
  }

  .office-lunch {
    font-size: 14px;
    line-height: 1;
    margin: 0 0 20px 10px;
    text-align: left;

    h3 {
      margin: 0 0 5px 0;
      font-size: 14px;
      color: ${({ theme }) => theme.blue};
      line-height: 1;
    }

    .lunch-title {
    }

    .lunch-name {
      margin: 0;
    }
    .lunch-vendor {
      margin: 0;
      font-weight: 400;
    }

    .lunch-description {
      margin: 0;
      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 1.4;
        margin: 0;
      }
    }

    .allergen-text {
      margin: 0;
      font-weight: 300;
    }
  }

  .events-and-lunches {
    .event-list {
      flex: 1;
    }
    .office-lunch {
      h3 {
        @media only screen and (max-width: 750px) {
          text-align: center;
        }
      }
      .lunch-title {
        /* display: flex; */
        align-items: center;
        margin: 0 0 5px 0;

        @media only screen and (max-width: 1300px) {
          flex-direction: column;
          align-items: flex-start;
        }
        @media only screen and (max-width: 750px) {
          align-items: center;
        }

        .lunch-vendor {
          margin-right: 10px;
          @media only screen and (max-width: 750px) {
            margin: 0;
          }

          &:after {
            content: ":";
          }
        }
      }

      .lunch-description {
        display: none;
        @media only screen and (max-width: 750px) {
          text-align: center;
        }
      }
      .lunch-details {
        display: flex;
        align-content: center;
        margin: 5px 0;
      }

      .allergen-text {
        margin-right: 10px;
      }
      p {
        width: 200px;
        max-width: 100%;
      }
      .more-info-icon {
        width: 12px;
      }
    }
  }

  .lunch-only {
    h3 {
      display: none;
    }
    .lunch-title {
      text-align: center;
      .lunch-name {
        font-size: 20px;
        margin: 5px 0 10px 0;
      }
      .lunch-vendor {
        font-size: 16px;
        font-weight: 400;
      }
    }
    .lunch-description {
      text-align: center;
      font-size: 16px;
      margin-bottom: 14px;
    }

    .lunch-details {
      text-align: center;

      p {
        &.allergen-text {
          font-weight: 500;
          text-align: center;
          margin: 0 auto;
          width: 100% !important;
        }
      }
    }
  }
`;

const CalFilters = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  left: 100%;
  width: 130px;
  margin-left: 40px;
  margin-top: 20px;

  label {
    margin-left: 6px;
  }

  .field {
    margin-left: 20px;
  }
  .field,
  .field * {
    cursor: pointer;
  }
`;

const CalHeader = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  @media only screen and (max-width: 670px) {
    flex-direction: column;
  }
`;

const EventPopupWrap = styled.div`
  position: absolute;
  top: 30%;
  left: 25%;
  padding: 20px;
  padding-bottom: 30px;
  margin: 0 auto;
  padding: 40px 20px;
  max-height: 200px;
  overflow: auto;
  width: 600px;
  background: ${({ theme }) => theme.hoverBackground};
  border-radius: 15px;
  z-index: 5;
  box-shadow: -2px 4px 4px rgba(165, 165, 165, 0.2);
  text-align: left;
  min-height: 200px;

  &.event-popup-wrap {
    @media only screen and (max-width: 1200px) {
      left: 30px;
    }
    @media only screen and (max-width: 750px) {
      top: 70px;
      left: 0;
      right: 0;
      width: 100%;
      margin: 0 auto;
      box-sizing: border-box;
      max-height: 60%;
      height: auto;
      overflow: auto;
      z-index: 5;
      position: fixed;
    }
  }

  &.active-popup {
    display: block;
  }

  .close-x {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;

    @media only screen and (max-width: 900px) {
      display: block;
    }
  }

  h2 {
    padding: 0;
    margin: 0;
    margin-bottom: 5px;
    text-transform: uppercase;
    font-size: 25px;
    color: ${({ theme }) => theme.textColor};
    text-align: left;
  }

  p {
    color: ${({ theme }) => theme.textColor};
  }

  .popup-event-title {
    text-align: left;
    margin: 0;
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 20px;
    line-height: 1.2;
  }
  .popup-event-time {
    margin: 0;
    text-align: left;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.2;
  }

  .day-date-dot {
    margin: 0 15px 0 10px;
    font-size: 20px;
    line-height: 1;
  }

  .popup-description {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.4s;
    text-align: left;
    margin-top: 20px;
  }
  .outline-button {
    padding: 0;
    margin-top: 20px;

    span {
      font-size: 14px;
    }
  }

  &.office-lunch {
    .popup-event-title {
      /* font-size: 18px;
      font-weight: 600;
      */

      margin: 5px 0 0 0;
      @media only screen and (max-width: 1100px) {
        font-size: 18px;
      }
    }
    .popup-lunch-vendor {
      margin: 0 !important;
    }
    .lunch-vendor {
      margin-top: 10px;
      font-size: 16px;
      font-weight: 400;

      @media only screen and (max-width: 1100px) {
        margin-top: 5px;
        font-size: 14px;
      }
    }
    .popup-description {
      margin: 0;
      font-size: 14px;
      font-weight: 400;
      /* padding: 0 10px; */

      @media only screen and (max-width: 1100px) {
        margin-bottom: 20px;
        font-size: 14px;
      }
    }
  }
`;

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const shortMonths = [
  "Jan",
  "Feb",
  "Mar",
  "April",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

const formatEventData = (theLunchData) => {
  let cleanedDataArr = [];

  // format the data

  // what every event should have
  // Name
  // description
  // Start date
  // End date
  // start Time
  // end time
  // all day
  // collab day (boolean)
  // range (boolean)
  // custom class
  // id

  // This is a custom formatter for the office_lunches collection
  theLunchData.map((lunch) => {
    let theData = [];
    let name = lunch.Title;
    let eventStart = lunch.date;
    let description = lunch.notes;
    let id = lunch.id;

    theData.push({
      name: `${name}`,
      description,
      start_date: eventStart,
      end_date: eventStart,
      start_time: "",
      end_time: "",
      all_day: false,
      collaboration_day: false,
      range: false,
      range_indv_date: null,
      custom_class: "office-lunch", // You can customize this based on your needs
      id,
      vendor: lunch.vendor,
      allergen_info: lunch.allergen_options,
      allergen_info_link: lunch.allergen_info_link,
      office_lunch: true,
    });

    cleanedDataArr.push(...theData);
  });
  return cleanedDataArr;
};

function calculateDaysInRange(dateRange) {
  const [startDateStr, endDateStr] = dateRange.split(" - ");
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);

  // Calculate the difference in milliseconds
  const timeDifference = endDate.getTime() - startDate.getTime();

  // Convert milliseconds to days
  const daysDifference = timeDifference / (1000 * 3600 * 24);

  // Round to the nearest whole number
  const roundedDays = Math.round(daysDifference);

  return roundedDays;
}

const formatGoogleData = (thisMonthsGoogleData) => {
  let cleanedGoogleDataArr = [];
  // format the data
  thisMonthsGoogleData.map((event) => {
    let eventStart = event.start.date;
    if (event.start.dateTime) {
      eventStart = event.start.dateTime.split("T")[0];
    }
    let eventEnd = event.end.date;
    if (event.end.dateTime) {
      eventEnd = event.end.dateTime.split("T")[0];
    }

    eventEnd = eventEnd.toString().padStart(2, "0");
    eventStart = eventStart.toString().padStart(2, "0");

    let name = event.summary;
    let description = event.description;
    let id = event.id;

    // Assign times for events

    // const startDateTimeObject = new Date(startDateTime);
    // const endDateTimeObject = new Date(endDateTime);

    // const start_time = startDateTimeObject.toLocaleTimeString([], {
    //   hour: "2-digit",
    //   minute: "2-digit",
    // });
    // const end_time = endDateTimeObject.toLocaleTimeString([], {
    //   hour: "2-digit",
    //   minute: "2-digit",
    // });

    const dateRange = `${eventStart} - ${eventEnd}`;
    let rangeLength = calculateDaysInRange(dateRange);

    let rangeEvent = rangeLength > 1;

    let theData = [];

    // map through the range and create individual events
    const inputDate = eventStart;
    const parts = inputDate.split("-");
    const formattedDate = `${parts[1]}/${parts[2]}/${parts[0]}`;
    let currentDay = new Date(formattedDate);

    // The Indicator Words below is the keyword used to identify Collab days, Holidays, and 3ps events
    const collabDayIndicatorWord = "Travel-In Week";
    const holidayIndicatorWord = "MX Holiday | ";
    const threePIndicatorWord = "3ps";
    let isTravelDay = name.includes(collabDayIndicatorWord);
    let isHoliday = name.includes(holidayIndicatorWord);
    let isThreeP = name.toLowerCase().includes(threePIndicatorWord);
    let customClass = "";

    if (isTravelDay) {
      customClass = "collab-week-day";
      name = `${months[currentDay.getMonth()]} Collab Week`;
    }
    if (isHoliday) {
      name = name.replace(holidayIndicatorWord, "");
    }
    if (isThreeP) {
      name = name.replace(holidayIndicatorWord, "");
    }

    eventStart = addLeadingZeros(eventStart);
    eventEnd = addLeadingZeros(eventEnd);

    // if it's a multi day range event split event into multiple days, otherwise just push it as is
    if (rangeEvent) {
      for (let index = 0; index < rangeLength; index++) {
        if (isThreeP) {
          continue;
        }

        if (isTravelDay) {
          customClass = "collab-week-day";
          customClass += ` collab-week-${index + 1}`;
        }

        theData.push({
          name: `${name} ${
            rangeLength > 1 && !isTravelDay ? `(Day ${index + 1})` : ""
          }`,
          description,
          start_date: eventStart,
          end_date: eventEnd,
          start_time: "",
          end_time: "",
          all_day: false,
          collaboration_day: isTravelDay,
          range: rangeEvent,
          range_indv_date: rangeEvent
            ? `${currentDay.getFullYear()}-${
                currentDay.getMonth() + 1
              }-${currentDay.getDate()}`
            : null,
          custom_class: customClass, // You can customize this based on your needs
          id,
          event_link: event.htmlLink,
        });
        const nextDay = new Date(currentDay);
        nextDay.setDate(currentDay.getDate() + 1);
        currentDay = nextDay;
      }
    } else if (isThreeP) {
      return;
    } else {
      theData.push({
        name: name,
        description,
        start_date: eventStart,
        end_date: eventEnd,
        start_time: "",
        end_time: "",
        all_day: false,
        collaboration_day: false,
        range: false,
        range_indv_date: null,
        custom_class: customClass, // You can customize this based on your needs
        id,
        event_link: event.htmlLink,
      });
    }

    cleanedGoogleDataArr.push(...theData);
  });
  // push non range event
  return cleanedGoogleDataArr;
};

const baseUrl = "https://api.hub.mx.com";
// const baseUrl = "http://localhost:3080";

const getGoogleEventData = async (selectedMonth, selectedYear, oktaAuth) => {
  try {
    const googleResult = await axios.get(
      `${baseUrl}/api/user/calendar/${selectedMonth
        .toString()
        .padStart(2, "0")}/${selectedYear}`,
      {
        headers: {
          authorization: `Bearer ${oktaAuth.getIdToken()}`,
        },
      }
    );
    let thisMonthsGoogleData = googleResult.data;
    const theFormattedData = await formatGoogleData(thisMonthsGoogleData);
    return theFormattedData;
  } catch (err) {
    console.log("error " + err);
  }
};

const getLunchEventData = async (selectedMonth, selectedYear, oktaAuth) => {
  try {
    let filterParams = "limit=-1";
    filterParams += `&filter[_and][0][_and][0][month(date)][_eq]=${selectedMonth
      .toString()
      .padStart(
        2,
        "0"
      )}&filter[_and][0][_and][1][year(date)][_eq]=${selectedYear}`;

    const theLunchData = await api.collection(
      "office_lunches",
      oktaAuth,
      filterParams
    );

    const theFormattedData = await formatEventData(theLunchData);
    return theFormattedData;
  } catch (err) {
    console.log("error " + err);
  }
};

const addLeadingZeros = (date) => {
  // Parse the original date
  const [year, month, day] = date.split("-");

  // Add leading zeros
  const formattedDate = `${year}-${month.padStart(2, "0")}-${day.padStart(
    2,
    "0"
  )}`;

  return formattedDate;
};

const sortedEventsByDay = (events) => {
  const organizedEvents = {};

  events.forEach((event) => {
    let startDate = event.start_date;

    if (event.range_indv_date) {
      startDate = event.range_indv_date;
    }

    startDate = addLeadingZeros(startDate);
    // Create a new array for the day if it doesn't exist

    if (!organizedEvents[startDate]) {
      organizedEvents[startDate] = [];
    }

    // Push the event to the corresponding day
    organizedEvents[startDate].push({
      name: event.name,
      description: event.description || "",
      start_date: event.start_date,
      end_date: event.end_date,
      start_time: event.start_time || "",
      end_time: event.end_time || "",
      all_day: event.all_day || "",
      collaboration_days: event.collaboration_day || false,
      range: event.range || "",
      custom_class: event.custom_class || "",
      id: event.id,
      event_link: event.event_link,
      office_lunch: event.office_lunch ? event.office_lunch : false,
      vendor: event.vendor ? event.vendor : null,
      allergen_info: event.allergen_info ? event.allergen_info : null,
      allergen_info_link: event.allergen_info_link
        ? event.allergen_info_link
        : null,
    });
  });
  return organizedEvents;
};

const loadUpTheCalendarData = async (selectedMonth, selectedYear, oktaAuth) => {
  // Steps
  // 1. Get the Data
  // 2. Format the data to have the expected key/values
  // 3. Sort into buckets based on day
  // 4. Pass or store the data for the calendar

  const lunchEvents = await getLunchEventData(
    selectedMonth,
    selectedYear,
    oktaAuth
  );
  const googleEvents = await getGoogleEventData(
    selectedMonth,
    selectedYear,
    oktaAuth
  );

  const sortedEvents = await sortedEventsByDay([
    ...googleEvents,
    ...lunchEvents,
  ]);
  return sortedEvents;
};

function getNextMonthsDates(inputDate, n) {
  const result = [];
  const currentDate = new Date(inputDate);
  for (let i = 1; i <= n; i++) {
    const newDate = new Date(currentDate);
    newDate.setDate(currentDate.getDate() + i);
    result.push(newDate.toISOString().split("T")[0]); // Format as YYYY-MM-DD
  }

  return result;
}
function getPrevMonthsDates(inputDate, n) {
  const result = [];
  const currentDate = new Date(inputDate);

  for (let i = 1; i <= n; i++) {
    const newDate = new Date(currentDate);
    newDate.setDate(currentDate.getDate() - i);
    result.push(newDate.toISOString().split("T")[0]); // Format as YYYY-MM-DD
  }

  return result;
}

// build the list of days
const getDaysOfTheMonth = (monthInfo) => {
  let daysInMonthArr = [];
  // create an array of all the days in the month
  // create filler days for prev month days and next month days

  let monthStartsOnWeekend =
    monthInfo.first_day_index === 6 || monthInfo.first_day_index === 0
      ? true
      : false;
  let monthEndsOnWeekend =
    monthInfo.last_day_index === 6 || monthInfo.last_day_index === 0
      ? true
      : false;

  // if no weekends then remove 1 from calculations
  let leadingOffset = monthInfo.no_weekends
    ? monthInfo.first_day_index - 1
    : monthInfo.first_day_index;
  let trailingOffset = monthInfo.no_weekends
    ? 5 - monthInfo.last_day_index
    : 6 - monthInfo.last_day_index;

  for (let index = 1; index <= monthInfo.last_day_date; index++) {
    const currentDay = new Date(
      `${monthInfo.month}/${index}/${monthInfo.year}`
    );

    // const formattedDate = `${currentDay.getFullYear()}-${(
    //   currentDay.getMonth() + 1
    // )
    //   .toString()
    //   .padStart(2, "0")}-${currentDay.getDate().toString().padStart(2, "0")}`;

    const formattedDate = `${monthInfo.year}-${monthInfo.month
      .toString()
      .padStart(2, "0")}-${index.toString().padStart(2, "0")}`;

    let isAWeekend =
      currentDay.getDay() === 6 || currentDay.getDay() === 0 ? true : false;
    // if we're hiding weekends then check that the day isn't a weekend
    if (monthInfo.no_weekends) {
      if (!isAWeekend) {
        daysInMonthArr.push({
          firstDay: index === 1 ? true : false,
          lastDay: index === monthInfo.last_day_date ? true : false,
          day_date: index,
          weekday_index: currentDay.getDay(),
          is_weekend: isAWeekend,
          full_date: `${monthInfo.year}-${monthInfo.month}-${index}`,
          days_events: monthInfo.events[formattedDate],
        });
      }
    } else {
      daysInMonthArr.push({
        firstDay: index === 1 ? true : false,
        lastDay: index === monthInfo.last_day_date ? true : false,
        day_date: index,
        weekday_index: currentDay.getDay(),
        is_weekend: isAWeekend,
        full_date: `${monthInfo.year}-${monthInfo.month}-${index}`,
        days_events: monthInfo.events[formattedDate],
      });
    }
  }

  // last months final days cell Generation

  let leadingDays = [];
  let trailingDays = [];

  let lastDay = parseInt(new Date(monthInfo.prev_month_last_day).getDate());
  let theDate = monthInfo.last_day;

  const prevMonthsEndingDays = getPrevMonthsDates(
    monthInfo.first_day,
    leadingOffset
  );
  const nextMonthLeadingDays = getNextMonthsDates(theDate, trailingOffset);

  if (monthInfo.no_weekends && monthStartsOnWeekend) {
    leadingOffset = 0;
    leadingDays = [];
  } else {
    // starting from nth offset to then count up the days in the loop to get the order right
    let lastDayStartCount = lastDay - (leadingOffset - 1);

    for (let i = 0; i < leadingOffset; i++) {
      const dayToAdd = {
        other_day: true,
        date: prevMonthsEndingDays[leadingOffset - 1 - i],
        day_date: prevMonthsEndingDays[leadingOffset - 1 - i].split("-")[2],
        days_events: monthInfo.events[prevMonthsEndingDays[i - 1]],
      };
      leadingDays.push(dayToAdd);
      lastDayStartCount++;
    }
  }

  // next months beginning Days Cell Generation

  if (monthInfo.no_weekends && monthEndsOnWeekend) {
    trailingOffset = 0;
    trailingDays = [];
  } else {
    trailingDays = [];
    for (let j = 1; j <= trailingOffset; j++) {
      const dayToAdd = {
        other_day: true,
        date: nextMonthLeadingDays[j - 1],
        day_date: parseInt(nextMonthLeadingDays[j - 1].split("-")[2]),
        days_events: monthInfo.events[nextMonthLeadingDays[j - 1]],
      };
      trailingDays.push(dayToAdd);
    }
  }

  daysInMonthArr = [...leadingDays, ...daysInMonthArr, ...trailingDays];
  return daysInMonthArr;
};

// get all the info for the selected Month
const getMonthInfo = async (
  selectedMonth,
  selectedYear,
  noWeekends,
  eventData
) => {
  // account for a user changed year
  let nextMonth = selectedMonth + 1;
  let prevMonth = selectedMonth - 1;
  if (selectedMonth == 12) {
    nextMonth = 1;
  }
  if (selectedMonth == 1) {
    prevMonth = 12;
  }

  const getNextMonth = (month, year) => {
    let nextMonth = (parseInt(month) + 1).toString().padStart(2, "0");
    let nextMonthYear = year;

    if (month === "12") {
      nextMonth = "01";
      nextMonthYear = parseInt(year) + 1;
    }

    return {
      month: month,
      year: year,
    };
  };

  const month = selectedMonth;
  const year = selectedYear;
  const firstDay = new Date(year, month - 1, 1);
  const nextMonthFirstDay = new Date(year, month, 1);
  const lastDay = new Date(year, month, 0);
  const prevMonthLastDay = new Date(year, prevMonth, 0);
  const prevMonthLastIndex = new Date(year, prevMonth, 0).getDay();

  let theInfo = {
    month: month,
    year: year,
    first_day: firstDay,
    first_day_index: firstDay.getDay(),
    last_day: lastDay,
    last_day_date: lastDay.getDate(),
    last_day_index: lastDay.getDay(),
    next_month_first_day: nextMonthFirstDay,
    next_month_first_day_full_date: getNextMonth(month, year)[month],
    next_month_first_day_index: nextMonthFirstDay.getDay(),
    prev_month_last_day: prevMonthLastDay,
    prev_month_last_day_index: prevMonthLastIndex,
    prev_month_last_day_full_date: "",
    no_weekends: noWeekends,
    events: eventData,
  };

  const monthDays = await getDaysOfTheMonth(theInfo);

  theInfo.month_days = monthDays;
  return theInfo;
};

function CompanyCalendar({ theme }) {
  const { oktaAuth } = useOktaAuth();
  const [theEventData, setTheEventData] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [monthInfo, setMonthInfo] = useState(null);
  const [activeDay, setActiveDay] = useState(null);
  const [activeEvent, setActiveEvent] = useState(null);

  const [isLunchChecked, setIsLunchChecked] = useState(true);
  const [lunchPopup, setLunchPopup] = useState(false);
  const [isEventChecked, setIsEventChecked] = useState(true);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has("lunch")) {
      setIsEventChecked(false);
    }
  }, []);

  const toggleLunch = () => {
    setIsLunchChecked(!isLunchChecked);
  };

  const toggleEvent = () => {
    setIsEventChecked(!isEventChecked);
  };

  const [today, setToday] = useState(
    `${new Date().getFullYear()}-${
      new Date().getMonth() + 1
    }-${new Date().getDate()}`
  );

  const noWeekends = true;
  const popupEvents = false;

  let days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  let fullDays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  if (noWeekends) {
    days = ["Mon", "Tue", "Wed", "Thu", "Fri"];

    fullDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
  }

  useEffect(() => {
    (async () => {
      setMonthInfo(
        await getMonthInfo(
          selectedMonth,
          selectedYear,
          noWeekends,
          theEventData ? theEventData : []
        )
      );
    })();
  }, [selectedMonth, selectedYear, theEventData]);

  const handlePrevClick = () => {
    if (selectedMonth == 1) {
      setSelectedMonth(12);
      setSelectedYear((prevState) => prevState - 1);
    } else {
      setSelectedMonth((prevState) => prevState - 1);
    }
  };

  const handleNextClick = () => {
    if (selectedMonth == 12) {
      setSelectedMonth(1);
      setSelectedYear((prevState) => prevState + 1);
    } else {
      setSelectedMonth((prevState) => prevState + 1);
    }
  };
  const handleLunchInfoDisplay = (eventInfo, allergenInfo, allergenLink) => {
    setActiveEvent({ ...eventInfo, allergenInfo, allergenLink });
  };
  const handleEventClick = (info) => {
    setActiveEvent(info);
  };

  const closeEventPopup = (e) => {
    e.preventDefault();

    // setShowMore();
    // setPopupDisplayed(false);
    setActiveEvent(null);
    return;
  };

  useEffect(() => {
    (async () => {
      let allTheEvents = await loadUpTheCalendarData(
        selectedMonth,
        selectedYear,
        oktaAuth
      );
      setTheEventData(allTheEvents);
    })();
  }, [selectedMonth, selectedYear]);

  const closeButton =
    theme.name == "light"
      ? "https://assets.mx.com/hub/images/close-x-dark.svg"
      : "https://assets.mx.com/hub/images/close-x-light.svg";
  const infoIcon =
    theme.name == "light"
      ? "https://assets.mx.com/hub/images/events/icon-info-dark.svg"
      : "https://assets.mx.com/hub/images/events/icon-info-white.svg";

  const EventPopup = () => {
    let eventDate = activeEvent.start_date;
    let dayIndex = new Date(eventDate).getDay();

    if (!activeEvent) {
      return;
    }

    let eventLink = null;

    if (activeEvent.event_link) {
      eventLink =
        "https://calendar.google.com/calendar/u/0/r/eventedit/duplicate/" +
        activeEvent.event_link.split("?eid=")[1].split("&ctz")[0];
    }

    if (activeEvent.office_lunch) {
      return (
        <EventPopupWrap>
          <img
            className="close-x"
            onClick={(e) => {
              closeEventPopup(e);
            }}
            src={closeButton}
            alt="close button"
          />
          <p className="popup-lunch-vendor">{activeEvent.vendor}</p>
          <p className="popup-event-title">{activeEvent.name}</p>
          <div className="lunch-details">
            {activeEvent.allergenInfo !== "link" &&
              activeEvent.allergenInfo && (
                <p className="allergen-text">{activeEvent.allergenInfo}</p>
              )}
            {activeEvent.allergenLink && (
              <a
                className="allergen-text"
                href={activeEvent.allergenLink}
                target="_blank"
              >
                View Allergen Info
              </a>
            )}
          </div>
          <div className={`popup-description`}>
            {/* {parse(activeEvent.description)} */}
          </div>
        </EventPopupWrap>
      );
    }
    return (
      <EventPopupWrap
        className={`event-popup-wrap active-event-popup ${
          activeEvent.office_lunch ? "office-lunch" : ""
        }`}
      >
        <img
          className="close-x"
          onClick={(e) => {
            closeEventPopup(e);
          }}
          src={closeButton}
          alt="close button"
        />
        <p className="popup-event-title">{activeEvent.name}</p>

        {/* Figure out Event times */}
        {/* Show range in the date field for range events */}

        {!activeEvent.office_lunch && (
          <p className="popup-event-time">
            <span className="popup-day">
              {utils.easyToReadDate(activeEvent.start_date)}
              {activeEvent.range
                ? ` - ${utils.easyToReadDate(activeEvent.end_date)}`
                : ""}
            </span>{" "}
            {/* <span className="day-date-dot">&#183;</span> */}
            {/* <span className="popup-time">{getEventTime(activeDay)}</span> */}
          </p>
        )}

        {activeEvent.description && (
          <div className={`popup-description`}>
            {parse(activeEvent.description)}
          </div>
        )}

        {eventLink && (
          <OutlineButton
            rel="noreferrer"
            target="_blank"
            href={eventLink}
            outlineColor="#2F73DA"
          >
            Add to Calendar
          </OutlineButton>
        )}
      </EventPopupWrap>
    );
  };

  return (
    <Wrap>
      <BaseCalendarStyles>
        <CalFilters>
          <div className="field">
            <input
              type="checkbox"
              id="lunch-filter"
              checked={isLunchChecked}
              onChange={toggleLunch}
            />
            <label htmlFor="lunch-filter">Lunches</label>
          </div>
          <div className="field">
            <input
              type="checkbox"
              id="event-filter"
              checked={isEventChecked}
              onChange={toggleEvent}
            />
            <label htmlFor="event-filter">Events</label>
          </div>
        </CalFilters>
        <CalendarStyles>
          <div
            className={`${
              isEventChecked ? "events-and-lunches" : "lunch-only"
            } calendar ${noWeekends ? "no-weekends" : ""} ${
              activeDay || activeEvent ? "active-popup" : ""
            }`}
          >
            {activeEvent && (
              <div className="popup-bg-overlay" onClick={closeEventPopup} />
            )}
            <div className="calendar-header">
              <img
                className="control-arrow prev-arrow"
                src="https://assets.mx.com/hub/images/icon-arrow-blue.svg"
                onClick={handlePrevClick}
              />
              <CalHeader>
                <h1>
                  {months[selectedMonth - 1]} {selectedYear}
                </h1>
              </CalHeader>
              <img
                className="control-arrow next-arrow"
                style={{ transform: "rotate(180deg)" }}
                src="https://assets.mx.com/hub/images/icon-arrow-blue.svg"
                onClick={handleNextClick}
              />
            </div>
            <div className="calendar-body">
              {activeEvent && <EventPopup event={event} />}
              <div id="weekdays">
                {days.map((day, index) => (
                  <div
                    key={"week-" + index}
                    className="weekday"
                    id={"week-" + index}
                  >
                    {day}
                  </div>
                ))}
              </div>
              <div
                id="month-days"
                className={
                  monthInfo && monthInfo.month_days.length % 4 === 0
                    ? "weeks-4"
                    : "weeks-5"
                }
              >
                {monthInfo &&
                  monthInfo.month_days.map((day, index) => {
                    const todayClass = today === day.full_date ? "today" : "";
                    const otherDayClass = day.other_day ? "other-day" : "";
                    const weekendClass = day.is_weekend ? "weekend" : "";
                    const displayedDate = day.day_date;
                    const hasEvents = day.days_events
                      ? "has-events"
                      : "no-events";
                    const whatSideOfTheCalendarIsTheDayOn =
                      day.weekday_index > 3 ? "cal-right" : "cal-left";
                    const isActiveDay = activeDay === day.full_date;
                    const dayMonth = day.full_date
                      ? day.full_date.split("-")[1]
                      : day.date.split("-")[1];

                    let travelDay = false;
                    if (day.days_events) {
                      for (
                        let index = 0;
                        index < day.days_events.length;
                        index++
                      ) {
                        const event = day.days_events[index];

                        if (event.collaboration_days) {
                          travelDay = true;
                        }
                      }
                    }

                    const numberOfEvents = () => {
                      let totalEvents = 0;
                      if (!day.days_events) {
                        return;
                      }

                      const eventsList = day.days_events;

                      totalEvents = eventsList.length;

                      return totalEvents;
                    };

                    const totalEvents = numberOfEvents();

                    return (
                      <div
                        key={`month-${index}`}
                        className={`calendar-day ${weekendClass} ${todayClass} ${otherDayClass} ${hasEvents} ${
                          travelDay ? "collab-week" : ""
                        } ${whatSideOfTheCalendarIsTheDayOn} ${
                          isActiveDay ? "active-day" : ""
                        }`}
                        id={day.full_date}
                        onClick={(e) =>
                          day.days_events
                            ? setActiveDay(e.target.id)
                            : setActiveDay(null)
                        }
                      >
                        {lunchPopup && (
                          <div className="lunch-details-popup">
                            {lunchPopup.description}
                          </div>
                        )}
                        <div className="day-date">
                          <p className="mobile-month">{months[dayMonth - 1]}</p>
                          <p className="day-date-num">{displayedDate}</p>
                        </div>
                        {popupEvents && day.days_events && (
                          <div className="event-dot"></div>
                        )}
                        {day.days_events && (
                          <ul
                            className={`event-list ${
                              totalEvents === 0 ? "no-events" : ""
                            } ${popupEvents ? "popup-events" : ""}`}
                          >
                            {/* Popup events event list popup */}
                            {popupEvents && (
                              <>
                                <img
                                  className="close-x"
                                  onClick={(e) => {
                                    setActiveDay(null);
                                  }}
                                  src={closeButton}
                                  alt="close button"
                                />
                                <h3>{utils.easyToReadDate(day.full_date)}</h3>
                              </>
                            )}
                            {/* Events */}
                            {day.days_events &&
                              day.days_events
                                .filter((event) => event.office_lunch !== true)
                                .map((event, index) => {
                                  if (!isEventChecked && !event.office_lunch)
                                    return;

                                  const sharedDayFormat =
                                    day.days_events.length !== 1;

                                  return (
                                    <li
                                      key={index}
                                      className={`event test ${
                                        event.custom_class
                                          ? event.custom_class
                                          : ""
                                      }`}
                                    >
                                      <p
                                        onClick={() => {
                                          handleEventClick(event);
                                        }}
                                      >
                                        {event.name}
                                      </p>
                                    </li>
                                  );
                                })}
                          </ul>
                        )}
                        {/* Lunches */}
                        {day.days_events &&
                          isLunchChecked &&
                          day.days_events
                            .filter((event) => event.office_lunch === true)
                            .map((event, index) => {
                              const sharedDayFormat =
                                day.days_events.length !== 1;

                              let allergenInfo = event.allergen_info;
                              let allergenLink = false;

                              if (allergenInfo === "provided by caterer") {
                                allergenInfo =
                                  "Allergen Info Provided by Caterer ";
                              }
                              if (allergenInfo === "link") {
                                allergenLink = event.allergen_info_link;
                              }

                              return (
                                <div
                                  key={index}
                                  className={`office-lunch ${
                                    event.custom_class ? event.custom_class : ""
                                  }`}
                                >
                                  <div
                                    key={index}
                                    className={
                                      "lunch-item " +
                                      (sharedDayFormat ? "boxed-lunch" : "")
                                    }
                                  >
                                    <h3>
                                      Lunch{" "}
                                      <span>
                                        <img
                                          onClick={() =>
                                            handleLunchInfoDisplay(
                                              event,
                                              allergenInfo,
                                              allergenLink
                                            )
                                          }
                                          className="more-info-icon"
                                          src={infoIcon}
                                        />
                                      </span>
                                    </h3>
                                    <div className="lunch-title">
                                      {/* {event.vendor && (
                                        <p className="lunch-vendor">
                                          {event.vendor}
                                        </p>
                                      )} */}
                                      <p className="lunch-name">{event.name}</p>
                                    </div>
                                    <div className="lunch-description">
                                      {event.description &&
                                        parse(event.description)}
                                    </div>
                                    <div className="lunch-details">
                                      {allergenInfo !== "link" &&
                                        allergenInfo && (
                                          <p className="allergen-text">
                                            {allergenInfo}
                                          </p>
                                        )}
                                      {allergenLink && (
                                        <a
                                          className="allergen-text"
                                          href={allergenLink}
                                          target="_blank"
                                        >
                                          View Allergen Info
                                        </a>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </CalendarStyles>
      </BaseCalendarStyles>
    </Wrap>
  );
}

export default withTheme(CompanyCalendar);
