import styled from "styled-components";

const TaskWrap = styled.a`
  display: flex;
  margin: 20px 20px 20px 0;
  max-width: 100%;
  text-decoration: none;
  img {
    width: 107px;
    height: 107px;
    min-width: 107px;
    min-height: 107px;
    border-radius: 10px;
    background: #f5f5f5;
    object-fit: cover;
  }
  transition: transform ease 0.3s;
  &:hover {
    @media only screen and (min-width: 1100px) {
      transform: scale(0.98);
    }
  }
  &:active {
    opacity: 0.6;
  }
`;

const Right = styled.div`
  padding-left: 20px;
  width: 500px;
  max-width: 100%;
  position: relative;
  top: -2px;
`;

const Deadline = styled.div`
  text-transform: uppercase;
  color: #f00;
  &.optional {
    color: ${({ theme }) => theme.textColor};
  }
  font-size: 16px;
  font-weight: 400;
`;

const Title = styled.div`
  color: ${({ theme }) => theme.textColor};
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  text-transform: capitalize;
  height: 60px;
  display: flex;
  align-items: center;
  @media only screen and (max-width: 700px) {
    height: auto;
    padding: 15px 0;
  }
`;

function formatDate(dateString, optional) {
  if (!dateString) return "";

  const [year, month, day] = dateString.split("-");

  const shortYear = year.slice(-2);

  let formattedDate = `${month}/${day}/${shortYear}`;

  let preText = optional ? "Optional" : "Deadline";

  formattedDate = preText + " – " + formattedDate;

  return formattedDate;
}

const BannerWrap = styled.a`
  width: 100%;
  padding: 20px 30px;
  max-width: 100%;
  background: #2f73da;
  border-radius: 15px;
  margin: 10px 0;

  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 700;
  color: white;

  cursor: pointer;

  text-decoration: none;
  transition: transform ease 0.3s;
  &:hover {
    @media only screen and (min-width: 1100px) {
      transform: scale(0.98);
    }
  }
  &:active {
    opacity: 0.6;
  }
`;

const LinkText = styled.div`
  color: #2f73da;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
  padding: 5px 7px;
  position: relative;
  left: -5px;
`;

const ManagerTask = ({ data, key }) => {
  const {
    deadline,
    deadline_optional,
    title,
    link,
    link_text,
    thumbnail_image,
    display_as_banner,
  } = data;

  let dealineClass = deadline_optional ? "optional" : "";

  let featuredImage =
    "https://directus.hub.mx.com/assets/b4da0650-1312-4f10-8dee-3df4a79c4659";
  if (thumbnail_image) {
    featuredImage = `https://directus.hub.mx.com/assets/${thumbnail_image}?width=600`;
  }

  if (display_as_banner) {
    return (
      <BannerWrap href={link} target="_blank">
        {title}
      </BannerWrap>
    );
  }

  return (
    <TaskWrap key={key} href={link} target="_blank">
      <img src={featuredImage} />
      <Right>
        <Deadline className={dealineClass}>
          {formatDate(deadline, deadline_optional)}
        </Deadline>
        <Title>{title}</Title>
        <LinkText>{link_text}</LinkText>
      </Right>
    </TaskWrap>
  );
};

export default ManagerTask;
