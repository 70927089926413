import React, { useEffect, useRef } from "react";
import styled, { withTheme } from "styled-components";
import DarkArrow from "../assets/images/icons/dark-arrow.svg";
import LightArrow from "../assets/images/icons/light-arrow.svg";

const Wrap = styled.div`
  position: fixed;
  bottom: 0;
  right: 111px;
  border-radius: 50%;
  width: 84px;
  height 84px;
  background: ${({ theme }) => theme.sectionColor};
  display:flex;
  justify-content:center;
  align-items:center;
  cursor: pointer;
  transition: all 0.5s ease;
  transform: translateY(100px);

  &:hover {
    opacity:0.8;
  }
  &:active {
    transition: none;
    opacity:0.4;
  }

  &.in-view {
    transform: translateY(-38px);
  }

`;

const Arrow = styled.img`
  width: 40px;
`;

const ReturnToTopButton = ({ theme, onReturn }) => {
  const buttonRef = useRef();

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    onReturn();
  };

  const checkForDisplay = () => {
    if (!buttonRef.current) return;

    const show = window.scrollY > 2000;
    if (show && !buttonRef.current.classList.contains("in-view")) {
      buttonRef.current.classList.add("in-view");
    } else if (!show && buttonRef.current.classList.contains("in-view")) {
      buttonRef.current.classList.remove("in-view");
    }
  };

  checkForDisplay();

  useEffect(() => {
    const onScroll = (e) => {
      checkForDisplay();
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <Wrap onClick={handleClick} ref={buttonRef}>
      <Arrow src={theme.name == "light" ? DarkArrow : LightArrow} />
    </Wrap>
  );
};

export default withTheme(ReturnToTopButton);
