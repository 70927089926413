// TO DO
// Active Day Popup
// Event Popup
// Lunch Popup
// Don't include lunch in the event dot logic
// Show lunches on other/trailing days too
// Check Mobile

import React, { useState, useEffect, useRef } from "react";
import styled, { withTheme } from "styled-components";
import parse from "html-react-parser";
import api from "./api";
import utils from "./utils";
import axios from "axios";
import { useOktaAuth } from "@okta/okta-react";
import OutlineButton from "./OutlineButton";

const Wrap = styled.div`
  width: 100%;
  max-width: 1400px;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      p {
        margin: 0;
        font-weight: 400;
        position: relative;
        z-index: 1;
      }
    }
  }
`;

const BaseCalendarStyles = styled.div`
  color: ${({ theme }) => theme.textColor};
  position: relative;

  .control-arrow {
    cursor: pointer;
  }

  #weekdays {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    height: 15px;
    margin: 5px 0;
  }
  .calendar.no-weekends {
    #weekdays {
      grid-template-columns: repeat(5, 1fr);
    }
    #month-days {
      grid-template-columns: repeat(5, 1fr);
    }
    .weekend {
      display: none;
    }
  }

  .calendar-header {
    padding: 20px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 20px;
    box-sizing: border-box;
    h1 {
      font-weight: 700;
      font-size: 20px;
      text-align: center;
      text-transform: uppercase;
      text-align: center;
      margin: 0;
      padding: 0;
    }
    #cal-prev-month {
      width: 50px;
      cursor: pointer;
    }
    #cal-next-month {
      width: 50px;
      text-align: right;
      cursor: pointer;
      img {
        transform: scaleX(-1);
      }
    }
  }

  #month-days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    align-items: start;
    grid-template-rows: repeat(5, 60px);

    @media only screen and (max-width: 750px) {
      grid-template-rows: 1fr;
    }

    &.weeks-4 {
      grid-template-rows: repeat(4, 60px);
    }

    @media only screen and (max-width: 900px) {
      position: relative;
    }

    @media only screen and (max-width: 750px) {
      grid-template-columns: 1fr !important;
    }

    .calendar-day {
      height: 100%;
      font-weight: 700;
      min-height: 50px;

      ${(props) => props.popupEvents && "position: relative;"}

      @media only screen and (max-width: 900px) {
        position: unset;
      }
      @media only screen and (max-width: 750px) {
        /* min-height: 90px !important; */
      }

      &.has-events {
        cursor: pointer;

        &:hover {
          background: ${({ theme }) => theme.tabsWrapper};
          border-radius: 5px;
        }
      }

      &.other-day {
        color: ${({ theme }) => theme.textGray};
        font-weight: 500;
      }

      .day-date-num {
        padding: 8px 5px 0 5px;
        margin: 0;
      }

      &.weekend {
        color: ${({ theme }) => theme.textGray};
        font-weight: 500;
      }

      .event-dot {
        display: none;
        position: absolute;
        right: 30%;
        top: 0;
        width: 6px;
        height: 6px;
        background: ${({ theme }) => theme.blue};
        margin: 0 auto;
        margin-top: 10px;
        border-radius: 50%;
        pointer-events: none;

        @media only screen and (max-width: 750px) {
          right: 3px;
          top: 6px;
        }
      }

      &.has-events {
        .event-dot {
          display: block;
        }
        &.not-counted-events .event-dot {
          display: none;
        }
      }

      .lunch-icon {
        pointer-events: none;
        display: none;
        width: 11px;
        margin: 5px auto 0 auto;
        transition: transform 0.2s ease;

        @media only screen and (max-width: 750px) {
          position: absolute;
          right: 20px;
        }

        &:hover {
          @media only screen and (min-width: 1100px) {
            transform: scale(1.08);
          }
        }
      }

      &.lunch-day {
        .lunch-icon {
          display: block;
        }
      }
    }

    .today {
      background: linear-gradient(
        193deg,
        rgb(206 199 219 / 23%) 0%,
        rgb(180 206 234 / 36%) 100%
      );
      box-shadow: 10px 10px 19px -14px rgb(113 135 163 / 30%);
      border-radius: 10px;
      padding: 10px !important;
    }
  }
`;

const CalendarStyles = styled.div`
  margin: 30px;
  @media only screen and (max-width: 1700px) {
    max-width: 100%;
  }

  .popup-bg-overlay {
    position: absolute;
    z-index: 2;
    background-color: #00000078;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .calendar-header {
    padding: 0 0 20px 0;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;

    h1 {
      font-weight: 700;
      font-size: 18px;
    }
    #cal-prev-month {
      width: 50px;
      cursor: pointer;
    }
    #cal-next-month {
      width: 50px;
      text-align: right;
      cursor: pointer;
      img {
        transform: scaleX(-1);
      }
    }

    .control-arrow {
      width: 12px;
      cursor: pointer;
    }
  }

  #weekdays {
    display: grid;
    grid-gap: 20px;
    height: 15px;
    margin: 0;
    margin-bottom: 30px;
    text-align: center;

    @media only screen and (max-width: 900px) {
      display: none;
    }

    .weekday {
      font-weight: 700;
      font-size: 12px;
      text-align: center;
      color: #808080;
      text-transform: uppercase;
    }
  }

  #month-days {
    display: grid;
    grid-row-gap: 15px;
    grid-column-gap: 10px;
    align-items: start;
    position: relative;

    .calendar-day {
      text-align: center;
      box-sizing: border-box;
      padding: 10px 0;
      display: flex;
      flex-direction: column;
      height: initial;
      position: relative;
      padding-bottom: 20px;

      @media only screen and (max-width: 750px) {
        min-height: unset;
      }

      @media only screen and (max-width: 750px) {
        &:after {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          width: 100%;
          margin: 0 auto;
          content: "";
          background: ${({ theme }) => theme.textColor};
          height: 2px;
        }
        &:first-child {
          margin-top: 20px;
          &:after {
            display: none;
          }
        }
      }

      .has-events {
      }

      .mobile-month {
        display: none;
        margin: 0;
        padding-right: 10px;
        @media only screen and (max-width: 750px) {
          display: block;
        }
      }

      .event-list {
        display: none;
        @media only screen and (max-width: 750px) {
          display: block;
          margin-top: 10px;
        }
      }
      .event {
        border-bottom: 1px solid ${({ theme }) => theme.sectionColor};
        margin: 0;
        margin-bottom: 5px;
        padding-bottom: 2px;
        cursor: pointer !important;
        transition: color 0.2s ease;
        margin-top: 10px;
        cursor: pointer;

        @media only screen and (max-width: 750px) {
          margin-top: 10px;
        }

        &:hover {
          color: ${({ theme }) => theme.blue};
        }

        &:last-child {
          border: none;
        }

        p {
          text-align: left;

          @media only screen and (max-width: 750px) {
            text-align: center;
          }
        }
      }

      .day-date {
        pointer-events: none;
        text-align: center;
        @media only screen and (max-width: 900px) {
          display: flex;
          align-items: center;
        }
      }

      .day-date-num {
        font-weight: 700;
        font-size: 14px;
        line-height: 1;
        text-transform: uppercase;
        color: ${({ theme }) => theme.textColor};
        text-align: center;
        display: inline-block;
        padding: 0;
        width: 80%;
        margin: 0 auto 12px auto;

        @media only screen and (max-width: 750px) {
          text-align: left;
          margin: 0;
        }
      }
      &.other-day .day-date-num {
        color: ${({ theme }) => theme.textGray};
      }
      .event-title {
        @media only screen and (max-width: 900px) {
          font-size: 12px;
        }
      }

      &.collab-week {
        .day-date-num {
          background: ${({ theme }) => theme.blue};
          color: #fff;
          height: 15px;
          width: 15px;
          border-radius: 50%;
          padding: 6px;
          line-height: 1.1;
          margin: 0 auto;
          display: flex;
          justify-content: center;

          @media only screen and (max-width: 750px) {
            margin: 0;
          }
        }
      }
    }
  }
`;

const EventPopupWrap = styled.div`
  position: absolute;
  top: 30%;
  left: 25%;
  padding: 20px;
  padding-bottom: 30px;
  margin: 0 auto;
  padding: 40px 20px;
  max-height: 200px;
  overflow: auto;
  min-width: 300px;
  width: fit-content;
  background: ${({ theme }) => theme.hoverBackground};
  border-radius: 15px;
  z-index: 3;
  box-shadow: -2px 4px 4px rgba(165, 165, 165, 0.2);
  text-align: left;
  min-height: 200px;

  @media only screen and (max-width: 750px) {
    &.event-popup-wrap {
      top: -30px;
      left: 0;
      right: 0;
      width: 100%;
      margin: 0 auto;
      box-sizing: border-box;
      max-height: 60%;
      height: auto;
      overflow: auto;
      z-index: 50;
    }
  }

  @media only screen and (max-width: 750px) {
    &.event-popup-wrap {
      top: 30%;
      position: fixed;
    }
  }

  &.event-popup-wrap {
    z-index: 5;
  }

  &.active-popup {
    display: block;
  }

  &.active-popup {
  }

  .event-list {
    li {
      cursor: pointer;
      &:hover p {
        color: ${({ theme }) => theme.blue};
      }
    }
  }

  .close-x {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;

    @media only screen and (max-width: 900px) {
      display: block;
    }
  }

  h2 {
    padding: 0;
    margin: 0;
    margin-bottom: 5px;
    text-transform: uppercase;
    font-size: 25px;
    color: ${({ theme }) => theme.textColor};
    text-align: left;
  }

  p {
    color: ${({ theme }) => theme.textColor};
  }

  .popup-event-title {
    text-align: left;
    margin: 0;
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 20px;
    line-height: 1.2;
  }
  .popup-event-time {
    margin: 0;
    text-align: left;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.2;
  }

  .day-date-dot {
    margin: 0 15px 0 10px;
    font-size: 20px;
    line-height: 1;
  }

  .popup-description {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.4;
    text-align: left;
    margin-top: 20px;
  }
  .outline-button {
    padding: 0;
    margin-top: 20px;

    span {
      font-size: 14px;
    }
  }

  &.office-lunch {
    p {
      margin: 0;
    }
    .lunch-name {
      font-size: 21px;
      font-weight: 600;
      margin: 0;

      @media only screen and (max-width: 1100px) {
        font-size: 18px;
      }
    }
    .lunch-vendor {
      font-size: 16px;
      font-weight: 400;

      @media only screen and (max-width: 1100px) {
        margin-top: 5px;
        font-size: 14px;
      }
    }
    .lunch-description {
      margin-top: 10px;
      font-size: 16px;
      font-weight: 400;

      @media only screen and (max-width: 1100px) {
        margin-bottom: 20px;
        font-size: 14px;
      }
    }
  }
`;

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const shortMonths = [
  "Jan",
  "Feb",
  "Mar",
  "April",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

const formatEventData = (theLunchData) => {
  let cleanedDataArr = [];

  // format the data

  // what every event should have
  // Name
  // description
  // Start date
  // End date
  // start Time
  // end time
  // all day
  // collab day (boolean)
  // range (boolean)
  // custom class
  // id

  // This is a custom formatter for the office_lunches collection
  theLunchData.map((lunch) => {
    let theData = [];
    let name = lunch.Title;
    let eventStart = lunch.date;
    let description = lunch.notes;
    let id = lunch.id;

    theData.push({
      name: `${name}`,
      description,
      start_date: eventStart,
      end_date: eventStart,
      start_time: "",
      end_time: "",
      all_day: false,
      collaboration_day: false,
      range: false,
      range_indv_date: null,
      custom_class: "office-lunch", // You can customize this based on your needs
      id,
      vendor: lunch.vendor,
      office_lunch: true,
    });

    cleanedDataArr.push(...theData);
  });
  return cleanedDataArr;
};

function calculateDaysInRange(dateRange) {
  const [startDateStr, endDateStr] = dateRange.split(" - ");
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);

  // Calculate the difference in milliseconds
  const timeDifference = endDate.getTime() - startDate.getTime();

  // Convert milliseconds to days
  const daysDifference = timeDifference / (1000 * 3600 * 24);

  // Round to the nearest whole number
  const roundedDays = Math.round(daysDifference);

  return roundedDays;
}

const formatGoogleData = (thisMonthsGoogleData) => {
  let cleanedGoogleDataArr = [];
  // format the data
  thisMonthsGoogleData.map((event) => {
    let eventStart = event.start.date;
    if (event.start.dateTime) {
      eventStart = event.start.dateTime.split("T")[0];
    }
    let eventEnd = event.end.date;
    if (event.end.dateTime) {
      eventEnd = event.end.dateTime.split("T")[0];
    }

    eventEnd = eventEnd.toString().padStart(2, "0");
    eventStart = eventStart.toString().padStart(2, "0");

    let name = event.summary;
    let description = event.description;
    let id = event.id;

    // Assign times for events

    // const startDateTimeObject = new Date(startDateTime);
    // const endDateTimeObject = new Date(endDateTime);

    // const start_time = startDateTimeObject.toLocaleTimeString([], {
    //   hour: "2-digit",
    //   minute: "2-digit",
    // });
    // const end_time = endDateTimeObject.toLocaleTimeString([], {
    //   hour: "2-digit",
    //   minute: "2-digit",
    // });

    const dateRange = `${eventStart} - ${eventEnd}`;
    let rangeLength = calculateDaysInRange(dateRange);

    let rangeEvent = rangeLength > 1;

    let theData = [];

    // map through the range and create individual events
    const inputDate = eventStart;
    const parts = inputDate.split("-");
    const formattedDate = `${parts[1]}/${parts[2]}/${parts[0]}`;
    let currentDay = new Date(formattedDate);

    // The Indicator Words below is the keyword used to identify Collab days, Holidays, and 3ps events
    const collabDayIndicatorWord = "Travel-In Week";
    const holidayIndicatorWord = "MX Holiday | ";
    const threePIndicatorWord = "3ps";
    let isTravelDay = name.includes(collabDayIndicatorWord);
    let isHoliday = name.includes(holidayIndicatorWord);
    let isThreeP = name.toLowerCase().includes(threePIndicatorWord);
    let customClass = "";

    if (isTravelDay) {
      customClass = "collab-week-day";
      name = `${months[currentDay.getMonth()]} Collab Week`;
    }
    if (isHoliday) {
      name = name.replace(holidayIndicatorWord, "");
    }
    if (isThreeP) {
      name = name.replace(holidayIndicatorWord, "");
    }

    eventStart = addLeadingZeros(eventStart);
    eventEnd = addLeadingZeros(eventEnd);

    // if it's a multi day range event split event into multiple days, otherwise just push it as is
    if (rangeEvent) {
      for (let index = 0; index < rangeLength; index++) {
        if (isThreeP) {
          continue;
        }

        if (isTravelDay) {
          customClass = "collab-week-day";
          customClass += ` collab-week-${index + 1}`;
        }

        theData.push({
          name: `${name} ${
            rangeLength > 1 && !isTravelDay ? `(Day ${index + 1})` : ""
          }`,
          description,
          start_date: eventStart,
          end_date: eventEnd,
          start_time: "",
          end_time: "",
          all_day: false,
          collaboration_day: isTravelDay,
          range: rangeEvent,
          range_indv_date: rangeEvent
            ? `${currentDay.getFullYear()}-${
                currentDay.getMonth() + 1
              }-${currentDay.getDate()}`
            : null,
          custom_class: customClass, // You can customize this based on your needs
          id,
          event_link: event.htmlLink,
        });
        const nextDay = new Date(currentDay);
        nextDay.setDate(currentDay.getDate() + 1);
        currentDay = nextDay;
      }
    } else if (isThreeP) {
      return;
    } else {
      theData.push({
        name: name,
        description,
        start_date: eventStart,
        end_date: eventEnd,
        start_time: "",
        end_time: "",
        all_day: false,
        collaboration_day: false,
        range: false,
        range_indv_date: null,
        custom_class: customClass, // You can customize this based on your needs
        id,
        event_link: event.htmlLink,
      });
    }

    cleanedGoogleDataArr.push(...theData);
  });
  // push non range event
  return cleanedGoogleDataArr;
};

const baseUrl = "https://api.hub.mx.com";
// const baseUrl = "http://localhost:3080";

const getGoogleEventData = async (selectedMonth, selectedYear, oktaAuth) => {
  try {
    const googleResult = await axios.get(
      `${baseUrl}/api/user/calendar/${selectedMonth
        .toString()
        .padStart(2, "0")}/${selectedYear}`,
      {
        headers: {
          authorization: `Bearer ${oktaAuth.getIdToken()}`,
        },
      }
    );
    let thisMonthsGoogleData = googleResult.data;
    const theFormattedData = await formatGoogleData(thisMonthsGoogleData);
    return theFormattedData;
  } catch (err) {
    console.log("error " + err);
  }
};

const getLunchEventData = async (selectedMonth, selectedYear, oktaAuth) => {
  try {
    let filterParams = "limit=-1";
    filterParams += `&filter[_and][0][_and][0][month(date)][_eq]=${selectedMonth
      .toString()
      .padStart(
        2,
        "0"
      )}&filter[_and][0][_and][1][year(date)][_eq]=${selectedYear}`;

    const theLunchData = await api.collection(
      "office_lunches",
      oktaAuth,
      filterParams
    );

    const theFormattedData = await formatEventData(theLunchData);
    return theFormattedData;
  } catch (err) {
    console.log("error " + err);
  }
};

const addLeadingZeros = (date) => {
  // Parse the original date
  const [year, month, day] = date.split("-");

  // Add leading zeros
  const formattedDate = `${year}-${month.padStart(2, "0")}-${day.padStart(
    2,
    "0"
  )}`;

  return formattedDate;
};

const sortedEventsByDay = (events) => {
  const organizedEvents = {};

  events.forEach((event) => {
    let startDate = event.start_date;

    if (event.range_indv_date) {
      startDate = event.range_indv_date;
    }

    startDate = addLeadingZeros(startDate);
    // Create a new array for the day if it doesn't exist

    if (!organizedEvents[startDate]) {
      organizedEvents[startDate] = [];
    }

    // Push the event to the corresponding day
    organizedEvents[startDate].push({
      name: event.name,
      description: event.description || "",
      start_date: event.start_date,
      end_date: event.end_date,
      start_time: event.start_time || "",
      end_time: event.end_time || "",
      all_day: event.all_day || "",
      collaboration_days: event.collaboration_day || false,
      range: event.range || "",
      custom_class: event.custom_class || "",
      id: event.id,
      event_link: event.event_link,
      office_lunch: event.office_lunch ? event.office_lunch : false,
      vendor: event.vendor ? event.vendor : null,
    });
  });
  return organizedEvents;
};

const loadUpTheCalendarData = async (selectedMonth, selectedYear, oktaAuth) => {
  // Steps
  // 1. Get the Data
  // 2. Format the data to have the expected key/values
  // 3. Sort into buckets based on day
  // 4. Pass or store the data for the calendar

  const lunchEvents = await getLunchEventData(
    selectedMonth,
    selectedYear,
    oktaAuth
  );
  const googleEvents = await getGoogleEventData(
    selectedMonth,
    selectedYear,
    oktaAuth
  );

  const sortedEvents = await sortedEventsByDay([
    ...googleEvents,
    ...lunchEvents,
  ]);
  return sortedEvents;
};

function getNextMonthsDates(inputDate, n) {
  const result = [];
  const currentDate = new Date(inputDate);
  for (let i = 1; i <= n; i++) {
    const newDate = new Date(currentDate);
    newDate.setDate(currentDate.getDate() + i);
    result.push(newDate.toISOString().split("T")[0]); // Format as YYYY-MM-DD
  }

  return result;
}
function getPrevMonthsDates(inputDate, n) {
  const result = [];
  const currentDate = new Date(inputDate);

  for (let i = 1; i <= n; i++) {
    const newDate = new Date(currentDate);
    newDate.setDate(currentDate.getDate() - i);
    result.push(newDate.toISOString().split("T")[0]); // Format as YYYY-MM-DD
  }

  return result;
}

// build the list of days
const getDaysOfTheMonth = (monthInfo) => {
  let daysInMonthArr = [];
  // create an array of all the days in the month
  // create filler days for prev month days and next month days

  let monthStartsOnWeekend =
    monthInfo.first_day_index === 6 || monthInfo.first_day_index === 0
      ? true
      : false;
  let monthEndsOnWeekend =
    monthInfo.last_day_index === 6 || monthInfo.last_day_index === 0
      ? true
      : false;

  // if no weekends then remove 1 from calculations
  let leadingOffset = monthInfo.no_weekends
    ? monthInfo.first_day_index - 1
    : monthInfo.first_day_index;
  let trailingOffset = monthInfo.no_weekends
    ? 5 - monthInfo.last_day_index
    : 6 - monthInfo.last_day_index;

  for (let index = 1; index <= monthInfo.last_day_date; index++) {
    const currentDay = new Date(
      `${monthInfo.month}/${index}/${monthInfo.year}`
    );

    // const formattedDate = `${currentDay.getFullYear()}-${(
    //   currentDay.getMonth() + 1
    // )
    //   .toString()
    //   .padStart(2, "0")}-${currentDay.getDate().toString().padStart(2, "0")}`;

    const formattedDate = `${monthInfo.year}-${monthInfo.month
      .toString()
      .padStart(2, "0")}-${index.toString().padStart(2, "0")}`;

    let isAWeekend =
      currentDay.getDay() === 6 || currentDay.getDay() === 0 ? true : false;
    // if we're hiding weekends then check that the day isn't a weekend
    if (monthInfo.no_weekends) {
      if (!isAWeekend) {
        daysInMonthArr.push({
          firstDay: index === 1 ? true : false,
          lastDay: index === monthInfo.last_day_date ? true : false,
          day_date: index,
          weekday_index: currentDay.getDay(),
          is_weekend: isAWeekend,
          full_date: `${monthInfo.year}-${monthInfo.month}-${index}`,
          days_events: monthInfo.events[formattedDate],
        });
      }
    } else {
      daysInMonthArr.push({
        firstDay: index === 1 ? true : false,
        lastDay: index === monthInfo.last_day_date ? true : false,
        day_date: index,
        weekday_index: currentDay.getDay(),
        is_weekend: isAWeekend,
        full_date: `${monthInfo.year}-${monthInfo.month}-${index}`,
        days_events: monthInfo.events[formattedDate],
      });
    }
  }

  // last months final days cell Generation

  let leadingDays = [];
  let trailingDays = [];

  let lastDay = parseInt(new Date(monthInfo.prev_month_last_day).getDate());
  let theDate = monthInfo.last_day;

  const prevMonthsEndingDays = getPrevMonthsDates(
    monthInfo.first_day,
    leadingOffset
  );
  const nextMonthLeadingDays = getNextMonthsDates(theDate, trailingOffset);

  if (monthInfo.no_weekends && monthStartsOnWeekend) {
    leadingOffset = 0;
    leadingDays = [];
  } else {
    // starting from nth offset to then count up the days in the loop to get the order right
    let lastDayStartCount = lastDay - (leadingOffset - 1);

    for (let i = 0; i < leadingOffset; i++) {
      const dayToAdd = {
        other_day: true,
        date: prevMonthsEndingDays[leadingOffset - 1 - i],
        day_date: prevMonthsEndingDays[leadingOffset - 1 - i].split("-")[2],
        days_events: monthInfo.events[prevMonthsEndingDays[i - 1]],
      };
      leadingDays.push(dayToAdd);
      lastDayStartCount++;
    }
  }

  // next months beginning Days Cell Generation

  if (monthInfo.no_weekends && monthEndsOnWeekend) {
    trailingOffset = 0;
    trailingDays = [];
  } else {
    trailingDays = [];
    for (let j = 1; j <= trailingOffset; j++) {
      const dayToAdd = {
        other_day: true,
        date: nextMonthLeadingDays[j - 1],
        day_date: parseInt(nextMonthLeadingDays[j - 1].split("-")[2]),
        days_events: monthInfo.events[nextMonthLeadingDays[j - 1]],
      };
      trailingDays.push(dayToAdd);
    }
  }

  daysInMonthArr = [...leadingDays, ...daysInMonthArr, ...trailingDays];
  return daysInMonthArr;
};

// get all the info for the selected Month
const getMonthInfo = async (
  selectedMonth,
  selectedYear,
  noWeekends,
  eventData
) => {
  // account for a user changed year
  let nextMonth = selectedMonth + 1;
  let prevMonth = selectedMonth - 1;
  if (selectedMonth == 12) {
    nextMonth = 1;
  }
  if (selectedMonth == 1) {
    prevMonth = 12;
  }

  const getNextMonth = (month, year) => {
    let nextMonth = (parseInt(month) + 1).toString().padStart(2, "0");
    let nextMonthYear = year;

    if (month === "12") {
      nextMonth = "01";
      nextMonthYear = parseInt(year) + 1;
    }

    return {
      month: month,
      year: year,
    };
  };

  const month = selectedMonth;
  const year = selectedYear;
  const firstDay = new Date(year, month - 1, 1);
  const nextMonthFirstDay = new Date(year, month, 1);
  const lastDay = new Date(year, month, 0);
  const prevMonthLastDay = new Date(year, prevMonth, 0);
  const prevMonthLastIndex = new Date(year, prevMonth, 0).getDay();

  let theInfo = {
    month: month,
    year: year,
    first_day: firstDay,
    first_day_index: firstDay.getDay(),
    last_day: lastDay,
    last_day_date: lastDay.getDate(),
    last_day_index: lastDay.getDay(),
    next_month_first_day: nextMonthFirstDay,
    next_month_first_day_full_date: getNextMonth(month, year)[month],
    next_month_first_day_index: nextMonthFirstDay.getDay(),
    prev_month_last_day: prevMonthLastDay,
    prev_month_last_day_index: prevMonthLastIndex,
    prev_month_last_day_full_date: "",
    no_weekends: noWeekends,
    events: eventData,
  };

  const monthDays = await getDaysOfTheMonth(theInfo);

  theInfo.month_days = monthDays;
  return theInfo;
};

function HomeCalendar({ theme }) {
  const { oktaAuth } = useOktaAuth();
  const [theEventData, setTheEventData] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [monthInfo, setMonthInfo] = useState(null);
  const [activeDay, setActiveDay] = useState(null);
  const [activeEvent, setActiveEvent] = useState(null);

  const [today, setToday] = useState(
    `${new Date().getFullYear()}-${
      new Date().getMonth() + 1
    }-${new Date().getDate()}`
  );

  const noWeekends = true;
  const popupEvents = false;

  let days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  let fullDays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  if (noWeekends) {
    days = ["Mon", "Tue", "Wed", "Thu", "Fri"];

    fullDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
  }

  useEffect(() => {
    (async () => {
      setMonthInfo(
        await getMonthInfo(
          selectedMonth,
          selectedYear,
          noWeekends,
          theEventData ? theEventData : []
        )
      );
    })();
  }, [selectedMonth, selectedYear, theEventData]);

  const handlePrevClick = () => {
    if (selectedMonth == 1) {
      setSelectedMonth(12);
      setSelectedYear((prevState) => prevState - 1);
    } else {
      setSelectedMonth((prevState) => prevState - 1);
    }
  };

  const handleNextClick = () => {
    if (selectedMonth == 12) {
      setSelectedMonth(1);
      setSelectedYear((prevState) => prevState + 1);
    } else {
      setSelectedMonth((prevState) => prevState + 1);
    }
  };

  const closePopup = (e, popupType) => {
    e.preventDefault();

    if (popupType === "day") {
      setActiveDay(null);
    }

    if (popupType === "event") {
      setActiveEvent(null);
    }

    if (popupType === "both") {
      setActiveEvent(null);
      setActiveDay(null);
    }
    return;
  };

  useEffect(() => {
    (async () => {
      let allTheEvents = await loadUpTheCalendarData(
        selectedMonth,
        selectedYear,
        oktaAuth
      );
      setTheEventData(allTheEvents);
    })();
  }, [selectedMonth, selectedYear]);

  useEffect(() => {}, [activeDay]);

  const closeButton =
    theme.name == "light"
      ? "https://assets.mx.com/hub/images/close-x-dark.svg"
      : "https://assets.mx.com/hub/images/close-x-light.svg";

  const EventPopup = () => {
    let eventDate = activeEvent.start_date;
    let dayIndex = new Date(eventDate).getDay();

    if (!activeEvent) {
      return;
    }

    let eventLink = null;

    if (activeEvent.event_link) {
      eventLink =
        "https://calendar.google.com/calendar/u/0/r/eventedit/duplicate/" +
        activeEvent.event_link.split("?eid=")[1].split("&ctz")[0];
    }

    let officeLunch = false;
    if (activeEvent.office_lunch) {
      officeLunch = true;
    }

    if (officeLunch) {
      return (
        <EventPopupWrap className="event-popup-wrap active-event-popup office-lunch">
          <img
            className="close-x"
            onClick={(e) => {
              closePopup(e, "event");
            }}
            src={closeButton}
            alt="close button"
          />
          {/* <img
            className="lunch-icon"
            alt="lunch-icon"
            src="https://assets.mx.com/hub/images/icon-hamburger.svg"
          /> */}
          <p className="lunch-vendor">{activeEvent.vendor}</p>
          <p className="lunch-name">{activeEvent.name}</p>

          {activeEvent.description && (
            <div className={`lunch-description`}>
              {parse(activeEvent.description)}
            </div>
          )}
        </EventPopupWrap>
      );
    }

    return (
      <EventPopupWrap className="event-popup-wrap active-event-popup">
        <img
          className="close-x"
          onClick={(e) => {
            closePopup(e, "event");
          }}
          src={closeButton}
          alt="close button"
        />
        <p className="popup-event-title">{activeEvent.name}</p>

        {/* Figure out Event times */}
        {/* Show range in the date field for range events */}

        <p className="popup-event-time">
          <span className="popup-day">
            {utils.easyToReadDate(activeEvent.start_date)}
            {activeEvent.range
              ? ` - ${utils.easyToReadDate(activeEvent.end_date)}`
              : ""}
          </span>
          {/* <span className="popup-time">{getEventTime(activeDay)}</span> */}
        </p>

        {activeEvent.description && (
          <div className={`popup-description`}>
            {parse(activeEvent.description)}
          </div>
        )}

        {eventLink && (
          <OutlineButton
            rel="noreferrer"
            target="_blank"
            href={eventLink}
            outlineColor="#2F73DA"
          >
            Add to Calendar
          </OutlineButton>
        )}
      </EventPopupWrap>
    );
  };
  const DayPopup = () => {
    const activeDayEvents = monthInfo.events[addLeadingZeros(activeDay)];
    return (
      <EventPopupWrap>
        <img
          className="close-x"
          onClick={(e) => {
            closePopup(e, "day");
          }}
          src={closeButton}
          alt="close button"
        />
        <p className="popup-event-title">{utils.easyToReadDate(activeDay)}</p>
        <ul className="event-list ">
          {activeDayEvents.map((event, index) => {
            if (event.office_lunch) {
              return (
                <li
                  key={index}
                  className={`event ${
                    event.custom_class ? event.custom_class : ""
                  }`}
                  onClick={(e) => {
                    setActiveEvent(event);
                  }}
                >
                  <p className="lunch-vendor">
                    {"Lunch: "} {event.vendor} -{" "}
                    <span className="lunch-name">{event.name}</span>
                  </p>
                </li>
              );
            } else {
              return (
                <li
                  key={index}
                  className={`event ${
                    event.custom_class ? event.custom_class : ""
                  }`}
                  onClick={(e) => {
                    setActiveEvent(event);
                  }}
                >
                  <p>{event.name}</p>
                </li>
              );
            }
          })}
        </ul>
        {/* Figure out Event times */}
        {/* Show range in the date field for range events */}

        {/* <p className="popup-event-time">
          <span className="popup-day">
            {utils.easyToReadDate(activeEvent.start_date)}
            {activeEvent.range
              ? ` - ${utils.easyToReadDate(activeEvent.end_date)}`
              : ""}
          </span>{" "}
          
        </p> */}
      </EventPopupWrap>
    );
  };

  return (
    <Wrap>
      <BaseCalendarStyles>
        <CalendarStyles>
          <div
            className={`calendar ${noWeekends ? "no-weekends" : ""} ${
              activeDay || activeEvent ? "active-popup" : ""
            }`}
          >
            {(activeEvent || activeDay) && (
              <div
                className="popup-bg-overlay"
                onClick={(e) => closePopup(e, "both")}
              />
            )}
            <div className="calendar-header">
              <img
                className="control-arrow prev-arrow"
                src="https://assets.mx.com/hub/images/icon-arrow-blue.svg"
                onClick={handlePrevClick}
              />
              <h1>
                {months[selectedMonth - 1]} {selectedYear}
              </h1>
              <img
                className="control-arrow next-arrow"
                style={{ transform: "rotate(180deg)" }}
                src="https://assets.mx.com/hub/images/icon-arrow-blue.svg"
                onClick={handleNextClick}
              />
            </div>
            <div className="calendar-body">
              {activeEvent && <EventPopup />}
              {activeDay && <DayPopup />}
              <div id="weekdays">
                {fullDays.map((day, index) => (
                  <div
                    key={"week-" + index}
                    className="weekday"
                    id={"week-" + index}
                  >
                    {day}
                  </div>
                ))}
              </div>
              <div
                id="month-days"
                className={
                  monthInfo && monthInfo.month_days.length % 4 === 0
                    ? "weeks-4"
                    : "weeks-5"
                }
              >
                {monthInfo &&
                  monthInfo.month_days.map((day, index) => {
                    const todayClass = today === day.full_date ? "today" : "";
                    const otherDayClass = day.other_day ? "other-day" : "";
                    const weekendClass = day.is_weekend ? "weekend" : "";
                    const displayedDate = day.day_date;
                    const hasEvents = day.days_events
                      ? "has-events"
                      : "no-events";

                    // Check if the events of the day are just lunch, collab day, or both This is used to hide the dot indicator on those days

                    let onlyNotCountedEvents = false;

                    if (day.days_events && day.days_events.length > 0) {
                      const events = day.days_events;

                      // Check if there's only one event and it's either a lunch or a collaboration day
                      if (
                        events.length === 1 &&
                        (events[0].office_lunch || events[0].collaboration_days)
                      ) {
                        onlyNotCountedEvents = true;
                      }

                      if (events.length === 2) {
                        const isLunch = (event) => event.office_lunch;
                        const isCollaborationDay = (event) =>
                          event.collaboration_days;

                        if (
                          (isLunch(events[0]) &&
                            isCollaborationDay(events[1])) ||
                          (isLunch(events[1]) && isCollaborationDay(events[0]))
                        ) {
                          onlyNotCountedEvents = true;
                        }
                      }
                    }

                    const isActiveDay = activeDay === day.full_date;
                    const dayMonth = day.full_date
                      ? day.full_date.split("-")[1]
                      : day.date.split("-")[1];

                    let travelDay = false;
                    let lunchDay = false;
                    if (day.days_events) {
                      for (
                        let index = 0;
                        index < day.days_events.length;
                        index++
                      ) {
                        const event = day.days_events[index];

                        if (event.collaboration_days) {
                          travelDay = true;
                        }
                        if (event.office_lunch) {
                          lunchDay = true;
                        }
                      }
                    }
                    return (
                      <div
                        key={`month-${index}`}
                        className={`calendar-day ${weekendClass} ${todayClass} ${otherDayClass} ${hasEvents} ${
                          onlyNotCountedEvents ? "not-counted-events" : ""
                        } ${travelDay ? "collab-week" : ""} ${
                          lunchDay ? "lunch-day" : ""
                        }  ${isActiveDay ? "active-day" : ""}`}
                        id={day.full_date}
                        onClick={(e) =>
                          day.days_events
                            ? setActiveDay(e.target.id)
                            : setActiveDay(null)
                        }
                      >
                        <div className="day-date">
                          <p className="mobile-month">{months[dayMonth - 1]}</p>
                          <p className="day-date-num">{displayedDate}</p>
                        </div>

                        <div className="event-dot"></div>

                        {day.days_events && (
                          <ul
                            className={`event-list ${
                              day.day_date > 13 ? "lower-days" : "upper-days"
                            } ${popupEvents ? "popup-events" : ""}`}
                          >
                            {/* Popup events event list popup */}
                            {popupEvents && (
                              <>
                                <img
                                  className="close-x"
                                  onClick={(e) => {
                                    setActiveDay(null);
                                  }}
                                  src={closeButton}
                                  alt="close button"
                                />
                                <h3>{utils.easyToReadDate(day.full_date)}</h3>
                              </>
                            )}
                            {day.days_events &&
                              day.days_events.map((event, index) => {
                                return (
                                  <li
                                    key={index}
                                    className={`event ${
                                      event.custom_class
                                        ? event.custom_class
                                        : ""
                                    }`}
                                    onClick={(e) => {
                                      setActiveEvent(event);
                                    }}
                                  >
                                    <p>{event.name}</p>
                                  </li>
                                );
                              })}
                          </ul>
                        )}
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </CalendarStyles>
      </BaseCalendarStyles>
    </Wrap>
  );
}

export default withTheme(HomeCalendar);
