import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import styled from "styled-components";
import api from "./api";
import { Link } from "react-router-dom";
import ReturnToTopButton from "./ReturnToTopButton";

import { useOktaAuth } from "@okta/okta-react";

const OuterWrapper = styled.section`
  width: 100%;
  background: ${({ theme }) => theme.backgroundColor};
`;

const PageWrapper = styled.section`
  display: flex;
  flex-direction: row;
  max-width: 1750px;
  min-height: calc(100vh - 76px);
  max-width: 2500px;
  margin: 0 auto;
`;

const GlossaryNestedSidebar = styled.div`
  background: ${({ theme }) => theme.hoverBackground};
  display: flex;
  flex-direction: column;
  width: 200px;
  padding: 0 40px;

  @media only screen and (max-width: 1000px) {
    display: none;
  }
`;

const SideWrap = styled.div`
  position: sticky;
  top: 76px;
  padding: 20px 0;
`;

const LetterMarker = styled.div`
  height: 0;
  width: 100%;
`;

const ActiveLetter = styled.span`
  font-size: 40px;
  font-family: Proxima-nova;
  padding-left: 20px;
  font-weight: 300;
  text-transform: uppercase;
  position: relative;
  top: -2px;
`;
const GlossarySearch = styled.input`
  background: ${({ theme }) => theme.backgroundColor};
  border-radius: 25px;
  outline: none;
  border: none;
  height: 30px;
  color: ${({ theme }) => theme.textColor};
  padding-left: 20px;
  position: sticky;
  top: 10px;
`;
const FilterByLetter = styled.div`
  margin-top: 20px;

  h3 {
    letter-spacing: 0px;
    color: ${({ theme }) => theme.textColor};
    text-transform: uppercase;
  }
`;
const Letters = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;

  .active-letter {
    background: ${({ theme }) => theme.blue};
    color: #fff;
  }
`;
const Letter = styled(Link)`
  background: ${({ theme }) => theme.backgroundColor};
  color: ${({ theme }) => theme.textColor};
  font-weight: 600;
  width: 30px;
  line-height: 30px;
  border-radius: 50%;
  text-align: center;
  font-size: 14px;
  margin: 5px;
  text-decoration: none;

  transition: opacity ease 0.3s;
  &:hover {
    opacity: 0.5;
  }
  &:active {
    transition: none;
    opacity: 0.3;
  }
`;
const FilterByCat = styled.div``;
const GlossaryContent = styled.div`
  color: ${({ theme }) => theme.textColor};
  background: ${({ theme }) => theme.backgroundColor};
  position: relative;
  padding-bottom: 50px;
`;

const GlossaryTitle = styled.h2`
  letter-spacing: 0px;
  color: ${({ theme }) => theme.textColor};
  margin: 0;
  width: calc(100% - 40px);
  margin: 0 0 0 40px;
  padding-top: 30px;
  padding-bottom: 30px;
  background: ${({ theme }) => theme.backgroundColor};
  line-height: 1;
  position: sticky;
  top: 76px;
  z-index: 10;
  border-bottom: 2px ${({ theme }) => theme.divider} solid;
`;
const Brush = styled.span`
  font-family: MXBrush;
  font-weight: 300;
  font-size: 72px;
`;
const Term = styled.h3`
  color: ${({ theme }) => theme.textColor};
  letter-spacing: 0px;
  margin: 0;
`;
const Definition = styled.p`
  letter-spacing: 0px;
  color: ${({ theme }) => theme.textColor};
  margin: 0;
`;
const Category = styled.p`
  margin: 0;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.textColor};
  padding-bottom: 10px;
`;
const GlossaryItem = styled.div`
  padding: 40px 0 30px 0;
  position: relative;
  max-width: 950px;
  margin: 0 auto;
  margin-left: 150px;
  width: 80%;

  @media only screen and (max-width: 1000px) {
    margin: 0 auto;
  }

  :after {
    width: 100px;
    background: ${({ theme }) => theme.divider};
    position: absolute;
    content: "";
    height: 1px;
    left: 0;
    bottom: 0;
  }
`;

const TermSubmit = styled.div`
  border-top: 1px solid ${({ theme }) => theme.hoverBackground};
  margin-top: 50px;
  padding-top: 10px;

  form {
    display: flex;
    flex-direction: column;
  }

  h3 {
    letter-spacing: 0px;
    color: ${({ theme }) => theme.textColor};
    text-transform: uppercase;
  }
`;

const SubmittedTerm = styled.input`
  background: ${({ theme }) => theme.backgroundColor};
  width: 97%;
  color: ${({ theme }) => theme.textColor};
  letter-spacing: 0px;
  opacity: 1;
  min-height: 30px;
  padding: 7px;
  font-family: inherit;
  font-size: inherit;
  border: none;
  outline: none;
  border-radius: 25px;
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${({ theme }) => theme.textDisabled};
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${({ theme }) => theme.textDisabled};
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${({ theme }) => theme.textDisabled};
  }
`;

const CategoryDropdown = styled.select`
  background: ${({ theme }) => theme.backgroundColor};
  color: ${({ theme }) => theme.textColor};
  opacity: 0.5;
  width: 97%;
  box-sizing: content-box;
  letter-spacing: 0px;
  opacity: 1;
  min-height: 20px;
  padding: 7px;
  font-family: inherit;
  font-size: inherit;
  border: none;
  outline: none;
  border-radius: 10px;
  border-radius: 25px;

  /* Hack to change the color of the default placeholder */
  &:invalid {
    color: ${({ theme }) => theme.textDisabled};
  }
`;

const SubmittedDefinition = styled.textarea`
  margin: 15px 0;
  background: ${({ theme }) => theme.backgroundColor};
  color: ${({ theme }) => theme.textColor};
  width: 97%;
  letter-spacing: 0px;
  opacity: 1;
  min-height: 68px;
  padding: 7px;
  font-family: inherit;
  font-size: inherit;
  border: none;
  outline: none;
  border-radius: 10px;
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${({ theme }) => theme.textDisabled};
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${({ theme }) => theme.textDisabled};
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${({ theme }) => theme.textDisabled};
  }
`;

const SubmitButton = styled.button`
  text-align: center;
  background: none;
  margin-top: 10px;
  text-transform: uppercase;
  border: none;
  outline: none;
  padding: 10px;
  max-width: 200px;
  width: 100%;
  margin-left: auto;
  margin-top: 20px;
  text-decoration: none;
  cursor: pointer;
  background: #2f73da 0% 0% no-repeat padding-box;
  border-radius: 20px;
  color: white;
  &:hover {
    opacity: 0.7;
  }
  &:active {
    opacity: 0.5;
  }

  &.disabled {
    background: gray !important;
    pointer-events: none;
  }
`;

const SuccessMessageSection = styled.section`
  width: 100%;
  text-align: center;
  margin-top: 20px;
  p {
    padding: 10px 0;
    color: ${({ theme }) => theme.textColor};
  }
`;

function GlossaryPage() {
  const [glossaryData, setGlossaryData] = useState([]);
  const [activeLetter, setActiveLetter] = useState("letter-a");
  const [letters, setLetters] = useState([]);
  const [runAfterModeChange, setRunAfterModeChange] = useState(null);
  const [mode, setMode] = useState("reading");
  const [searchResults, setSearchResults] = useState([]);
  const { authState, oktaAuth } = useOktaAuth();
  const submittedDefinitionRef = useRef("");
  const submittedTermRef = useRef("");
  const submittedCategoryRef = useRef("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [suggestMode, setSuggestMode] = useState(false);
  const [displaySuggestion, setDisplaySuggestion] = useState(false);
  const [oktaInfo, setOktaInfo] = useState([]);
  const [submittedCategory, setSubmittedCategory] = useState("");

  // Get these dynamically eventually
  const glossaryCategories = [
    "Company",
    "Industry",
    "Service",
    "Software",
    "Technology",
  ];

  useEffect(() => {
    // Check if the user is authenticated
    if (authState.isAuthenticated) {
      const accessToken = authState.accessToken.accessToken;
      setOktaInfo(authState.idToken.claims);

      // Now you can use the accessToken as needed
      // For example, you can include it in your API requests
    }
  }, [oktaInfo]);

  let setClickedLetter = async (i) => {
    setMode("reading");
    const l = letters[i];
    const letterId = "letter-" + l;
    const y = document.getElementById(letterId).getBoundingClientRect().top;
    const position = y + window.scrollY - 200;
    window.scrollTo({ top: position, behavior: "smooth" });
    setActiveLetter(letterId);
  };

  const getNextLetterId = (s) => {
    //this function takes strings formatted like "letter-k"
    const actualLetter = s.split("-")[1];
    const i = letters.indexOf(actualLetter) + 1;
    if (i >= letters.length) {
      return "letter-z";
    }
    let l = letters[i];
    return "letter-" + l;
  };

  const getPrevLetterId = (s) => {
    //this function takes strings formatted like "letter-k"
    const actualLetter = s.split("-")[1];
    const i = letters.indexOf(actualLetter) - 1;
    if (i < 0) {
      return "letter-a";
    }
    let l = letters[i];
    return "letter-" + l;
  };

  useEffect(() => {
    async function fetchData() {
      let filterParams = "filter[status][_eq]=published";
      filterParams += "&limit=-1";
      filterParams += "&sort=term";
      const glossaryCollection = await api.collection(
        "glossary",
        oktaAuth,
        filterParams
      );
      setGlossaryData(glossaryCollection);

      const letterSet = [];

      glossaryCollection.forEach((item) => {
        const firstLetter = item.term.trim().charAt(0).toLowerCase();
        if (!letterSet.includes(firstLetter)) {
          letterSet.push(firstLetter);
        }
      });
      setLetters(letterSet);
    }
    fetchData();
  }, []);

  useEffect(() => {
    const onScroll = (e) => {
      const nextLetterId = getNextLetterId(activeLetter);
      const prevLetterId = getPrevLetterId(activeLetter);
      const nextHeader = document.getElementById(nextLetterId);
      const activeHeader = document.getElementById(activeLetter);

      if (nextHeader.getBoundingClientRect().y <= 200) {
        setActiveLetter(nextLetterId);
      } else if (activeHeader.getBoundingClientRect().y > 200) {
        setActiveLetter(prevLetterId);
      }
    };
    window.addEventListener("wheel", onScroll);

    return () => window.removeEventListener("wheel", onScroll);
  }, [activeLetter]);

  const handleTermSubmit = () => {
    const successfulSubmitMessage = () => {
      setShowSuccessMessage(true);
      setTimeout(() => setShowSuccessMessage(false), 4000);
      setSubmittedCategory("");
      return false;
    };

    const data = {
      term: submittedTermRef.current.value,
      definition: submittedDefinitionRef.current.value,
      category: submittedCategoryRef.current.value,
      status: "review",
      user_submitted: oktaInfo.name,
    };

    if (data.term == "") {
      return;
    } else if (data.definition == "") {
      return;
    } else if (data.category == "") {
      return;
    } else {
      sendData();
    }

    async function sendData() {
      let suggestionPost = await api.createItem("glossary", oktaAuth, data);
      if (suggestionPost === "success") {
        successfulSubmitMessage();
      }
    }
  };

  let currentMappedLetter = "";
  let glossaryItems = glossaryData;
  let pageName = "Glossary";
  let pageSubHeader = activeLetter.split("-")[1];

  if (mode == "searching") {
    glossaryItems = searchResults;
    pageName = "Search results:";
    pageSubHeader = "";
  }

  return (
    <OuterWrapper>
      <PageWrapper>
        <GlossaryNestedSidebar>
          <SideWrap>
            <FilterByLetter>
              <h3>Filter By Letter</h3>
              <Letters>
                {letters.map((letter, i) => {
                  return (
                    <Letter
                      id={"letter-button-" + letter}
                      className={
                        activeLetter == "letter-" + letter && "active-letter"
                      }
                      to={`#${letter}`}
                      onClick={() => {
                        setClickedLetter(i);
                      }}
                      key={i}
                    >
                      {letter.toUpperCase()}
                    </Letter>
                  );
                })}
              </Letters>
            </FilterByLetter>
            <FilterByCat></FilterByCat>
            {/* <TermSubmit>
              <h3>Suggest a New Term</h3>
              {!showSuccessMessage && (
                <form action="javascript:void(0)">
                  <SubmittedTerm
                    required
                    placeholder={"Term"}
                    ref={submittedTermRef}
                  />
                  <SubmittedDefinition
                    required
                    ref={submittedDefinitionRef}
                    placeholder={"Definition"}
                    // using ref instead of hook as band-aid to avoid a typing bug
                  />
                  <CategoryDropdown
                    required
                    ref={submittedCategoryRef}
                    value={submittedCategory}
                    onChange={(e) => setSubmittedCategory(e.target.value)}
                  >
                    <option value="" defaultValue disabled>
                      Select a Category
                    </option>
                    {glossaryCategories.map((category, index) => {
                      return (
                        <option key={index} value={category}>
                          {category}
                        </option>
                      );
                    })}
                  </CategoryDropdown>
                  <SubmitButton type="submit" onClick={handleTermSubmit}>
                    Submit
                  </SubmitButton>
                </form>
              )}
              {showSuccessMessage && (
                <SuccessMessageSection>
                  <p>
                    Your term has been submitted for review and will be listed
                    in the glossary once approved!
                  </p>
                </SuccessMessageSection>
              )}
            </TermSubmit> */}
          </SideWrap>
        </GlossaryNestedSidebar>
        {glossaryItems && (
          <GlossaryContent>
            <GlossaryTitle className="glossary-title">
              <Brush>{pageName}</Brush>
              <ActiveLetter>{pageSubHeader}</ActiveLetter>
            </GlossaryTitle>
            {glossaryItems.map((item, i) => {
              const firstLetter = item.term.trim().charAt(0).toLowerCase();

              let LetterHeader = () => "";

              if (currentMappedLetter != firstLetter) {
                currentMappedLetter = firstLetter;

                LetterHeader = () => {
                  return <LetterMarker id={"letter-" + firstLetter} />;
                };
              }

              return (
                <div key={i}>
                  <LetterHeader />
                  <GlossaryItem
                    id={item.term.toLowerCase().replaceAll(" ", "-")}
                  >
                    <Term>{item.term}</Term>
                    <Category>{item.category}</Category>
                    <Definition>{item.definition}</Definition>
                  </GlossaryItem>
                </div>
              );
            })}
          </GlossaryContent>
        )}
      </PageWrapper>
      <ReturnToTopButton
        onReturn={() => {
          setClickedLetter(0);
        }}
      />
    </OuterWrapper>
  );
}

export default GlossaryPage;
