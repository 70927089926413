import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const PageNumbers = styled.div`
  display: flex;
  justify-content: center;
  max-width: 360px;
  margin: 0 auto;
  user-select: none;
`;
const NextArrow = styled.p`
  color: ${({ theme }) => theme.textColor};
  margin-top: 9px;
  cursor: pointer;
  margin-left: 10px;
`;
const PreviousArrow = styled.p`
  color: ${({ theme }) => theme.textColor};
  margin-top: 9px;
  cursor: pointer;
  margin-right: 10px;
`;

const LastPage = styled.div`
  display: flex;
`;
const BetweenDots = styled.p`
  color: ${({ theme }) => theme.textColor};
  letter-spacing: 2px;
  margin: 0;
  margin-top: 14px;
`;

const PageNumber = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  font-size: 18px;
  line-height: 1;
  border-radius: 50%;
  text-align: center;
  transition: transform 0.2s ease;
  cursor: pointer;
  margin: 5px 10px;
  border: 1px solid ${({ theme }) => theme.blue};
  color: ${({ theme }) => theme.textColor};

  &:hover {
    color: #fff;
    background: ${({ theme }) => theme.blue};
    transform: scale(0.98);
  }
  &.current-page {
    color: #fff;
    background: ${({ theme }) => theme.blue};
    transform: scale(0.98);
  }
`;

const Pagination = ({ setPage, page, count, limit }) => {
  const pageCount = Math.ceil(count / limit);
  let dotNumber = 1;

  if (page > dotNumber) {
    dotNumber = page - 1;
  }

  if (page > pageCount - 1) {
    dotNumber--;
  }

  const dot1 = dotNumber;
  const dot2 = dotNumber + 1;
  const dot3 = dotNumber + 2;
  const dot4 = dotNumber + 3;

  const prevDots = dot1 > 2;
  const nextDots = dot4 < pageCount;

  return (
    <PageNumbers>
      {dot1 > 1 && (
        <PageNumber
          onClick={() => setPage(1)}
          className={page == 1 && "current-page"}>
          {1}
        </PageNumber>
      )}

      {prevDots && <BetweenDots>...</BetweenDots>}

      <PageNumber
        onClick={() => setPage(dot1)}
        className={page == dot1 && "current-page"}>
        {dot1}
      </PageNumber>

      {dot2 <= pageCount && (
        <PageNumber
          onClick={() => setPage(dot2)}
          className={page == dot2 && "current-page"}>
          {dot2}
        </PageNumber>
      )}

      {dot3 <= pageCount && (
        <PageNumber
          onClick={() => setPage(dot3)}
          className={page == dot3 && "current-page"}>
          {dot3}
        </PageNumber>
      )}
      
      {nextDots && <BetweenDots>...</BetweenDots>}

      {dot4 <= pageCount && (
        <PageNumber
          onClick={() => setPage(pageCount)}
          className={page == pageCount && "current-page"}>
          {pageCount}
        </PageNumber>
      )}

    </PageNumbers>
  );
};

export default Pagination;
