import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import styled, { withTheme } from "styled-components";
import spacemanLight from "../assets/images/spaceman-dark.png";
import spacemanDark from "../assets/images/spaceman-light.png";
import HeroBG from "../assets/images/founders-circle/header-bg.png";
import Jacket from "../assets/images/founders-circle/jacket.png";
import Shoes from "../assets/images/founders-circle/shoes.png";
import Watch from "../assets/images/founders-circle/watch.png";
import ValOneBG from "../assets/images/founders-circle/watch.png";

const PageWrapper = styled.section`
  background: ${({ theme }) => theme.backgroundColor};

  * {
    margin: 0;
    padding: 0;
    font-family: Proxima-nova;
  }

  .sm-gray {
    color: ${({ theme }) => theme.textGray};
    font-weight: 600;
    letter-spacing: 1.1;
    font-size: 18px;
    margin: 0;
  }
`;

const InnerWrapper = styled.div`
  background: url(${HeroBG});
  background-size: cover;
  background-repeat: no-repeat;
`;
const Hero = styled.div`
  max-width: 1435px;
  margin: 0 auto;
  text-align: left;
  // width: 100%;
  padding: 100px 50px;

  @media screen and (max-width: 900px) {
    padding: 100px 20px;
  }

  h3 {
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 1;
    color: #ffffff;
    margin-bottom: 50px;
    width: 100%;
  }

  h1 {
    font-weight: bold;
    font-size: 100px;
    line-height: 1;
    color: #ffffff;
    width: 100%;

    @media screen and (max-width: 900px) {
      font-size: 80px;
    }
  }
  p {
    margin: 50px 0;
    font-weight: normal;
    font-size: 24px;
    line-height: 1.2;
    color: #ffffff;
    max-width: 1054px;
    width: 100%;
    @media screen and (max-width: 900px) {
      font-size: 18px;
    }
  }
`;

const Awards = styled.div`
  max-width: 1435px;
  margin: 50px auto;
  display: flex;
  padding: 0 20px;

  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`;
const LeftCol = styled.div`
  max-width: 387px;

  h3 {
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.textColor};
    margin-bottom: 10px;
  }

  h2 {
    font-weight: bold;
    font-size: 48px;
    line-height: 1.2;
    color: ${({ theme }) => theme.textColor};
    padding: 10px 0;
    margin-bottom: 10px;
    text-transform: uppercase;
  }
  p {
    margin-bottom: 30px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: ${({ theme }) => theme.textColor};
    max-width: 322px;
  }
`;
const RightCol = styled.div`
  max-width: 1034px;
  margin-left: 20px;
  width: 100%;
  background: ${({ theme }) => theme.sectionColor};
  border-radius: 25px;

  @media screen and (max-width: 900px) {
    margin-left: 0;
  }
`;
const BackgroundWrapper = styled.div`
  background: ${({ theme }) => theme.sectionColor};
`;
const Accordion = styled.div`
  .accordion {
    color: ${({ theme }) => theme.textColor};
    cursor: pointer;
    padding: 10px 18px;
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
    transition: 0.4s;
    background: ${({ theme }) => theme.tabsWrapper};
    font-weight: bold;
    font-size: 21px;
    line-height: 34px;
    text-transform: uppercase;
    margin: 1px 0;

    &.active {
      display: none;
      background: ${({ theme }) => theme.navBackgroundColor};
    }
    &:hover {
      background: ${({ theme }) => theme.hoverBackground};
    }
  }

  .panel {
    background: ${({ theme }) => theme.navBackgroundColor};
    padding: 20px 20px 30px 20px;
    display: none;
    overflow: hidden;
    color: ${({ theme }) => theme.textColor};

    h2 {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 28px;
      line-height: 44px;
      color: ${({ theme }) => theme.textColor};
      margin-bottom: 15px;
    }

    p {
      line-height: 1.2;
    }

    &.active {
      display: block;
    }
  }
`;
const Details = styled.div`
  background: ${({ theme }) => theme.sectionColor};
  max-width: 1435px;
  margin: 50px auto;
  padding: 20px 20px;
  padding-bottom: 50px;
  border-radius: 5px;

  & > h2 {
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.textColor};
    margin-bottom: 10px;
  }
`;
const DetailRows = styled.div`
  div {
    margin: 10px 0;
  }
  h3 {
    font-weight: bold;
    font-size: 48px;
    line-height: 58px;
    color: ${({ theme }) => theme.textColor};
    margin-bottom: 10px;
  }
  p {
    margin-bottom: 30px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: ${({ theme }) => theme.textColor};
    max-width: 1364px;
  }
`;

const BlueBtn = styled.a`
  text-decoration: none;
  color: #fff;
  background: ${({ theme }) => theme.blue};
  margin-top: ${(props) => `${props.marginTop}px;`}
  padding: 8px 20px;
  font-size: 14px;
  border-radius: 25px;
  -webkit-text-decoration: none;
  text-decoration: none;
  text-transform: uppercase;
`;
const PrizeTiles = styled.div`
  display: grid;
  grid-template-columns: 462px 462px 462px;
  grid-gap: 10px;
  margin-top: 30px;

  @media screen and (max-width: 1400px) {
    grid-template-columns: 462px 462px;
    justify-content: center;
    align-content: center;
  }
  @media screen and (max-width: 900px) {
    grid-template-columns: 462px;
    justify-content: center;
    align-content: center;
  }

  div {
    position: relative;
    transition: transform 0.2s ease;

    &:hover {
      transform: scale(0.995);
    }

    p {
      position: absolute;
      bottom: 20px;
      left: 20px;
      color: #fff;
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      line-height: 34px;
    }
  }
`;

function FoundersCircle({ theme }) {
  const [activeAccordion, setActiveAccordion] = useState(1);

  function toggleAccordion(num) {
    setActiveAccordion(num);
  }

  return (
    <>
      <PageWrapper>
        <InnerWrapper>
          <Hero>
            <h3>MARCH 16, 2023</h3>
            <h1>FOUNDERS CIRCLE</h1>
            <p>
              In honor of our MX founders, we are excited to introduce the
              Founders Circle Awards; an initiative to recognize MXers who
              embody our MX values.
            </p>
            <BlueBtn
              href="https://www.wrike.com/workspace.htm?acc=379363&wr=2#/forms?formid=649494"
              target="_blank"
            >
              Submit A Nomination
            </BlueBtn>
          </Hero>
        </InnerWrapper>
        <Awards>
          <LeftCol>
            <h3 className="sm-gray">Awards</h3>
            <h2>Founders Circle Awards</h2>
            <p>
              The Founders Circle recognizes team member excellence across our
              MX Values. Nominations should clearly outline the value the team
              member demonstrated in relation to a specific project and outcome.
            </p>
            <BlueBtn
              href="https://www.wrike.com/workspace.htm?acc=379363&wr=2#/forms?formid=649494"
              target="_blank"
            >
              Submit A Nomination
            </BlueBtn>
          </LeftCol>
          <RightCol>
            <Accordion>
              <button
                onClick={() => toggleAccordion(1)}
                className={`accordion ${activeAccordion == 1 ? "active" : ""}`}
              >
                Purposeful Contribution
              </button>
              <div className={`panel ${activeAccordion == 1 ? "active" : ""}`}>
                <h2>Purposeful Contribution</h2>
                <p>
                  We believe that we have a moral imperative to always be
                  advancing humankind. We believe it is possible to change the
                  arc of humanity and we intend to. We align with those that are
                  on a mission to improve the world. We strive for such things
                  and will contribute all we can in the roles, ways and
                  companies that have the most impact. Contribution demands
                  execution and MX chooses to have a strong bias towards
                  execution. We know that we cannot contribute and have impact
                  at scale if we do not have a culture of clear individual, team
                  and organizational accountability.
                </p>
              </div>

              <button
                onClick={() => toggleAccordion(2)}
                className={`accordion ${activeAccordion == 2 ? "active" : ""}`}
              >
                Exuberance for Life
              </button>
              <div className={`panel ${activeAccordion == 2 ? "active" : ""}`}>
                <h2>Exuberance for Life</h2>
                <p>
                  We choose to be exuberant and buoyant in all we do. We think
                  of life as a gift and a chance to make each person’s journey
                  even better. We believe we get the chance but also have the
                  responsibility to uplift others by being inspirational,
                  encouraging, kind (even when being firm), and loving. We
                  strive in everything we do to be constructive and kind while
                  keeping any ire or condescension at bay. Bottom line: We don’t
                  like jerks. We have the right attitude while enjoying and
                  being grateful for the ride!
                </p>
              </div>
              <button
                onClick={() => toggleAccordion(3)}
                className={`accordion ${activeAccordion == 3 ? "active" : ""}`}
              >
                Founder Mindset
              </button>
              <div className={`panel ${activeAccordion == 3 ? "active" : ""}`}>
                <h2>Founder Mindset</h2>
                <p>
                  When encountering any problem across MX, we ask ourselves, “If
                  not me, then who? If not now, then when?” We care deeply about
                  the company, its trajectory and velocity to the point we can
                  not stay silent if we see an area where we can do better. We
                  are willing to vigorously and passionately debate options so
                  that we can quickly come to the optimal solution. We are
                  gritty, relentless leaders (regardless of title) who are
                  introspective and self-analytical. In the face of complex,
                  long term problems, we are capable of composing world-changing
                  solutions by reducing problems to fundamental bite-size
                  iterative pieces. We deliver quality work and build for the
                  long term.
                </p>
              </div>
              <button
                onClick={() => toggleAccordion(4)}
                className={`accordion ${activeAccordion == 4 ? "active" : ""}`}
              >
                Foundation of Trust
              </button>
              <div className={`panel ${activeAccordion == 4 ? "active" : ""}`}>
                <h2>Foundation of Trust</h2>
                <p>
                  We demand transparency, openness and trust from ourselves and
                  each other. We err on the side of trust and transparency and
                  rely on precise data-driven clarity. We are a data-driven
                  organization that believes in radical honesty. We leverage
                  metrics and data to celebrate our capability, increase our
                  transparency, optimize our accountability, and expose our
                  vulnerability. Then we use that data to make solid strategic
                  decisions. We seek to find understanding and common ground. We
                  never seek to be offended. We trust, but verify. We are
                  precise in all things to remove ambiguity and drive to clarity
                  across the organization.
                </p>
              </div>
              <button
                onClick={() => toggleAccordion(5)}
                className={`accordion ${activeAccordion == 5 ? "active" : ""}`}
              >
                Objectivity in Discourse
              </button>
              <div className={`panel ${activeAccordion == 5 ? "active" : ""}`}>
                <h2>Objectivity in Discourse</h2>
                <p>
                  We are truth seekers who believe that radical honesty and
                  integrity are requisite in all actions. Pride and
                  defensiveness have no place in seeking what is true and right.
                  We remove ego, seek input/feedback, challenge personal
                  assumptions, introspect, improve, and then repeat. Data rules!
                  Data determines merit of claims in a debate. Meritocracy is
                  established by results verified by data. No one at MX is
                  entitled to influence within MX, except on the merits of the
                  value they generate. Influence is not the result of a title
                  distinction, tenure, or personal belief. It is the result of
                  continuous, results-driven value creation that ripples across
                  the organization.
                </p>
              </div>
              <button
                onClick={() => toggleAccordion(6)}
                className={`accordion ${activeAccordion == 6 ? "active" : ""}`}
              >
                Cognizance
              </button>
              <div className={`panel ${activeAccordion == 6 ? "active" : ""}`}>
                <h2>Cognizance</h2>
                <p>
                  We choose to have empathy and be aware of how we are affecting
                  others – always being respectful, fair and kind to team
                  members, the company, partners, clients, end-users,
                  competitors and the world. We choose to hold ourselves
                  personally and collectively responsible to be observant,
                  informed, grounded, mindful, perceptive, sensible, wise and
                  judicious as we determine how to have the best effect on those
                  around us. We believe in team fit and natural resonance that
                  drive strong team cohesiveness. When team fit is off, we
                  believe it is best for all sides to openly communicate, be
                  supportive and then find their optimal fit.
                </p>
              </div>
              <button
                onClick={() => toggleAccordion(7)}
                className={`accordion ${activeAccordion == 7 ? "active" : ""}`}
              >
                Iterative Innovation
              </button>
              <div className={`panel ${activeAccordion == 7 ? "active" : ""}`}>
                <h2>Iterative Innovation</h2>
                <p>
                  We believe in highly iterative and rapid innovation. We start
                  small, make quick cuts, test assumptions, fail quickly, make
                  adjustments and improve. By innovating this way, and living
                  fiercely loyal to all of the MX values, we will naturally have
                  ideal innovation.
                </p>
              </div>
            </Accordion>
          </RightCol>
        </Awards>
        <Details>
          <h2 className="sm-gray">Details</h2>
          <DetailRows>
            <div>
              <h3>NOMINATING</h3>
              <p>
                The Annual Founders Circle Awards recognize team member
                excellence across our MX values. Team members may nominate
                colleagues for a specific value category by using the "submit a
                nomination" form above.
              </p>
            </div>
            <div>
              <h3>JUDGING</h3>
              <p>
                Nominations will be submitted to a judging committee and the
                submissions that receive the most votes for each value from our
                judging panel will win (seven winners total). All nominations
                must be submitted by March 10.
              </p>
            </div>
            <div>
              <h3>AWARDING</h3>
              <p>
                Award winners will be announced at our next Town Hall on March
                16. Take a moment to nominate a team member who exemplifies our
                MX values today!
              </p>
            </div>
            <div>
              <h3>PRIZES</h3>
              <p>
                Founders Circle winners will receive some incredible prizes
                including a dinner with members of our Executive Team.
              </p>
            </div>
          </DetailRows>
        </Details>
      </PageWrapper>
    </>
  );
}

export default withTheme(FoundersCircle);
