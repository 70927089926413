import React, { useState, useEffect, useRef } from "react";
import api from "./api";
import utils from "./utils";
import { useOktaAuth } from "@okta/okta-react";
import styled, { withTheme } from "styled-components";
import GlossaryPage from "./GlossaryPage";

const PageWrapper = styled.section`
  .glossary-title {
    top: 0;
  }
`;

function Glossary({ theme }) {
  return (
    <PageWrapper>
      <GlossaryPage />
    </PageWrapper>
  );
}

export default withTheme(Glossary);
