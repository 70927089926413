import { React, useState, useEffect, useRef } from "react";
import styled from "styled-components";
import $ from "jquery";
import ClipboardJS from "clipboard";

const PageWrapper = styled.div`
  padding-top: 50px;
  position: relative;

  .content {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @media only screen and (max-width: 1000px) {
      flex-direction: column;
    }
  }

  #email-copy-success {
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    right: 0;
  }
  #email-copy-success .success-message {
    opacity: 0;
    transition: opacity 500ms;
    background-color: #2f73da;
    color: #fff;
    padding: 15px 0;
    font-size: 10px;
    font-family: "Proxima Nova", "SF Pro Text", Helvetica, Arial, sans-serif;
    text-align: center;
    pointer-events: none;
  }
  #email-copy-success .success-message h2 {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 400;
    margin: 0;
    color: #fff;
    font-size: 20px;
  }
  #email-copy-success .success-message a {
    color: #fff;
  }
  #email-copy-success.show-success .success-message {
    opacity: 1;
  }
  .tool-section {
    max-width: 500px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    input,
    select {
      padding-left: 10px;
    }

    #inputs {
      width: 100%;
      margin: 20px 0 50px 0;
      padding: 20px 30px;
    }
    #inputs form {
      display: flex;
      flex-direction: column;
    }
    #inputs h2 {
      padding: 0 0 10px 0;
      margin: 0;
      font-weight: 100;
      line-height: 1.1;
      font-size: 45px;
      text-align: center;
      color: ${({ theme }) => theme.textColor};
    }
    #inputs label {
      font-family: "Proxima Nova", "SF Pro Text", Helvetica, Arial, sans-serif;
      padding: 10px 0 5px 0;
      font-size: 14px;
      color: ${({ theme }) => theme.textColor};
    }
    #inputs input,
    select {
      height: 50px;
      font-size: 18px;
    }
    #inputs #phone-check-checkbox {
      padding-top: 10px;
    }
    #inputs #phone-check-checkbox input {
      display: inline;
      height: 14px;
    }
    #inputs .phone-field {
      display: flex;
      flex-direction: column;
    }
    #inputs #phone-format {
      padding: 5px 0;
      color: #929498;
    }
    #inputs .copy-submit-btn-container {
      text-align: center;
    }
    #inputs .copy-btn {
      margin-top: 20px;
      display: inline-block;
      padding: 0 20px;
      white-space: nowrap;
      border-radius: 2px;
      background-color: #2f73da;
      color: #fff;
      font-family: Proxima-nova, "SF Pro Text", Helvetica, Arial, sans-serif;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 2px;
      line-height: 50px;
      text-transform: uppercase;
      text-align: center;
      border: none;
      width: 100%;
      cursor: pointer;
    }
    .hide-phone {
      display: none !important;
      margin: 0;
      padding: 0;
      clear: both;
    }
  }

  #outside-wrap {
    width: 50%;
    max-width: 100%;
    padding: 20px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      width: 440px;
      height: 100%;
      border: 2px solid #f3f3f3;
      top: 0;
      left: 0;
      z-index: 0;
    }
  }
  #signature-wrap {
    width: 400px;
    max-width: 100%;
    position: relative;
    z-index: 1;

    .mx-logo {
      width: 45px;
      height: 45px;
      object-fit: contain;

      img {
        width: 45px;
        height: 45px;
      }
    }
  }
`;

function Signature() {
  const [nameValue, setNameValue] = useState("");
  const [titleValue, setTitleValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [phoneValue, setPhoneValue] = useState("(801) 669-5500");
  const [useCustomPhone, setUseCustomPhone] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const [formType, setFormType] = useState("default");
  const copyBtnRef = useRef(null);

  const [oktaTokenStorage, setOktaTokenStorage] = useState(null);

  useEffect(() => {
    const storedToken = localStorage.getItem("okta-token-storage");
    const parsedToken = storedToken ? JSON.parse(storedToken) : null;
    const userInfo = parsedToken.idToken.claims;
    setOktaTokenStorage(userInfo);
    setNameValue(userInfo.name);
    setEmailValue(userInfo.email);
  }, []);

  useEffect(() => {
    // Copy Signature
    var clipboard = new ClipboardJS(copyBtnRef.current);

    clipboard.on("success", function (e) {
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
      e.clearSelection();
    });
    clipboard.on("error", function (e) {
      console.error("Action:", e.action);
      console.error("Trigger:", e.trigger);
    });
  }, []);

  return (
    <>
      <PageWrapper>
        <div
          id="email-copy-success"
          className={copySuccess ? "show-success" : ""}
        >
          <div className="success-message">
            <h2>Signature Copied to Clipboard</h2>
          </div>
        </div>

        <div className="content">
          <section className="tool-section">
            <div id="inputs">
              <h2>
                Employee <strong>Information</strong>
              </h2>
              <form id="info-form">
                <label htmlFor="form_type">Signature Type:</label>
                <select
                  id="form_type"
                  onChange={(e) => {
                    setFormType(e.target.value);
                  }}
                >
                  <option value="default">Default Signature</option>
                  <option value="mxs">MXS Signature</option>
                </select>
                <label htmlFor="name">Name:</label>
                <input
                  id="name"
                  placeholder={nameValue === "" ? "Your Name" : nameValue}
                  value={nameValue}
                  onChange={(e) => setNameValue(e.target.value)}
                />
                <label htmlFor="position">Title:</label>
                <input
                  id="position"
                  placeholder={titleValue === "" ? "Your Title" : titleValue}
                  value={titleValue}
                  onChange={(e) => setTitleValue(e.target.value)}
                />
                <label htmlFor="email">Email:</label>
                <input
                  id="email"
                  placeholder={
                    emailValue === "" ? "your.email@mx.com" : emailValue
                  }
                  value={emailValue}
                  onChange={(e) => setEmailValue(e.target.value)}
                />
                {formType === "default" && (
                  <div id="phone-check-checkbox">
                    <input
                      type="checkbox"
                      id="phone-check"
                      checked={useCustomPhone}
                      onChange={(e) => setUseCustomPhone(e.target.checked)}
                    />
                    <label htmlFor="phone-check"> Change Phone Number</label>
                  </div>
                )}
                {useCustomPhone && (
                  <div className="phone-field">
                    <label htmlFor="phone">Phone:</label>
                    <input
                      id="phone"
                      type="tel"
                      placeholder={
                        phoneValue === "" ? "(801) 669-5500" : phoneValue
                      }
                      value={phoneValue}
                      onChange={(e) => setPhoneValue(e.target.value)}
                    />
                    <p id="phone-format">Format: (801) 669-5500</p>
                  </div>
                )}
              </form>
              <div className="copy-submit-btn-container">
                <button
                  ref={copyBtnRef}
                  className="copy-btn"
                  id="copy-btn"
                  data-clipboard-target="#signature-wrap"
                >
                  Copy Signature
                </button>
              </div>
            </div>
          </section>
          <div id="outside-wrap">
            <div id="signature-wrap">
              <table
                maxwidth="100%"
                width="400px"
                cellPadding="0"
                cellSpacing="0"
                style={{
                  padding: "0px",
                  borderWidth: "0px",
                  margin: "0px",
                  tableLayout: "fixed",
                  margin: "0",
                  width: "auto",
                  border: "0px",
                  borderCollapse: "collapse",
                  borderSpacing: "0px",
                  fontFamily: `"Proxima Nova", "SF Pro Text", Helvetica, Arial`,
                }}
              >
                <tbody>
                  <tr>
                    <td
                      className="mx-logo"
                      style={{ maxWidth: 45, width: 45, paddingRight: 10 }}
                    >
                      <img
                        src="https://mx.com/assets/images/signature/mx-square-logo.jpg"
                        alt="Logo"
                      />
                    </td>
                    <td valign="center" style={{ paddingRight: 10 }}>
                      <p
                        style={{
                          whiteSpace: "nowrap",
                          fontWeight: "bold",
                          padding: 0,
                          margin: 0,
                          fontSize: 16,
                          textTransform: "uppercase",
                        }}
                      >
                        {nameValue === "" ? "Your Name" : nameValue}
                      </p>
                      <p style={{ margin: 0, fontSize: 14 }}>
                        {titleValue === "" ? "Your Title" : titleValue}
                      </p>
                    </td>

                    {formType === "default" && (
                      <td valign="center" style={{ paddingRight: 10 }}>
                        <a
                          href={`tel:${phoneValue}`}
                          style={{
                            color: "#000",
                            textDecoration: "none",
                            fontWeight: "bold",
                            margin: 0,
                            fontSize: 14,
                          }}
                        >
                          {phoneValue === "" ? "(801) 669-5500" : phoneValue}
                        </a>
                        <p
                          style={{
                            margin: 0,
                            fontSize: 14,
                            color: "#000",
                            textDecoration: "none",
                          }}
                        >
                          {emailValue === "" ? "first.last@mx.com" : emailValue}
                        </p>
                      </td>
                    )}
                    {formType === "mxs" && (
                      <td valign="center" style={{ maxWidth: 300 }}>
                        <a href="https://www.mx.com/summit">
                          <img
                            style={{ width: "100%", maxWidth: "100%" }}
                            src="https://assets.mx.com/images/signature/2023-signature-image-2x.png"
                            alt="Image"
                          />
                        </a>
                      </td>
                    )}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
}

export default Signature;
