import React, { useState } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 600px;
  border: 2px solid ${({ theme }) => theme.divider};
  border-radius: 15px;
  display: flex;
  flex-wrap: wrap;
  padding: 20px 40px;

  h1 {
    font-weight: 500;
    font-size: 35px;
    line-height: 1;
    color: ${({ theme }) => theme.blue};
    margin-bottom: 50px;
  }

  .field {
    width: 100%;
    margin: 10px 0;

    &.all-day {
      display: flex;
      margin: 0;
      label {
        padding-top: 20px;
        margin-right: 20px;
      }
      input {
        width: 20px;
      }
    }
    label {
      display: block;
      padding: 0;
      margin-bottom: 10px;
      line-height: 1;
      font-size: 14px;
      /* background: ${({ theme }) => theme.backgroundColor}; */
      /* width: 90px;
      top: 10px; */
      position: relative;
    }

    input,
    select,
    textarea {
      border: 1px solid ${({ theme }) => theme.divider};
      border-radius: 5px;
      padding-left: 10px;
      box-sizing: border-box;
      width: 100%;
      height: 50px;
      font-size: 18px;
    }

    &.field-50 {
      width: 50%;
    }
    textarea {
      box-sizing: border-box;
      height: 150px;
      width: 100%;
      max-width: 100%;
      font-size: 16px;
    }
    select {
      box-sizing: border-box;
      height: 50px;
      width: 100%;
      font-size: 18px;
    }
  }

  .the-link {
    margin-top: 50px;

    & > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    h2 {
      margin: 0;
      font-weight: 700;
      font-size: 20px;
      line-height: 1;
      text-transform: uppercase;
      color: ${({ theme }) => theme.textColor};
    }
    .copy-text {
      line-height: 1;
      text-transform: uppercase;
      font-weight: 700;
      color: ${({ theme }) => theme.blue};
    }
    .link-text {
      color: ${({ theme }) => theme.textColor};
      text-decoration: none;
      display: inline-block;
      margin: 0;
      padding: 20px 20px 20px 0;
      background: ${({ theme }) => theme.backgroundColor};
      border-radius: 15px;
      overflow-wrap: anywhere;

      &:hover {
        color: ${({ theme }) => theme.blue};
      }
    }
  }
`;

// LInk Example
// let date = 20230508;
// let url = `https://calendar.google.com/calendar/event?action=TEMPLATE&dates=20230508T160000Z/20230508T164500Z&text=MX+Product+Forum+Innovation+to+Outcomes:+
// Open+Finance+and+Financial+Wellness&location=https://www.mx.com/product-forums/open-finance-financial-wellness/watch/&ctz=America/Los_Angeles`;

const EventForm = () => {
  const [title, setTitle] = useState("");
  const [allDay, setAllDay] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endDate, setEndDate] = useState("");
  const [endTime, setEndTime] = useState("");
  const [timezone, setTimezone] = useState("");
  const [details, setDetails] = useState("");
  const [location, setLocation] = useState("");
  const [url, setURL] = useState(
    `https://calendar.google.com/calendar/event?action=TEMPLATE&dates=${startDate}Z/20230508T164500Z&text=${title.replace(
      / /g,
      "+"
    )}&location=${location}&ctz=${timezone}`
  );
  const [testURL, setTestURL] =
    useState(`https://calendar.google.com/calendar/event?action=TEMPLATE&dates=20230508T160000Z/20230508T164500Z&text=MX+Product+Forum+Innovation+to+Outcomes:+
Open+Finance+and+Financial+Wellness&location=https://www.mx.com/product-forums/open-finance-financial-wellness/watch/&ctz=America/Los_Angeles`);

  const handleTitleChange = (e) => {
    const formattedTitle = e.target.value;
    setTitle(formattedTitle);
  };

  const handleAllDayChange = (e) => {
    setAllDay(e.target.checked);
  };

  // have all dates and times combine for the URL

  // const formattedDateTimeStart =
  // formattedStartDate + "T" + formattedStartTime;

  // const formattedDateTimeEnd = formattedEndDate + "T" + formattedEndTime;
  // setStartDate(e.target.value);

  const handleStartDateChange = (e) => {
    const formattedStartDate = startDate.replaceAll("-", "");
  };

  const handleStartTimeChange = (e) => {
    const formattedStartTime = startTime
      ? startTime.replaceAll(":", "") + "00"
      : "000000";
    setStartTime(e.target.value);
  };

  const handleEndDateChange = (e) => {
    const formattedEndDate = endDate.replaceAll("-", "");
    setEndDate(e.target.value);
  };
  const handleLocationChange = (e) => {
    setLocation(e.target.value);
  };

  const handleEndTimeChange = (e) => {
    const formattedEndTime = endTime
      ? endTime.replaceAll(":", "") + "00"
      : "000000";
    setEndTime(e.target.value);
  };

  const handleTimezoneChange = (e) => {
    setTimezone(e.target.value);
  };

  const handleDetailsChange = (e) => {
    setDetails(e.target.value);
  };

  return (
    <Wrapper>
      <h1>Google Calendar Invite Generator</h1>
      <div className="field">
        <label htmlFor="title">Event Title:</label>
        <input
          type="text"
          id="title"
          value={title}
          onChange={handleTitleChange}
        />
      </div>

      <div className="field all-day">
        <label htmlFor="allDay">All Day Event:</label>
        <input
          type="checkbox"
          id="allDay"
          checked={allDay}
          onChange={handleAllDayChange}
        />
      </div>

      <div className="field field-50">
        <label htmlFor="startDate">Start Date:</label>
        <input
          type="date"
          id="startDate"
          value={startDate}
          onChange={handleStartDateChange}
        />
      </div>

      {!allDay && (
        <div className="field field-50">
          <>
            <label htmlFor="startTime">Start Time:</label>
            <input
              type="time"
              id="startTime"
              value={startTime}
              onChange={handleStartTimeChange}
            />
          </>
        </div>
      )}
      <div className="field field-50">
        <label htmlFor="endDate">End Date:</label>
        <input
          type="date"
          id="endDate"
          value={endDate}
          onChange={handleEndDateChange}
        />
      </div>

      {!allDay && (
        <div className="field field-50">
          <>
            <label htmlFor="endTime">End Time:</label>
            <input
              type="time"
              id="endTime"
              value={endTime}
              onChange={handleEndTimeChange}
            />
          </>
        </div>
      )}
      <div className="field">
        <label htmlFor="timezone">Timezone:</label>
        <select id="timezone" value={timezone} onChange={handleTimezoneChange}>
          <option value="America/Denver" selected="selected">
            Mountain Time (MT)
          </option>
          <option value="America/Chicago">Central Time (CT)</option>
          <option value="America/New_York">Eastern Time (ET)</option>
          <option value="America/Los_Angeles">Pacific Time (PT)</option>
        </select>
      </div>

      <div className="field">
        <label htmlFor="details">Event Details:</label>
        <textarea
          id="details"
          value={details}
          onChange={handleDetailsChange}
        ></textarea>
      </div>
      <div className="field">
        <label htmlFor="location">Location:</label>
        <input
          type="text"
          id="location"
          value={location}
          onChange={handleLocationChange}
        />
      </div>

      <div className="the-link">
        <div>
          <h2>Invite Link</h2>
          <p className="copy-text">Copy</p>
        </div>
        <a className="link-text" href={testURL} target="_blank">
          {url}
        </a>
      </div>
    </Wrapper>
  );
};

export default EventForm;
