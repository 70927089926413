import React, { useEffect, useState, useRef } from "react";
import Page from "../components/page";
import styled, { withTheme } from "styled-components";

const PageWrap = styled.div`
  text-align: center;
  h1 {
    margin: 20px;
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.015em;
    text-align: center;
    color: ${({ theme }) => theme.textColor};
    margin-top: 100px;
  }
  a {
    text-decoration: none;
    display: block;
    color: ${({ theme }) => theme.textColor};
    margin: 20px 0;
  }
  p {
    color: ${({ theme }) => theme.textColor};
    font-weight: 900;
  }
`;

function SummitWatch({ theme }) {
  return (
    <>
      {/* <Nav /> */}

      <Page>
        <PageWrap>
          <h1>MXS Streams</h1>
          <p>Stream MXS sessions using the links below</p>

          <a href="https://vimeo.com/event/3713961/e0c25c1e29">
            Watch Morning Keynote 9:00AM - Noon
          </a>
          <a href="https://vimeo.com/event/3714195/5fcd05ef9c">
            Watch Breakouts 1:15PM - 4:00PM
          </a>
          <a href="https://vimeo.com/event/3714203/04b95f07d4">
            Watch Afternoon Keynote 4:00PM - 5:00PM
          </a>
        </PageWrap>
      </Page>
    </>
  );
}

export default SummitWatch;
