import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import styled, { withTheme } from "styled-components";
import sunDark from "../assets/images/icons/sun-dark.svg";
import sunLight from "../assets/images/icons/sun-light.svg";

const Wrap = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
  position: fixed;
  right: 0;
  z-index: 20;
  bottom: 50px;
`;

const ToggleButton = styled.div`
  width: 76px;
  height: 62px;
  background: ${({ theme }) => theme.gradientBg};
  cursor: pointer;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Sun = styled.img`
  width: 47px;
`;

const ToggleWrap = ({ theme, setTheme }) => {
  const sunImg = theme.name == "light" ? sunDark : sunLight;

  const toggleTheme = () => {
    let newTheme = theme.name == "dark" ? "light" : "dark";
    localStorage.setItem("mx-hub-theme", newTheme);
    setTheme(newTheme);
  };

  const pageBlacklist = ["/kickoff"];
  if (pageBlacklist.includes(window.location.pathname)) return "";

  return (
    <Wrap id="theme-toggle">
      <ToggleButton
        onClick={() => {
          toggleTheme();
        }}
      >
        <Sun src={sunImg} />
      </ToggleButton>
    </Wrap>
  );
};

export default withTheme(ToggleWrap);
