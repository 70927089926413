import styled from "styled-components";

const FilterButtonWrap = styled.div`

    user-select: none;

    text-align: center;
    width: fit-content;
    height: 46px;
    border-radius: 24px;
    border: 2px black solid;
    padding: 0 64px;

    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;

    &.sub-version {
        font-size: 16px;
        padding: 0 23px;
        height: 32px;
    }
    
    display: flex;
    align-items: center;
    justify-content: center;

    border: 2px ${({ theme }) => theme.textColor} solid;
    color: ${({ theme }) => theme.textColor};

    cursor: pointer;

    transition: scale ease 0.3s;

    &:hover {
        scale: 0.98;
    }

    &.selected {
        color: #2F73DA;
        border-color: #2F73DA;
    }
    &.grey {
        color: #959595;
        border-color: #959595;
    }
`;

const FilterButton = ({ state, children, onClick, subVersion }) => {

    let className = state;
    if(subVersion) className += " sub-version";

  return (
    <FilterButtonWrap onClick={onClick} className={className}>
      {children}
    </FilterButtonWrap>
  );
};

export default FilterButton;
