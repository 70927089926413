import { React, useEffect, useState } from "react";
import styled, { withTheme } from "styled-components";
import { SearchBox } from "react-instantsearch-hooks-web";

const SearchBarWrap = styled.div`
  &.search-bar {
    background: ${({ theme }) => theme.backgroundColor};

    .ais-SearchBox-form {
      width: 900px;
      max-width: 100%;
      margin: 0 auto;
      position: relative;
      padding: 0px 20px;
      margin: 20px 0px;
      box-sizing: border-box;

      .ais-SearchBox-input {
        height: 40px;
        width: 90%;
      }
      .ais-SearchBox-input {
        border-radius: 25px;
        height: 33px;
        width: 100%;
        padding: 0px 20px;
        border: 1px solid rgb(187, 187, 187);
        background: ${({ theme }) => theme.backgroundColor};
        color: ${({ theme }) => theme.textColor};
      }
      .ais-SearchBox-submit {
        width: 40px;
        height: 40px;
        padding: 0;
        display: none;

        svg {
          width: 12px;
          padding: 0;
          height: auto;
        }
      }
      .ais-SearchBox-reset {
        display: none;
      }
    }
  }
  &.home-search-bar {
    position: relative;
    width: 100%;

    .ais-SearchBox {
      .ais-SearchBox-form {
        width: 100%;
        max-width: 900px;
        margin: 0px auto;
        position: relative;
        z-index: 10;

        .ais-SearchBox-input {
          border-radius: 25px;
          border: none;
          height: 40px;
          width: 100%;
          max-width: 890px;
          padding: 0px 20px;
          background: rgb(255, 255, 255);
          color: rgb(0, 0, 0);
          z-index: 10;
          position: relative;
        }
        .ais-SearchBox-submit {
          width: 40px;
          height: 40px;
          padding: 0;
          display: none;

          svg {
            width: 12px;
            padding: 0;
            height: auto;
          }
        }
        .ais-SearchBox-reset {
          display: none;
        }
      }
    }
  }
`;

function SearchBoxComponent({ onSearchInputChange, className }) {
  const handleInputChange = (event) => {
    const value = event.target.value;
    if (value) {
      onSearchInputChange(value);
    } else {
      onSearchInputChange(null);
    }
  };

  return (
    <SearchBarWrap className={className}>
      <SearchBox
        placeholder="Search..."
        onChangeCapture={(e) => {
          handleInputChange(e);
        }}
      />
    </SearchBarWrap>
  );
}

export default withTheme(SearchBoxComponent);
